import React, { useState, useEffect } from 'react';
import { SafeAreaView, ScrollView, View, Text, Dimensions, Switch, TouchableOpacity, Modal, Button, TextInput, StatusBar } from 'react-native';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import style from '../theme/style';
import { useNavigation } from '@react-navigation/native';
import FormStepByStep from './FormStepByStep';
import stylesForm from '../theme/step';
import { useStorage } from '../contextApp';
import ActionItem from './composants/ActionItem';
import StrongPoints from './composants/StrongPoints';
import Checkbox from './composants/Checkbox';
import Counter from './Counter';
import { useTranslation } from 'react-i18next';


const MultiSteps = ({ route }) => {
  const { height: screenHeight, width: screenWidth } = Dimensions.get('window');
  const { t } = useTranslation();
  const { storageState, setItem, removeItem } = useStorage();
  const { QuizItem = {} } = route.params || {}; // Add default values 
  const navigation = useNavigation();
  const lang = storageState.lang;
  const usernameEva = storageState.formateurUser;
  const type = storageState.formateurType;
  const userAp = storageState.username;
  const cidInscription = storageState.cidInscription;
  const [improvePoints, setImprovePoints] = useState([]);
  const [notAcquiredPoints, setNotAcquiredPoints] = useState([]);
  const [isGlobalModalVisible, setGlobalModalVisible] = useState(false);
  const [globalComment, setGlobalComment] = useState('');
  const [isStrongModalVisible, setStrongModalVisible] = useState(false);
  const [selectedStrongPoints, setSelectedStrongPoints] = useState([]);
  const [isComment, setIsComment] = useState(false);
  const [commentStates, setCommentStates] = useState(actions ? new Array(actions.length).fill(false) : []);
  const [commentIndex, setCommentIndex] = useState(null);
  const [editedComment, setEditedComment] = useState('');
  const [medicals, setMedicals] = useState([]);
  const [competanceList, setCompetanceList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [message, setMessage] = useState('');
  const [surfaceValue, setSurfaceValue] = useState(2);
  const [mainValue, setMainValue] = useState(0);
  const [commentaireAcquired, setCommentaireAcquired] = useState('');
  const [editModeAcquired, setEditModeAcquired] = useState(false);
  const [commentaireImprove, setCommentaireImprove] = useState('');
  const [editModeImprove, setEditModeImprove] = useState(false);
  const [mains, setMains] = useState(0);
  const [surface, setSurface] = useState(0);
  const [commentaireStrong, setCommentaireStrong] = useState('');
  const [pointsToImprove, setPointsToImprove] = useState([]);
  const [pointsNotAquired, setPonitsNotAquired] = useState([]);
  const { nomFr, nomEn, idModule, points, actions: initialActions } = QuizItem;
  const [errorCompetances, setErrorCompetances] = useState(false);
  const [errorSousCompetances, setErrorSousCompetances] = useState(false);
  const [sendDataModal, setSendDataModal] = useState(false);
  const [sendRetakeModal, setSendRetakeModal] = useState(false);
  const courName = storageState.courName;
  const dataSend = storageState.apprenants;
  const [quizExist, setQuizExist] = useState(); 
  const [cntMain, setCntMain] = useState(0); 
  const [cntSurface, setCntSurface] = useState(0);
  const [strongPoints, setStrongPoints] = useState(points.map((point) => ({
    texte: lang === 'fr' ? point.nameFr : point.nameEn,
    coche: false,
    dateSaisie: new Date().toISOString(),
  })));
  const [actions, setActions] = useState(initialActions.map((act, index) => ({
    ordre: index + 1,
    texte: lang === 'fr' ? act.nameFr : act.nameEn,
    commentaire: '',
    coche: false,
    dateSaisie: new Date().toISOString(),
  })));
  const existingData = storageState.apprenants || [];
  const unfinishedCompetancesList= storageState.competances|| [];
  
  const existingUserIndex = existingData.findIndex((user) => user.nomUsager === userAp);
  const existingUnfinishedQuiz= unfinishedCompetancesList.findIndex((quiz) => quiz.idModule === QuizItem.idModule && quiz.cidInscription === QuizItem.cidInscription);
 console.log('existingUserIndex 01:',unfinishedCompetancesList[existingUnfinishedQuiz]);
      
  useEffect(() => {
    if (existingUserIndex !== -1) {
      const QuizDataIndex = existingData[existingUserIndex].listeQuiz.findIndex((quiz) => quiz.id === QuizItem.idModule && quiz.cidInscription === QuizItem.cidInscription);
    
      if (QuizDataIndex !== -1) {
        const existingQuizInfo = existingData[existingUserIndex].listeQuiz[QuizDataIndex];
        setQuizExist(existingQuizInfo);
        setCntMain(existingQuizInfo.mainNb);
        setCntSurface(existingQuizInfo.surfaceNb);
        setMains(existingQuizInfo.mainNb)
        setSurface(existingQuizInfo.surfaceNb);
       // setCommentaireAcquired(existingQuizInfo.appreciation.pointFortCommentaire)
      }
    }
  }, [storageState, userAp, QuizItem.idModule, QuizItem.cidInscription]);
  const userQuizDataIndex =existingUserIndex!=-1? existingData[existingUserIndex].listeQuiz.findIndex((quizInfo) => quizInfo.id === QuizItem.idModule && quizInfo.cidInscription === QuizItem.cidInscription):-1;

  useEffect(() => {
   // //console.log('mainCount', cntMain);
   // //console.log('surfaceCount', cntSurface);
   // //console.log('quizexist ==>', quizExist);
  }, [cntMain, cntSurface, quizExist, QuizItem.idModule, QuizItem.cidInscription]);
  
  useEffect(() => {
//setCommentaireAcquired();
  }, [cntMain, cntSurface, quizExist, QuizItem.idModule, QuizItem.cidInscription]);
  useEffect(() => {
    if (existingUserIndex !== -1) {
      console.log('userQuizDataIndex 02:',userQuizDataIndex);
       if (userQuizDataIndex !== -1) {
    setStrongPoints(existingData[existingUserIndex].listeQuiz[userQuizDataIndex].appreciation.pointsForts);
    existingData[existingUserIndex].listeQuiz[userQuizDataIndex].appreciation.pointsForts.map((pf)=>{
      if(pf.coche===true)
        {
          setSelectedStrongPoints((prevSelected) => {
            const updatedSelected = [...prevSelected];
          
              updatedSelected.push(pf.texte);
            
  
            return updatedSelected;
          });

        }
    })
    setActions(existingData[existingUserIndex].listeQuiz[userQuizDataIndex].actions);
    setGlobalComment(existingData[existingUserIndex].listeQuiz[userQuizDataIndex].commentaire)
    setCommentaireStrong(existingData[existingUserIndex].listeQuiz[userQuizDataIndex].appreciation.pointFortCommentaire)
    setCommentaireImprove(existingData[existingUserIndex].listeQuiz[userQuizDataIndex].appreciation.pointAmeliorerCommentaire)
    setCommentaireAcquired(existingData[existingUserIndex].listeQuiz[userQuizDataIndex].appreciation.pointNonAcquisCommentaire)
    const existingQuizInfo = existingData[existingUserIndex].listeQuiz[userQuizDataIndex];
   
    setMains(existingQuizInfo.mainNb)
    setSurface(existingQuizInfo.surfaceNb);
       }
       else if( existingUnfinishedQuiz!=-1) { 
        setStrongPoints(unfinishedCompetancesList[existingUnfinishedQuiz].appreciation.pointsForts);
        unfinishedCompetancesList[existingUnfinishedQuiz].appreciation.pointsForts.map((pf)=>{
          if(pf.coche===true)
            {
              setSelectedStrongPoints((prevSelected) => {
                const updatedSelected = [...prevSelected];
              
                  updatedSelected.push(pf.texte);
                
      
                return updatedSelected;
              });
    
            }
        })
        setActions(unfinishedCompetancesList[existingUnfinishedQuiz].actions);
        setGlobalComment(unfinishedCompetancesList[existingUnfinishedQuiz].commentaire)
        setCommentaireStrong(unfinishedCompetancesList[existingUnfinishedQuiz].appreciation.pointFortCommentaire)
        setCommentaireImprove(unfinishedCompetancesList[existingUnfinishedQuiz].appreciation.pointAmeliorerCommentaire)
        setCommentaireAcquired(unfinishedCompetancesList[existingUnfinishedQuiz].appreciation.pointNonAcquisCommentaire)
        console.log(unfinishedCompetancesList[existingUnfinishedQuiz].mains)
    setMains(unfinishedCompetancesList[existingUnfinishedQuiz].mains)
    setSurface(unfinishedCompetancesList[existingUnfinishedQuiz].surface);
      }
      }
      else if( existingUnfinishedQuiz!=-1) { 
        setStrongPoints(unfinishedCompetancesList[existingUnfinishedQuiz].appreciation.pointsForts);
        unfinishedCompetancesList[existingUnfinishedQuiz].appreciation.pointsForts.map((pf)=>{
          if(pf.coche===true)
            {
              setSelectedStrongPoints((prevSelected) => {
                const updatedSelected = [...prevSelected];
              
                  updatedSelected.push(pf.texte);
                
      
                return updatedSelected;
              });
    
            }
        })
        setActions(unfinishedCompetancesList[existingUnfinishedQuiz].actions);
        setGlobalComment(unfinishedCompetancesList[existingUnfinishedQuiz].commentaire)
        setCommentaireStrong(unfinishedCompetancesList[existingUnfinishedQuiz].appreciation.pointFortCommentaire)
        setCommentaireImprove(unfinishedCompetancesList[existingUnfinishedQuiz].appreciation.pointAmeliorerCommentaire)
        setCommentaireAcquired(unfinishedCompetancesList[existingUnfinishedQuiz].appreciation.pointNonAcquisCommentaire)
        console.log(unfinishedCompetancesList[existingUnfinishedQuiz].mains)
    setMains(unfinishedCompetancesList[existingUnfinishedQuiz].mains)
    setSurface(unfinishedCompetancesList[existingUnfinishedQuiz].surface);
      }
        }, [existingUserIndex, userQuizDataIndex,existingUnfinishedQuiz]);
  
console.log(existingData);

  if (existingUserIndex !== -1) {
   // //console.log('userQuizDataIndex 01:',userQuizDataIndex);
    if (userQuizDataIndex !== -1) {
     // //console.log('userQuizDataIndex 01 1:',userQuizDataIndex);
      var QuizData = {
        ...QuizItem,
        competences: QuizItem.competences.map((comp, index) => {
          var statut = existingData[existingUserIndex].listeQuiz[userQuizDataIndex].competences[index].statut;
          comp.statut = statut;
          if (existingData[existingUserIndex].listeQuiz[userQuizDataIndex].competences[index].commentaire) {
            comp.commentaire = existingData[existingUserIndex].listeQuiz[userQuizDataIndex].competences[index].commentaire;
          }
          return comp;
        }),
    //     pointFortCommentaire: commentaireStrong,sss
    // pointAmeliorerCommentaire: commentaireImprove,
    // pointNonAcquisCommentaire: commentaireAcquires
     mainNb: existingData[existingUserIndex].listeQuiz[userQuizDataIndex].mainNb,
     surfaceNb: existingData[existingUserIndex].listeQuiz[userQuizDataIndex].surfaceNb,
     appreciation: existingData[existingUserIndex].listeQuiz[userQuizDataIndex].appreciation,
    commentaire: existingData[existingUserIndex].listeQuiz[userQuizDataIndex].commentaire
      };
  
     // //console.log('QuizDat 011:',QuizData);
    }
    else if( existingUnfinishedQuiz!=-1) {
      // //console.log('userQuizDataIndex 01 1:',userQuizDataIndex);
      var QuizData = {
        ...QuizItem,
      
   
     mainNb: unfinishedCompetancesList[existingUnfinishedQuiz].mains,
     surfaceNb:unfinishedCompetancesList[existingUnfinishedQuiz].surface,
     appreciation: unfinishedCompetancesList[existingUnfinishedQuiz].appreciation,
    commentaire: unfinishedCompetancesList[existingUnfinishedQuiz].commentaire
      };
  }
    else {
      if (QuizItem.idModule === "EPD30" || QuizItem.idModule === "EPD31") {
        const completedCategory = storageState.completedCategory;
        // ////console.log(completedCsss)
        if (completedCategory) {
          if (completedCategory.id === QuizItem.idModule) {
            var QuizData = {
              ...QuizItem,
              competences: QuizItem.competences.map((comp, index) => {
                var statut = completedCategory.competences[index].statut;
                comp.statut = statut;
                return comp;
              })
            };
          }
          else {
            var QuizData = QuizItem
          }
        } else {
          var QuizData = QuizItem
        }

      }
      else {
        var QuizData = QuizItem
      }

    }
  } 
  else if( existingUnfinishedQuiz!=-1) {
    // //console.log('userQuizDataIndex 01 1:',userQuizDataIndex);
    var QuizData = {
      ...QuizItem,
    
 
   mainNb: unfinishedCompetancesList[existingUnfinishedQuiz].mains,
   surfaceNb:unfinishedCompetancesList[existingUnfinishedQuiz].surface,
   appreciation: unfinishedCompetancesList[existingUnfinishedQuiz].appreciation,
  commentaire: unfinishedCompetancesList[existingUnfinishedQuiz].commentaire
    };
}
  console.log(QuizData);
  console.log(mains);
  console.log(surface);
  useEffect(() => {
    //check langu 
  //  //console.log("langue update ..::", storageState.lang);
  }, [lang]);
 
  const idmod = QuizItem.idModule == "EPD30" || QuizItem.idModule == "EPD31" ? "EPD3" : QuizItem.idModule
  const apprenantsData =
  {
    "nomUsager": userAp,
    "medicals": medicals,
    "listeQuiz": [
      {
        "id": idmod,
        "cidInscription": cidInscription,
        "type": QuizItem.typeName === 'Évaluation' ? 'evaluation' : 'formation',
        "evaluationSatisfaisante": true,
        "formateur": usernameEva,
        "formateurType": type,
        "commentaire": globalComment,
        "mainNb": mains,
        "surfaceNb": surface,
        "competences": competanceList,
        "actions": actions,
        "appreciation": appreciation,
        "cour": courName
      }
    ]
  }
    ;

  useEffect(() => {
    //////console.log('action quiz',QuizItem.actions);
    // ////console.log('quiz item', pointsToImprove);
  }, [pointsToImprove]);

  useEffect(() => {
    //////console.log('action quiz',QuizItem.actions);
    // ////console.log('quiz item', pointsToImprove);
  }, [pointsNotAquired]);
  const handleSatisfactoryPress = async () => {
    try {


      var emptyCompetances = competanceList.filter((el) => el.statut == null)
      if (emptyCompetances.length > 0) {
        setErrorCompetances(true)
        return;
      }
      var emptySubSkills = competanceList.filter((competance) => {
        // Check if the statut is "ameliorer"
        if (competance.statut === "ameliorer") {

          if (competance.sousCompetences.length > 0) {
           
            // Check if any element in listSubSkills has coche set to false
            var hasFalseCoche = competance.sousCompetences.every((subSkill) => subSkill.coche == false);
            return hasFalseCoche;
          }
          else {
            return false;
          }

        }
        return false; // Don't include elements with statut other than "ameliorer"
      });
      //console.log("emptySubSkills",emptySubSkills)
      if (emptySubSkills.length > 0) {
        setErrorSousCompetances(true)
        return;
      }
      const updatedCompetances = competanceList.map(element => {
        ////console.log("comppp 00:::=>",updatedCompetances);
        if (element.statut !== "ameliorer") {
          const updatedSousCompetences = element.sousCompetences.map(el => ({
            ...el,
            coche: false
          }));
          ////console.log("comppp 01:::=>",updatedSousCompetences);
          return {
            ...element,
            sousCompetences: updatedSousCompetences
          };
        }
        return element;
      });

      setCompetanceList(updatedCompetances);
      // //console.log("comppp 02:::=>",competanceList);
      setSendDataModal(true)
    } catch (error) {
      console.error('Error saving apprenant data:', error);
    }
  };

  const handleRetakeRequired = async () => {
    try {


      var emptyCompetances = competanceList.filter((el) => el.statut == null)
      if (emptyCompetances.length > 0) {
        setErrorCompetances(true)
        return;
      }
      var emptySubSkills = competanceList.filter((competance) => {
        // Check if the statut is "ameliorer"
        if (competance.statut === "ameliorer") {

          if (competance.sousCompetences.length > 0) {
            // Check if any element in listSubSkills has coche set to false
            var hasFalseCoche = competance.sousCompetences.every((subSkill) => subSkill.coche == false);
            return hasFalseCoche;
          }
          else {
            return false;
          }

        }
        return false; // Don't include elements with statut other than "ameliorer"
      });
      // ////console.log("emptySubSkills",emptySubSkills)
      if (emptySubSkills.length > 0) {
        setErrorSousCompetances(true)
        return;
      }
      const updatedCompetances = competanceList.map(element => {
        if (element.statut !== "ameliorer") {
          const updatedSousCompetences = element.sousCompetences.map(el => ({
            ...el,
            coche: false
          }));

          return {
            ...element,
            sousCompetences: updatedSousCompetences
          };
        }
        return element;
      });

      setCompetanceList(updatedCompetances);
      setSendRetakeModal(true)

    } catch (error) {
      console.error('Error saving apprenant data:', error);
    }

  }
  const handleSendDataRetake = async () => {
    setSendRetakeModal(false)
    try {
      // Retrieve existing apprenants data from storage
      const asyncDataApprenant = storageState.apprenants || [];

      // Check if asyncDataApprenant is an array
      if (!Array.isArray(asyncDataApprenant)) {
        console.error('Error: asyncDataApprenant is not an array');
        return;
      }

      // Check if the user already exists in the array
      const existingUserIndex = asyncDataApprenant.findIndex((user) => user.nomUsager === userAp);

      if (existingUserIndex !== -1) {
        // If the user exists, update the existing entry
        const exisitingQuizUserIndex = asyncDataApprenant[existingUserIndex].listeQuiz.findIndex((quizInfo) => quizInfo.id === idmod && quizInfo.cidInscription === cidInscription)
        if (exisitingQuizUserIndex != -1) {
          asyncDataApprenant[existingUserIndex].listeQuiz[exisitingQuizUserIndex] = {
            "id": idmod,
            "cidInscription": cidInscription,
            "type": QuizItem.typeName === 'Évaluation' ? 'evaluation' : 'formation',
            "evaluationSatisfaisante": false,
            "formateur": usernameEva,
            "formateurType": type,
            "commentaire": globalComment,
            "mainNb": mains,
            "surfaceNb": surface,
            "competences": competanceList,
            "actions": actions,
            "appreciation": appreciation,
            "cour": courName
          }
        }
        else {
          asyncDataApprenant[existingUserIndex].listeQuiz.push({
            "id": idmod,
            "cidInscription": cidInscription,
            "type": QuizItem.typeName === 'Évaluation' ? 'evaluation' : 'formation',
            "evaluationSatisfaisante": false,
            "formateur": usernameEva,
            "formateurType": type,
            "commentaire": globalComment,
            "mainNb": mains,
            "surfaceNb": surface,
            "competences": competanceList,
            "actions": actions,
            "appreciation": appreciation,
            "cour": courName
          });
        }

      } else {
        // If the user does not exist, create a new entry
        asyncDataApprenant.push({
          "nomUsager": userAp,
          "medicals": medicals,
          "listeQuiz": [
            {
              "id": idmod,
              "cidInscription": cidInscription,
              "type": QuizItem.typeName === 'Évaluation' ? 'evaluation' : 'formation',
              "evaluationSatisfaisante": false,
              "formateur": usernameEva,
              "formateurType": type,
              "commentaire": globalComment,
              "mainNb": mains,
              "surfaceNb": surface,
              "competences": competanceList,
              "actions": actions,
              "appreciation": appreciation,
              "cour": courName
            }
          ]
        });
      }
      if (idmod === "EPD3") {
        var completedCategory = {
          "id": QuizItem.idModule,
          "cidInscription": cidInscription,
          "type": QuizItem.typeName === 'Évaluation' ? 'evaluation' : 'formation',
          "evaluationSatisfaisante": true,
          "formateur": usernameEva,
          "formateurType": type,
          "commentaire": globalComment,
          "mainNb": mains,
          "surfaceNb": surface,
          "competences": competanceList,
          "actions": actions,
          "appreciation": appreciation,
          "cour": courName
        }
        await setItem('completedCategory', completedCategory);
      }
      // Save the updated data to storage
      await setItem('apprenants', asyncDataApprenant);
      const unfinishedCompetances = storageState.competances ? storageState.competances : []
      var index = unfinishedCompetances.findIndex((el) => el.idModule === idmod)
      if (index != -1) {
        unfinishedCompetances[index] = {
          cidInscription: cidInscription,
          idModule: idmod,
          competances: competanceList,
          mains:mains,
          surface: surface,
          commentaire: globalComment,
          appreciation:appreciation,
          actions:actions
        }
      } else {
        unfinishedCompetances.push({
          cidInscription: cidInscription,
          idModule: idmod,
          competances: competanceList,
          mains:mains,
          surface: surface,
          commentaire: globalComment,
          appreciation:appreciation,
          actions:actions
        })
      }
      setItem("competances", unfinishedCompetances)
      setItem("idModule", idmod)
      // Navigate to the 'RecordedData' screen or any other screen as needed
      navigation.navigate('RecordedData');
    } catch (error) {
      console.error('Error saving apprenant data:', error);
    }
  };
  const handleSendData = async () => {
    setSendDataModal(false)
    try {
      // Retrieve existing apprenants data from storage
      const asyncDataApprenant = storageState.apprenants || [];
      //////console.log('app',asyncDataApprenant);
      // Check if asyncDataApprenant is an array
      if (!Array.isArray(asyncDataApprenant)) {
        console.error('Error: asyncDataApprenant is not an array');
        return;
      }

      // Check if the user already exists in the array
      const existingUserIndex = asyncDataApprenant.findIndex((user) => user.nomUsager === userAp);

      if (existingUserIndex !== -1) {
        // If the user exists, update the existing entry
        const exisitingQuizUserIndex = asyncDataApprenant[existingUserIndex].listeQuiz.findIndex((quizInfo) => quizInfo.id === idmod && quizInfo.cidInscription === cidInscription)
        if (exisitingQuizUserIndex != -1) {
          asyncDataApprenant[existingUserIndex].listeQuiz[exisitingQuizUserIndex] = {
            "id": idmod,
            "cidInscription": cidInscription,
            "type": QuizItem.typeName === 'Évaluation' ? 'evaluation' : 'formation',
            "evaluationSatisfaisante": true,
            "formateur": usernameEva,
            "formateurType": type,
            "commentaire": globalComment,
            "mainNb": mains,
            "surfaceNb": surface,
            "competences": competanceList,
            "actions": actions,
            "appreciation": appreciation,
            "cour": courName
          }
        }
        else {
          asyncDataApprenant[existingUserIndex].listeQuiz.push({
            "id": idmod,
            "cidInscription": cidInscription,
            "type": QuizItem.typeName === 'Évaluation' ? 'evaluation' : 'formation',
            "evaluationSatisfaisante": true,
            "formateur": usernameEva,
            "formateurType": type,
            "commentaire": globalComment,
            "mainNb": mains,
            "surfaceNb": surface,
            "competences": competanceList,
            "actions": actions,
            "appreciation": appreciation,
            "cour": courName
          });
        }
      } else {
        // If the user does not exist, create a new entry
        asyncDataApprenant.push({
          "nomUsager": userAp,
          "medicals": medicals,
          "listeQuiz": [
            {
              "id": idmod,
              "cidInscription": cidInscription,
              "type": QuizItem.typeName === 'Évaluation' ? 'evaluation' : 'formation',
              "evaluationSatisfaisante": true,
              "formateur": usernameEva,
              "formateurType": type,
              "commentaire": globalComment,
              "mainNb": mains,
              "surfaceNb": surface,
              "competences": competanceList,
              "actions": actions,
              "appreciation": appreciation,
              "cour": courName
            }
          ]
        });
      }
      if (idmod === "EPD3") {
        var completedCategory = {
          "id": QuizItem.idModule,
          "cidInscription": cidInscription,
          "type": QuizItem.typeName === 'Évaluation' ? 'evaluation' : 'formation',
          "evaluationSatisfaisante": true,
          "formateur": usernameEva,
          "formateurType": type,
          "commentaire": globalComment,
          "mainNb": mains,
          "surfaceNb": surface,
          "competences": competanceList,
          "actions": actions,
          "appreciation": appreciation,
          "cour": courName
        }
        await setItem('completedCategory', completedCategory);
      }
      // Save the updated data to storage
      await setItem('apprenants', asyncDataApprenant);
      const unfinishedCompetances = storageState.competances ? storageState.competances : []
      var index = unfinishedCompetances.findIndex((el) => el.idModule === idmod)
      if (index != -1) {
        unfinishedCompetances[index] = {
          cidInscription: cidInscription,
          idModule: idmod,
          competances: competanceList,
          mains:mains,
          surface: surface,
          commentaire: globalComment,
          appreciation:appreciation,
          actions:actions
        }
      } else {
        unfinishedCompetances.push({
          cidInscription: cidInscription,
          idModule: idmod,
          competances: competanceList,
          mains:mains,
          surface: surface,
          commentaire: globalComment,
          appreciation:appreciation,
          actions:actions
        })
      }
      setItem("competances", unfinishedCompetances)
      setItem("idModule", idmod)
      // Navigate to the 'RecordedData' screen or any other screen as needed
      navigation.navigate('RecordedData');
    } catch (error) {
      console.error('Error saving apprenant data:', error);
    }
  };

  const getHandsNbr = (newstate) => {
    setMains(newstate)
  }
  const getSurfaceNbr = (newstate) => {
    setSurface(newstate)
  }
  useEffect(() => {
    ////console.log('apprenant:', apprenantsData);
  }, [apprenantsData]);
  //commentaire not Acquired
  const togCommentAcquired = () => {
    setEditModeAcquired(!editModeAcquired);
  };

  const handleCommentAcquiredSend = () => {
    setEditModeAcquired(false);
  };

  //commentaire improve
  const togCommentImprove = () => {
    setEditModeImprove(!editModeImprove);
  };

  const handleCommentImproveSend = () => {
    setEditModeImprove(false);
  };


  const updateActionState = (index, key, value, isComment) => {
    setActions((prevActions) => {
      const updatedActions = [...prevActions];
      updatedActions[index] = {
        ...updatedActions[index],
        [key]: value,
      };
      setIsComment(isComment);
      return updatedActions;
    });
  };

  const toggleSwitch = (index, value) => {
    updateActionState(index, 'coche', value);
  };

  const toggleComment = (index) => {
    setCommentStates((prevCommentStates) => {
      const updatedStates = [...prevCommentStates];
      updatedStates[index] = !updatedStates[index];
      setEditedComment(actions[index].commentaire);
      return updatedStates;
    });
    setCommentIndex(index);
  };

  const handleCommentSend = (index) => {
    updateActionState(index, 'commentaire', editedComment, true);
    setCommentStates((prevCommentStates) => {
      const updatedStates = [...prevCommentStates];
      updatedStates[index] = false;
      return updatedStates;
    });
    setEditedComment('');
  };
  const toggleGlobalModal = () => {
    setGlobalModalVisible(!isGlobalModalVisible);
  };
  const toggleStrongModal = () => {
    setStrongModalVisible(!isStrongModalVisible);
  };

  const handleGlobalCommentSend = () => {
    //setGlobalComment(message);
    setGlobalModalVisible(false);
  };


  const handleStrongPointSend = () => {
    if (message.trim() !== '') {
      setStrongPoints((prevStrongPoints) => [
        ...prevStrongPoints,
        {
          texte: message.trim(),
          coche: false,
        },
      ]);
      setMessage('');
      setStrongModalVisible(false);
    }
  };
  useEffect(() => {
    //console.log('competance list:', competanceList);
  }, [competanceList]);
  const toggleCheckbox = (point, category, step) => {
    switch (category) {
      case 'strong':
        setStrongPoints((prevStrongPoints) => {
          const updatedStrongPoints = prevStrongPoints.map((sp) => {
            if (sp.texte === point.texte) {
              return {
                ...sp,
                coche: !sp.coche, // Toggle the 'coche' property
              };
            }
            return sp;
          });
          //////console.log('Strong Points Updated:', updatedStrongPoints);
          return updatedStrongPoints;
        });
        setSelectedStrongPoints((prevSelected) => {
          const updatedSelected = [...prevSelected];
          const isPointSelected = updatedSelected.includes(point.texte);

          if (isPointSelected) {
            updatedSelected.splice(updatedSelected.indexOf(point.texte), 1);
          } else {
            updatedSelected.push(point.texte);
          }

          return updatedSelected;
        });
        break;

      default:
        break;
    }
  };
  //////console.log("QuizItem",QuizItem.competences)
  //////console.log("QuizItemComp",competanceList)
  const handleRetour = async () => {
    const unfinishedCompetances = storageState.competances ? storageState.competances : []
    if (idmod === "EPD3") {
      var idMod = QuizItem.idModule
    }
    else {
      var idMod = idmod
    }
    // ////console.log(idMod)
    var index = unfinishedCompetances.findIndex((el) => el.idModule === idMod)
    if (index != -1) {
      unfinishedCompetances[index] = {
        cidInscription: cidInscription,
        idModule: idMod,
        competances: competanceList,
        mains:mains,
        surface: surface,
        commentaire: globalComment,
        appreciation:appreciation,
        actions:actions
      }
    } else {
      unfinishedCompetances.push({
        cidInscription: cidInscription,
        idModule: idMod,
        competances: competanceList,
        mains:mains,
        surface: surface,
        commentaire: globalComment,
        appreciation:appreciation,
        actions:actions
      })
    }
    setItem("competances", unfinishedCompetances)
    setItem("idModule", idMod)
    if (idmod === "EPD3") {
      navigation.navigate('Category', { QuizItem: storageState.EPD3 })
      removeItem('EPD3')
    }
    else {
      navigation.navigate('Practical Assessment', {
        datatosync: dataSend
      })
    }

  }
  const renderCheckboxes = (points, selectedState, category) => (
    points.map((point, index) => (
      <Checkbox
        key={index}
        label={point.texte}
        selected={selectedState.includes(point.texte)}
        onSelect={() => toggleCheckbox(point, category, index)}
        disabled={false}
      />
    ))
  );
  const appreciation = {
    pointFortCommentaire: commentaireStrong,
    pointAmeliorerCommentaire: commentaireImprove,
    pointNonAcquisCommentaire: commentaireAcquired,
    pointsForts: strongPoints,
  };
  useEffect(() => {
    //////console.log('appreciation Updated:', appreciation);
  }, [appreciation]);
  useEffect(() => {
    //////console.log('Strong Points Updated:', strongPoints);
  }, [strongPoints]);

  useEffect(() => {
    //  ////console.log('Actions Updated:', actions);
  }, [actions]);

  useEffect(() => {
    //////console.log('action quiz',QuizItem.actions);
    // ////console.log('quiz item', QuizItem);
  }, [QuizItem]);
  //////console.log(mains)

  const handleCloseErrorCompModal = () => {
    setErrorCompetances(false)
  }
  const handleCloseErrorSousCompModal = () => {
    setErrorSousCompetances(false)
  }
  const handleCloseSendModal = () => {
    setSendDataModal(false)
  }
  const handleCloseSendRetakeModal = () => {
    setSendRetakeModal(false)
  }
  return (
    <SafeAreaView style={style.area}>
      <View style={{ flex: 1 }}>
        <View style={{ position: 'relative', zIndex: 1 }}></View>
        <>

          <View style={{ flexDirection: 'row', position: 'sticky', top: 0, backgroundColor: '#013E7F', zIndex: 2 }}>
            <View style={{ width: '90%', alignItems: 'left' }}>

              <TouchableOpacity onPress={handleRetour}
                style={{ color: 'white', padding: 8, flexDirection: 'row', fontSize: 18, marginTop: 10, height: 60 }}>
                <Icons name="arrow-left-thin" size={28} color="#fff" style={{ marginRight: '7', marginTop: -2 }} />
                <Text style={{ color: 'white', fontSize: 18 }}>{t('backList')}</Text>
              </TouchableOpacity>

            </View>

            <View style={{ width: '10%', alignItems: 'right' }}>
              <TouchableOpacity onPress={toggleGlobalModal}
                style={{ color: 'white', padding: 8, fontSize: 18, marginTop: 10, height: 60 }}>
                <Icons name="tooltip-text" size={28} color="#fff" style={{ marginRight: '7', marginTop: -2 }} />
              </TouchableOpacity >

            </View>
          </View>
        </>



        <StatusBar backgroundColor="transparent" translucent={true} />

          <View style={{ marginTop: -7 }}>
            <View style={style.main}>
              <Text style={{ textAlign: 'center', fontSize: 27, fontWeight: 'bold', color: '#2f78ab', marginTop: '3%', marginBottom: 20 }}>
                {lang === 'fr' ? nomFr : nomEn} ({idmod})
              </Text>

              {QuizItem.mainSurface ?
              <View style={[stylesForm.formSurface, { top: 60 }]}>
              <Counter name={"hands"} setMains={getHandsNbr} nbr={QuizItem.main} cnt={QuizData && QuizData.mainNb?QuizData.mainNb: 0} />
              <Counter name={"surface"} setSurfaces={getSurfaceNbr} nbr={QuizItem.surface} cnt={QuizData && QuizData.surfaceNb?QuizData.surfaceNb: 0} />

            </View>
            : null}
                <ScrollView stickyHeaderIndices={[1]}>

              <View style={stylesForm.formContainer}>
                <View style={{ alignItems: 'right' }}>
                  <FormStepByStep quiz={QuizItem} competanceList={competanceList} setCompetanceList={setCompetanceList}
                    setPointsToImprove={setPointsToImprove} setPointsNotAquired={setPonitsNotAquired} globalComment={globalComment}
                    pointsToImprove={pointsToImprove} idModule={QuizItem.idModule} cidInscription={cidInscription}
                  />
                </View>
              </View>

              {/* Actions Section */}
              {QuizItem.actions && QuizItem.actions.length > 0 ? (
                <ActionItem
                  actions={actions}
                  toggleSwitch={toggleSwitch}
                  toggleComment={toggleComment}
                  commentStates={commentStates}
                  handleCommentSend={handleCommentSend}
                  editedComment={editedComment}
                  setEditedComment={setEditedComment}
                />
              ) : null}


              <Text style={{ color: '#2f78ab', fontWeight: '500', fontSize: 28, marginTop: 30 }} >{t('titleGlobal')}</Text>
              {/* Strong Points Section */}
              <StrongPoints
                strongModalVisible={isStrongModalVisible}
                toggleStrongModal={toggleStrongModal}
                message={message}
                setMessage={setMessage}
                handleStrongPointSend={handleStrongPointSend}
                strongPoints={strongPoints}
                selectedStrongPoints={selectedStrongPoints}
                toggleCheckbox={toggleCheckbox}
                commentaireStrong={commentaireStrong}
                setCommentaireStrong={setCommentaireStrong}
              />

              {/* Updated Points to Improve */}

              <View style={stylesForm.panelBlock}>
                <View style={stylesForm.panel}>
                  <Text style={stylesForm.panelHeader}>{t('pointsAmeliorer')}</Text>
                  <TouchableOpacity onPress={() => togCommentImprove()}>
                    <Icons
                      name={commentaireImprove ? 'square-edit-outline' : 'tooltip-text'}
                      size={28}
                      color="#fff"
                      style={{ marginRight: '7' }}
                    />
                  </TouchableOpacity>
                </View>
                <View style={stylesForm.panelBody}>
                  <View>
                    {pointsToImprove.map((point, index) => (
                      <Checkbox
                        key={index}
                        label={point}
                        selected={pointsToImprove.includes(point)}

                        disabled={false}
                      />
                    ))
                    }
                  </View>
                  <View>
                    {editModeImprove ? (
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <TextInput
                          name="commentaireImprove"
                          placeholder={t('writeComment')}
                          type="text"
                          multiline
                          style={{ width: '80%', backgroundColor: '#cce0ff', borderRadius: 5 }}
                          value={commentaireImprove}
                          onChangeText={(comment) => setCommentaireImprove(comment)}
                        />
                        <TouchableOpacity onPress={() => handleCommentImproveSend()}>
                          <Icons name="send" size={28} color="#1052b5" />
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        {commentaireImprove ? (
                          <View style={styles.commentContainer}>
                            <View style={styles.commentBox}>
                              <Icons name='text-box' size={20} color="#c4dffb" style={styles.commentIcon} />
                              <Text style={styles.commentText}>{commentaireImprove}</Text>
                            </View>
                          </View>
                        ) : null}
                      </View>
                    )}
                  </View>
                </View>
              </View>
              {/* Updated Points not Acquired */}
              <View style={stylesForm.panelBlock}>
                <View style={stylesForm.panel}>
                  <Text style={stylesForm.panelHeader}>{t('pointsnonAquis')}</Text>
                  <TouchableOpacity onPress={() => togCommentAcquired()}>
                    <Icons
                      name={commentaireAcquired ? 'square-edit-outline' : 'tooltip-text'}
                      size={28}
                      color="#fff"
                      style={{ marginRight: '7' }}
                    />
                  </TouchableOpacity>
                </View>
                <View style={stylesForm.panelBody}>
                  <View>
                    {
                      pointsNotAquired.map((point, index) => (
                        <Checkbox
                          key={index}
                          label={point}
                          selected={pointsNotAquired.includes(point)}

                          disabled={false}
                        />
                      ))
                    }
                  </View>
                  <View>
                    {editModeAcquired ? (
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <TextInput
                          name="commentaireAcquired"
                          placeholder={t('writeComment')}
                          type="text"
                          multiline
                          style={{ width: '80%', backgroundColor: '#cce0ff', borderRadius: 5 }}
                          value={commentaireAcquired}
                          onChangeText={(comment) => setCommentaireAcquired(comment)}
                        />
                        <TouchableOpacity onPress={() => handleCommentAcquiredSend()}>
                          <Icons name="send" size={28} color="#1052b5" />
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        {commentaireAcquired ? (
                          <View style={styles.commentContainer}>
                            <View style={styles.commentBox}>
                              <Icons name='text-box' size={20} color="#c4dffb" style={styles.commentIcon} />
                              <Text style={styles.commentText}>{commentaireAcquired}</Text>
                            </View>
                          </View>
                        ) : null}
                      </View>
                    )}
                  </View>
                </View>
              </View>

              {/* Buttons for navigation */}
              <View style={stylesForm.buttonContainer}>
                <TouchableOpacity
                  onPress={handleRetakeRequired}
                  style={stylesForm.button}
                >
                  <Text style={stylesForm.buttonText}>{t('retakeRequired')}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={handleSatisfactoryPress}
                  style={stylesForm.buttonSuccess}
                >
                  <Text style={stylesForm.buttonText}>{t('satisfactory')}</Text>
                </TouchableOpacity>
              </View>
              </ScrollView>
            </View>
          </View>
       


        {/* Modal d'envoie des données  */}
        <Modal
          animationType="slide"
          transparent={true}
          visible={sendDataModal}
          onRequestClose={handleCloseSendModal}
        >
          <View style={stylesForm.modalContainer}>
            <View style={[stylesForm.modalContent, { width: screenWidth * 0.8 }]}>
              <View style={stylesForm.modalHeader}>

                <TouchableOpacity onPress={handleCloseSendModal}>
                  <Icons name="close" size={28} color="#eb8181" />
                </TouchableOpacity>
              </View>
              <View >
                <Text style={stylesForm.modalHeaderText}>{t('sendDataMessage')}</Text>
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "3%" }}>
                  <TouchableOpacity style={styles.button} onPress={handleCloseSendModal} >
                    <Text style={stylesForm.buttonText}>{t('no')}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.buttonSuccess} onPress={handleSendData} >
                    <Text style={stylesForm.buttonText}>{t('yes')}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </Modal>



        {/* Modal d'envoie des données  de retake*/}
        <Modal
          animationType="slide"
          transparent={true}
          visible={sendRetakeModal}
          onRequestClose={handleCloseSendRetakeModal}
        >
          <View style={stylesForm.modalContainer}>
            <View style={[stylesForm.modalContent, { width: screenWidth * 0.8 }]}>
              <View style={stylesForm.modalHeader}>

                <TouchableOpacity onPress={handleCloseSendRetakeModal}>
                  <Icons name="close" size={28} color="#eb8181" />
                </TouchableOpacity>
              </View>
              <View >
                <Text style={stylesForm.modalHeaderText}>{t('sendDataRetakeMessage')}</Text>
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "3%" }}>
                  <TouchableOpacity style={styles.button} onPress={handleCloseSendRetakeModal} >
                    <Text style={stylesForm.buttonText}>{t('no')}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.buttonSuccess} onPress={handleSendDataRetake} >
                    <Text style={stylesForm.buttonText}>{t('yes')}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </Modal>



        {/* Modal de'erreur competances  */}
        <Modal
          animationType="slide"
          transparent={true}
          visible={errorCompetances}
          onRequestClose={handleCloseErrorCompModal}
        >
          <View style={[stylesForm.modalContainer, { justifyContent: 'center', alignItems: 'center' }]}>
            <View style={[stylesForm.modalContent, { width: screenWidth * 0.8 }]}>
              <View style={stylesForm.modalHeader}>
                <Text style={stylesForm.modalHeaderText}>{t('error')}</Text>
                <TouchableOpacity onPress={handleCloseErrorCompModal}>
                  <Icons name="close" size={28} color="#eb8181" />
                </TouchableOpacity>
              </View>
              <View>
                <Text style={stylesForm.modalHeaderText}>{t('errorCompetances')}</Text>
                <Button title="OK" onPress={handleCloseErrorCompModal} />
              </View>
            </View>
          </View>
        </Modal>

        {/* Modal de'erreur Sous competances  */}
        <Modal
          animationType="slide"
          transparent={true}
          visible={errorSousCompetances}
          onRequestClose={handleCloseErrorSousCompModal}
        >
          <View style={[stylesForm.modalContainer, { justifyContent: 'center', alignItems: 'center' }]}>
            <View style={[stylesForm.modalContent, { width: screenWidth * 0.8 }]}>
              <View style={stylesForm.modalHeader}>
                <Text style={stylesForm.modalHeaderText}>{t('error')}</Text>
                <TouchableOpacity onPress={handleCloseErrorSousCompModal}>
                  <Icons name="close" size={28} color="#eb8181" />
                </TouchableOpacity>
              </View>
              <View>
                <Text style={stylesForm.modalHeaderText}>{t('errorSousCompetances')}</Text>
                <Button title="OK" onPress={handleCloseErrorSousCompModal} />
              </View>
            </View>
          </View>
        </Modal>


        {/* Modal de General Comment  */}
        <Modal
          animationType="slide"
          transparent={true}
          visible={isGlobalModalVisible}
          onRequestClose={toggleGlobalModal}
        >
          <View style={[stylesForm.modalContainer, { justifyContent: 'center', alignItems: 'center' }]}>
            <View style={[stylesForm.modalContent, { width: screenWidth * 0.8 }]}>
              <View style={stylesForm.modalHeader}>
                <Text style={stylesForm.modalHeaderText}>{t('globalComment')}</Text>
                <TouchableOpacity onPress={toggleGlobalModal}>
                  <Icons name="close" size={28} color="#eb8181" />
                </TouchableOpacity>
              </View>
              <View>
                <TextInput
                  placeholder={t('typeGlobalComment')}
                  value={globalComment}
                  onChangeText={(text) => setGlobalComment(text)}
                  multiline
                  style={stylesForm.input}
                />
                <Button title={t('send')} onPress={handleGlobalCommentSend} />
              </View>
            </View>
          </View>
        </Modal>
        {/* Modal de chat */}
        <Modal
          animationType="slide"
          transparent={true}
          visible={isStrongModalVisible}
          onRequestClose={toggleStrongModal}
        >
          <View style={[stylesForm.modalContainer, { justifyContent: 'center', alignItems: 'center' }]}>
            <View style={[stylesForm.modalContent, { width: screenWidth * 0.8 }]}>
              <View style={stylesForm.modalHeader}>
                <Text style={stylesForm.modalHeaderText}>{t('addStrongPoints')}</Text>
                <TouchableOpacity onPress={toggleStrongModal}>
                  <Icons name="close" size={28} color="#eb8181" />
                </TouchableOpacity>
              </View>
              <View>
                <TextInput
                  placeholder={t('typeStrongPoints')}
                  value={message}
                  onChangeText={(text) => setMessage(text)}
                  multiline
                  style={stylesForm.input}
                />
                <Button title={t('send')} onPress={handleStrongPointSend} />
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </SafeAreaView>
  );
};
const styles = {
  commentContainer: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#f0f0f0',
    borderRadius: 8,
  },
  commentBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentIcon: {
    marginRight: 10,
  },
  commentText: {
    fontSize: 16,
    color: '#333',
  },
  button: {
    ...style.btn,
    marginVertical: 20,
    backgroundColor: '#c90000',
    minWidth: 50,
    borderRadius: 18,
    marginRight: "2%"
  },
  buttonSuccess: {
    ...style.btn,
    marginVertical: 20,
    borderRadius: 18,
    backgroundColor: '#12812e',
    minWidth: 50
  },
};
export default MultiSteps;
