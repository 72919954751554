import React from 'react';
import {  View, Text, TouchableOpacity } from 'react-native';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Colors } from '../../theme/color';
import style from '../../theme/style';
import stylesForm from '../../theme/step';

const Checkbox = ({ label, selected, onSelect, disabled }) => (
<TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 8,
        opacity: disabled ? 0.5 : 1,
      }}
      onPress={() => {
        if (!disabled) {
          onSelect(label); // Pass the label to onSelect
        }
      }}
      disabled={disabled}
    >
      {/* Checkbox design */}
      <View
        style={{
          height: 24,
          width: 24,
          borderRadius: 5,
          borderWidth: 2,
          borderColor: disabled ? '#2f47a4' : '#3b86e9',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 5,
          marginLeft: 15,
        }}
      >
        {selected ? (
          <View
            style={{
              height: 15,
              width: 15,
              backgroundColor: disabled ? '#2f47a4' : '#3b86e9',
            }}
          />
        ) : null}
      </View>
      <Text style={[stylesForm.checkboxLabel, selected && { color: '#2a4a99' }]}>{label}</Text>
    </TouchableOpacity>
  );

export default Checkbox;

