import React from 'react';
import { View, TextInput, Text, Alert } from 'react-native';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import { darkGreen } from './Constants';

const Field = ({ placeholder, keyboardType, icon, required, ...props }) => {
  const handleBlur = (text) => {
    if (required && !text) {
      Alert.alert('Field is Required', `Please enter ${placeholder}`);
    }
  };

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', borderRadius: 50, backgroundColor: 'rgb(235 235 235)', marginVertical: 10, width:'78%'}}>
      <Icons name={icon} size={20} color={darkGreen} style={{ marginHorizontal: 10 }} />
      <TextInput
        {...props}
        onBlur={() => handleBlur(props.value)}
        style={{borderRadius: 100, color: darkGreen, padding: 15, width: '100%', backgroundColor: 'rgb(235 235 235)'}}
        placeholder={`${placeholder}${required ? ' *' : ''}`}
        placeholderTextColor={darkGreen}
        keyboardType={keyboardType}
      />
    
    </View>
  );
};

export default Field;
