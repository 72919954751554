import { View, Text, SafeAreaView, Dimensions, ImageBackground, TouchableOpacity, TextInput, Image, ScrollView, StatusBar } from 'react-native'
import React, { useState, useContext, useEffect } from 'react'
import { AppBar } from '@react-native-material/core';
import { Colors } from '../theme/color';
import { Avatar } from 'react-native-paper';
import style from '../theme/style';
import stylesform from '../theme/styles';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons'
import { useNavigation } from '@react-navigation/native';
import { useStorage } from "../contextApp";
import { Config } from "../config";
import BottomBar from './composants/BottomBar';
import { useTranslation } from 'react-i18next';
import NetInfo from '@react-native-community/netinfo';
const width = Dimensions.get('screen').width
const height = Dimensions.get('screen').height

import ChangeLanguage from "./composants/ChangeLang";
export default function RecodedData() {
  const { storageState,setItem } = useStorage();
  const navigation = useNavigation();
  const apiUrl = Config.apiUrl + "/" + "resto/bce/quiz/sync";
  const userApi = Config.userApi;
  const passApi = Config.passApi;
  const backUrl =Config.backUrl;
  const dataSend = storageState.apprenants;
  //width screen 
  const screenWidth = Dimensions.get('window').width;
// Déterminez le nombre de colonnes en fonction de la largeur de l'écran
const valueWidth = screenWidth > 600 ? 60 : 90;
  //console.log('deja sync record---------->', dataSend);
  const usernameEva = storageState.formateurUser;
  const type = storageState.formateurType;
  const userAp = storageState.username;
 const filterQuizs = storageState.filterQuizs;
 const lastQuizs = storageState.lastQuizs;
  const lang = storageState.lang;
  const avatarLetters = usernameEva.slice(0, 2).toUpperCase();
  const avatarLettersUser = userAp.slice(0, 2).toUpperCase();

  const initialConnectionInfo =  NetInfo.fetch();

  const { t } = useTranslation();
//console.log('seg ---------->', filterQuizs);
  const [isData,setIsData] = useState(false)
  const [alert, setAlert] = useState(null);
  useEffect(() => {
    if(dataSend.length>0) {
      setIsData(true)
    }
    //console.log('apprenant:', dataSend);
  }, [dataSend]);
  const handleLogoutFormateur = ()=>  {
    setAlert({ type: 'success', message: t('logoutSuccess') });

   var langue=lang
    localStorage.clear();
    setItem('language',langue)
    setItem('lang',langue)
    setTimeout(() => {
      navigation.navigate('Login');
      setAlert(null)

    }, 1200); 
   
  }
  let logoutTimer; // Variable globale pour stocker le compte à rebours

// Fonction pour réinitialiser le compte à rebours à 30 minutes
const resetLogoutTimer = () => {
  clearTimeout(logoutTimer); // Réinitialiser le compte à rebours à chaque action de l'utilisateur
  logoutTimer = setTimeout(handleLogoutLearner, 30 * 60 * 1000); // 30 minutes en millisecondes
};
const handleLogoutLearner = ()=>  {
  setAlert({ type: 'success', message: t('logoutSuccess') });

 localStorage.removeItem('quizData');
 localStorage.removeItem('username');
 localStorage.removeItem('type');

  setTimeout(() => {
    navigation.navigate("Profil",{
      usernameEva: usernameEva,
      type: type,
      lang: lang,
    });
    setAlert(null)

  }, 1200); 
 
}
const [isConnected, setIsConnected] = useState(true);

useEffect(() => {
  const checkConnection = async () => {
 
    setIsConnected(initialConnectionInfo.isConnected);

    const unsubscribe = NetInfo.addEventListener(state => {
      setIsConnected(state.isConnected);
      setShowBottomBar(true);
      setTimeout(() => {
        setShowBottomBar(false);
      }, 3000);
    });

    return () => {
      unsubscribe();
    };
  };

  checkConnection();
}, [initialConnectionInfo]);
const [showBottomBar, setShowBottomBar] = useState(false);
const handleOfflineClick = () => {
  setShowBottomBar(true);
  setAlert({ type: 'error', message: t('errorConnexion') });
  setTimeout(() => {
   
    setShowBottomBar(false);
    setAlert(null);
  }, 3000);

};
  const handleUpdateStatus = async (nomUsager) => {
    try {
      const apiUrl = backUrl +`/app/update-status/${nomUsager}`;
  
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {

        setItem('apprenants', []);
        setItem('competances', []);
        
        
        const updatedFilterQuizs = filterQuizs.map(quiz => {
          if (quiz.competences) {
            return {
              ...quiz,
              competences: quiz.competences.map(({ statut, ...rest }) => rest)
            };
          } else {
            return quiz;
          }
        });
        
        // Logging the updated array
        //console.log(updatedFilterQuizs);
        
        setItem('filterQuizs', updatedFilterQuizs);
        //console.log(`Update status for ${nomUsager} successful`);
      } else {
        console.error(`Error updating status for ${nomUsager}:`, response.statusText);
        setAlert({ type: 'error', message: t('errorSync') });
      }
      setTimeout(() => {
        setAlert(null); // Clear the alert after 7 seconds
      }, 3000);
    } catch (error) {
      console.error(`Error updating status for ${nomUsager}:`, error);
      setAlert({ type: 'error', message: t('errorSync') });
    }
  };
  
  
  const handleSyncronization = async () => {
    try {
      if (dataSend.length > 0 && dataSend[0].hasOwnProperty("listeQuiz")) {
        dataSend.forEach(entry => {
          if (entry.hasOwnProperty("listeQuiz")) {
            entry.listeQuiz.forEach(quiz => {
              if (quiz.hasOwnProperty("cour")) {
                delete quiz.cour;
              }
            });
          }
        });
      } ;
      
      if (dataSend.length > 0 && dataSend[0].hasOwnProperty("medicals")) {
        dataSend.forEach(entry => {
          if (entry.hasOwnProperty("medicals")) {
            entry.medicals.forEach(medical => {
              if (medical.hasOwnProperty("cour")) {
                delete medical.cour;
              }
              if (medical.hasOwnProperty("dateComplete")) {
                delete medical.dateComplete;
              }
            });
          }
        });
      }
      //console.log('dataSend après suppression de cour dans toutes les entrées de listeQuiz et medicale:', dataSend);
      
      //console.log('data to send:', dataSend);
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(userApi + ":" + passApi),
          "BCE-Quess-Auth": localStorage.getItem("jwt")
        },
        body: JSON.stringify({ apprenants: dataSend }),
      };
  
      // Vérification si le nom d'utilisateur et le mot de passe sont fournis
      if (!dataSend) {
        //console.log('error data send');
        // Handle the case where dataSend is empty
        return;
      }
  
      // Appel de l'API pour la connexion de l'apprenant
      const responseSyncron = await fetch(apiUrl, requestOptions);
  
      // Vérification de la réponse de l'API
      if (responseSyncron.ok) {
        const data = await responseSyncron.json();
        //console.log('Success send apprenant', data);
       
        setAlert({ type: 'success',message: t('successSync') });
        
        dataSend.forEach((apprenant) => {
          handleUpdateStatus(apprenant.nomUsager);
        });
        // Handle success response
      } else {
        //console.log('Error sending data');
        setAlert({ type: 'error', message: t('errorSync')  });
        // Handle error response
        throw new Error("Network response was not ok");
      }
      setTimeout(() => {
        setAlert(null); // Clear the alert after 7 seconds
      }, 4000);
    } catch (error) {
      setAlert({ type: 'error', message: t('errorSync')  });
      console.error("There was a problem with the synchronization:", error);
      // Handle other errors
    }
  };
  
  const goToCourse = async ()=> {
   
      navigation.navigate('Practical Assessment',{
        datatosync: dataSend
      })

   
  }
  return (
    <SafeAreaView style={[style.area, {}]}>
      <StatusBar backgroundColor="transparent" translucent={true} />
      <ImageBackground
        source={require('../../assets/image/BG_0.png')}
        resizeMode="stretch"
        style={stylesform .backgroundImage}
      >
        <StatusBar backgroundColor="transparent" translucent={true} />
        <View style={stylesform .headerContainer}>
        <Avatar.Text
          size={40} label={avatarLetters}
          style={{  backgroundColor: '#c8dcff',  marginTop: 2,
            alignSelf: "center",  color:'#043ca5'  }}
        />
          <View style={stylesform .greetingContainer}>
            <Text style={stylesform .greetingText}>
            {t('hi')}, {usernameEva}
            </Text>
            <Text style={stylesform .welcomeText}>{t(type)}</Text>
          </View>
        <ChangeLanguage />
        <Icons name="power" style={{ fontSize: 34,marginLeft:10, color: "white" }} onPress={handleLogoutFormateur} />
        </View>
      </ImageBackground>
      <View style={stylesform.infoContainer}>
        <View style={stylesform.userInfoBox}>
          <View style={stylesform.userInfoRow}>
          <Avatar.Text size={50} label={avatarLettersUser} style={{  backgroundColor: '#0c3989',  marginTop: 5, alignSelf: "center" }} />
            <View style={stylesform.userTextContainer}>
              <Text style={stylesform.userText}>{userAp}</Text>
              <Text style={stylesform.userTypeText}>{t('apprenantConnecte')}</Text>
            </View>
            <View> <Icons name="power-settings" style={{ fontSize: 47,marginRight:20, paddingRight:20,color: "#0c3989" }} onPress={handleLogoutLearner} /></View>
          </View>
        
        </View>
  
      </View>
      <View style={[style.main, { marginTop: -30 }]}>
      <ImageBackground
        source={require('../../assets/image/BG-L.png')} style={{ flex: 1, height: '100%',resizeMode: 'cover' }}
      >
        <ScrollView showsVerticalScrollIndicator={false} style={{ marginTop: 100 }}>
        {alert && (
          <View style={[stylesform.alertContainer, { backgroundColor: alert.type === 'error' ? '#FFBABA' : '#D5E8D4' }]}>
            <Text style={[stylesform.alertText, { color: alert.type === 'error' ? '#D8000C' : '#4F8A10' }]}>
              {alert.message}
            </Text>
          </View>
        )}
          <View style={{ flexDirection: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
            <Text style={[style.s17, { color: Colors.active }]}></Text>
            <TouchableOpacity onPress={() => navigation.navigate('Specialist')}
              style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={[style.s13, { color: Colors.lable }]}></Text>
            </TouchableOpacity>
          </View>
         <View style={{justifyContent: 'center', alignItems: 'center'  }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' ,marginTop:15, width: `${valueWidth}%`,}}>
            {/* <TouchableOpacity style={{ flex: 1,
                   backgroundColor: '#466badcf',
                    borderRadius: 35, paddingVertical: 15, paddingHorizontal: 24 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                <Text style={[style.s18, { color: Colors.secondary }]}> {t('startNewSegment')} </Text>
                <Icons name='book-plus-multiple' style={{fontSize:35,color:'white'}}/>
                  
                </View>
                </TouchableOpacity> */}

                <TouchableOpacity onPress={filterQuizs && filterQuizs.length > 0 ?goToCourse:''} style={{ flex: 1,
                   backgroundColor:filterQuizs && filterQuizs.length > 0 ? '#013E7F' : '#466badcf',
                    borderRadius: 35, paddingVertical: 15, paddingHorizontal: 24 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                <Text style={[style.s18, { color: Colors.secondary }]}> {t('startNewSegment')} </Text>
                <Icons name='book-plus-multiple' style={{fontSize:35,color:'white'}}/>
                  
                </View>
                </TouchableOpacity>
        
            
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' ,marginTop:15,width:`${valueWidth}%`}}>
            <TouchableOpacity
    onPress={ () => navigation.navigate('Home')}
    style={{
      flex: 1,
      backgroundColor: isConnected?'#013E7F' : '#466badcf',
      borderRadius: 35,
      paddingVertical: 12,
      paddingHorizontal: 24,
    }}
    disabled={!isConnected}
  >
    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
      <Text style={[style.s18, { color: Colors.secondary }]}>{t('selectCourse')}</Text>
      <Icons name='arrow-right-bold' style={{ fontSize: 35, color: 'white' }} />
    </View>
  </TouchableOpacity>
            

            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' ,marginTop:15,width:`${valueWidth}%`}}>
                <TouchableOpacity  onPress={() => navigation.navigate("DataTransfere" ,{data:""})}
                style={{ flex: 1,
                   backgroundColor: '#013E7F' 
                , borderRadius: 35, paddingVertical: 12,  paddingHorizontal: 24 }}>
            
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
             
                    <Text style={[style.s18, { color: Colors.secondary }]}>   {t('checkData')}</Text>
                    <Icons name='compare-horizontal' style={{fontSize:35,color:'white'}}/>
                </View>
                </TouchableOpacity>
            

            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 15, width: `${valueWidth}%` }}>
  <TouchableOpacity
    onPress={ handleSyncronization }
    style={{
      flex: 1,
      backgroundColor: dataSend.length > 0  ? isConnected?'#013E7F' : '#466badcf': '#466badcf',
      borderRadius: 35,
      paddingVertical: 12,
      paddingHorizontal: 24,
    }}
    disabled={ dataSend.length === 0 || !isConnected}
  >
    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
      <Text style={[style.s18, { color: Colors.secondary }]}>{t('synchronizeData')}</Text>
      <Icons name="cached" style={{ fontSize: 35, color: "white" }} />
    </View>
  </TouchableOpacity>
</View>
         </View>
       
        </ScrollView>
        </ImageBackground>
      </View>
      <BottomBar />
    </SafeAreaView>
  )
}