import React, { useState, useEffect } from 'react';
import { View, Dimensions, Text, TouchableOpacity, TextInput, StyleSheet, ScrollView } from 'react-native';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useStorage } from '../contextApp';
import stylesForm from '../theme/step';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

const FormStepByStep = ({ quiz, competanceList, setCompetanceList, globalComment, setPointsToImprove, setPointsNotAquired, pointsToImprove, idModule, cidInscription }) => {
  const { storageState } = useStorage();
  const lang = storageState.lang;
  const quizObj = storageState.competances ? storageState.competances.find((el) => el.idModule == idModule && el.cidInscription === cidInscription) : null
  const competances = quizObj != null ? quizObj.competances
    : quiz.competences


    const [commentairesByCompetence, setCommentairesByCompetence] = useState(
      competances.map(comp => comp.commentaire || '')
    );
    const [editCommentCompetance, setEditCommentCompetance] = useState(false);
    const [selectedRadioValues, setSelectedRadioValues] = useState(
      Array(competances.length).fill(null)
    );
 
  const [checkboxStatesByStep, setCheckboxStatesByStep] = useState(
    Array(competances.length).fill([]).map(() => [])
  );
  const { t } = useTranslation();
  const screenWidth = Dimensions.get('window').width;
  const [checkedImprovementSubCompetences, setCheckedImprovementSubCompetences] = useState(Array(competances.length).fill([]));
  useEffect(() => {
 //check langue 
    //console.log("langue update",lang);
  }, [lang]);
  // Déterminez le nombre de colonnes en fonction de la largeur de l'écran
  const numColumns = screenWidth > 600 ? 4 : 2;
  const existingStepTitles = competances.map((competence) => ({
    id: competence.id,
    order: 1,
    title: lang === 'fr' ? competence.nomFrComp ? competence.nomFrComp : competence.texte : competence.nomEnComp ? competence.nomEnComp : competence.texte,
    item: competence.sousCompetences.map((sousComp) =>
      lang === 'fr' ? sousComp.nomFrSousComp ? sousComp.nomFrSousComp : sousComp.texte : sousComp.nomEnSousComp ? sousComp.nomEnSousComp : sousComp.texte
    ),
    statut: competence.statut,
    commentaire: competence.commentaire
  }));
  //console.log("exist::",existingStepTitles)
  const dataStatus = [
    { key: 'acquis', label: t('acquis') },
    { key: 'ameliorer', label: t('ameliorer') },
    { key: 'nonAcquis', label: t('nonAcquis') },
    { key: 'nonEvalue', label: t('nonEvalue') }
  ];
  const [currentDateISO, setCurrentDateISO] = useState(moment().tz("America/Montreal").format());
  useEffect(() => {
    // Initialize radio button and checkbox states based on existing data
    const initialRadioValues = competances.map((competence) => competence.statut || null);
    const initialCheckboxStates = competances.map((competence) =>
      competence.sousCompetences.map((sousComp) => sousComp.coche ? lang === 'fr' ? sousComp.nomFrSousComp ? sousComp.nomFrSousComp : sousComp.texte : sousComp.nomEnSousComp ? sousComp.nomEnSousComp : sousComp.texte : undefined)

    );

    setSelectedRadioValues(initialRadioValues);
    setCheckboxStatesByStep(initialCheckboxStates);
    ////console.log("competance step 0",competances) 
  }, [competances]);
  
  //commentaire improve
  const [editCommentCompetanceIndex, setEditCommentCompetanceIndex] = useState(null);
  const [openCommentIndices, setOpenCommentIndices] = useState([]);

  const togCommentImprove = (index) => {
    if (openCommentIndices.includes(index)) {
      setOpenCommentIndices(openCommentIndices.filter(idx => idx !== index));
      setEditCommentCompetanceIndex(null);
    } else {
      setOpenCommentIndices([...openCommentIndices, index]);
      setEditCommentCompetanceIndex(index);
    }
  };

  ////console.log("checkboxes",checkboxStatesByStep) 
  const handleCommentImproveSend = (index) => {
    setEditCommentCompetance(false);
    setEditCommentCompetanceIndex(null);
    togCommentImprove(index);

    const updatedCommentaires = [...commentairesByCompetence];
    updatedCommentaires[index] = commentairesByCompetence[index];
    setCommentairesByCompetence(updatedCommentaires);
  };
  useEffect(() => {
    setCurrentDateISO(moment().tz("America/Montreal").format());
    ////console.log('date isoooo:', currentDateISO);
  }, [currentDateISO]);
  useEffect(() => {
    //  
    // const currentDateISO = new Date().toISOString();

    const ptsImp = [];
    const ptsNotAq = [];
    // Update competanceList when checkboxStatesByStep changes
    const stepTitles = existingStepTitles.map((step, index) => (
      {
        id: step.id,
        ordre: index,
        texte: step.title,
        dateSaisie: currentDateISO,
        statut: selectedRadioValues[index],
        commentaire: commentairesByCompetence[index],
        sousCompetences: step.item.map((sousCompText) => ({
          texte: sousCompText,
          coche:  checkboxStatesByStep[index].includes(sousCompText),
          dateSaisie: currentDateISO,
        })),

      }
    ));

    existingStepTitles.map((step, index) => {


      if (selectedRadioValues[index] === "ameliorer") {
        step.item.map((sousCompText) => {

          if (checkboxStatesByStep[index].includes(sousCompText)) {
            ptsImp.push(sousCompText)
          }

        })
      }
      else if (selectedRadioValues[index] === "nonAcquis") {
        ptsNotAq.push(step.title)
      }
    });
    setPointsToImprove(ptsImp)
    setPointsNotAquired(ptsNotAq)
    setCompetanceList(stepTitles);
   
  }, [checkboxStatesByStep, selectedRadioValues, commentairesByCompetence, setCompetanceList]);


  const Checkbox = ({ label, selected, onSelect, disabled }) => (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 8,
        opacity: disabled ? 0.5 : 1,
      }}
      onPress={() => {
        if (!disabled) {
          onSelect(label);
        }
      }}
      disabled={disabled}
    >
      <View
        style={{
          height: 24,
          width: 24,
          borderRadius: 5,
          borderWidth: 2,
          borderColor: disabled ? '#2f47a4' : '#3b86e9',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 5,
          marginLeft: 15,
        }}
      >
        {selected ? (
          <View
            style={{
              height: 15,
              width: 15,
              backgroundColor: disabled ? '#2f47a4' : '#3b86e9',
            }}
          />
        ) : null}
      </View>
      <Text style={{ fontSize: 16, fontWeight: 'bold', color: disabled ? '#2f47a4' : '#1d4b8b' }}>
        {label}
      </Text>
    </TouchableOpacity>
  );
  const RadioButton = ({ label, selected, onPress }) => (
    <TouchableOpacity onPress={onPress}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View
          style={{
            height: 24,
            width: 24,
            borderRadius: 12,
            borderWidth: 2,
            borderColor: '#fff',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 8,
          }}
        >
          {selected ? (
            <View
              style={{
                height: 12, borderWidth: 2,
                width: 12, borderColor: '#02bd31',
                borderRadius: 6,
                backgroundColor: '#02bd31',
              }}
            />
          ) : null}
        </View>
        <Text>{label}</Text>
      </View>
    </TouchableOpacity>
  );
  const handleStatusChange = (index, newStatus) => {
    // Si le nouveau statut n'est pas "ameliorer", décocher toutes les sous-compétences
    if (newStatus !== 'ameliorer') {
      const updatedCheckboxStates = [...checkboxStatesByStep];
      updatedCheckboxStates[index] = [];
      setCheckboxStatesByStep(updatedCheckboxStates);
    } else {
      // Si le nouveau statut est "ameliorer", cocher les sous-compétences précédemment cochées
      const updatedCheckboxStates = [...checkboxStatesByStep];
      updatedCheckboxStates[index] = checkedImprovementSubCompetences[index];
      setCheckboxStatesByStep(updatedCheckboxStates);
    }

    // Mettre à jour le statut de la compétence principale
    const updatedRadioValues = [...selectedRadioValues];
    updatedRadioValues[index] = newStatus;
    setSelectedRadioValues(updatedRadioValues);
  };

  // Fonction pour gérer la sélection des sous-compétences
  const handleCheckboxSelect = (index, sousCompText) => {
    const updatedCheckboxStates = [...checkboxStatesByStep];
  
    // Vérifier si la sous-compétence est déjà cochée
    const isChecked = updatedCheckboxStates[index].includes(sousCompText);
  
    // Si la sous-compétence est déjà cochée, la retirer de la liste
    if (isChecked) {
      updatedCheckboxStates[index] = updatedCheckboxStates[index].filter(
        (text) => text !== sousCompText
      );
    } else {
      // Sinon, l'ajouter à la liste des sous-compétences cochées
      updatedCheckboxStates[index] = [...updatedCheckboxStates[index], sousCompText];
    }
  
    // Mettre à jour l'état des cases à cocher
    setCheckboxStatesByStep(updatedCheckboxStates);
  
    // Mettre à jour les compétences associées dans la liste des compétences
    updateCompetanceList(index, updatedCheckboxStates[index]);
  
    // Mettre à jour les sous-compétences cochées si le statut est "ameliorer"
    if (selectedRadioValues[index] === 'ameliorer') {
      setCheckedImprovementSubCompetences((prev) => {
        const newCheckedImprovementSubCompetences = [...prev];
        newCheckedImprovementSubCompetences[index] = updatedCheckboxStates[index];
        return newCheckedImprovementSubCompetences;
      });
    }
  };

  
  ////console.log("competanceList: 1::", competanceList); 
 const updateCompetanceList = (index, updatedCheckboxState) => {
  // Créez une copie de la liste des compétences
  const updatedCompetanceList = [...competanceList];

  // Mettez à jour les sous-compétences pour la compétence spécifique
  updatedCompetanceList[index].sousCompetences = updatedCompetanceList[index].sousCompetences.map((sousComp) => ({
    ...sousComp,
    coche: updatedCheckboxState.includes(sousComp.texte)
  }));
  //console.log("mtyconsole :::::", updatedCompetanceList)
  // Mettez à jour la liste des compétences
  setCompetanceList(updatedCompetanceList);
};


  ////console.log('CompetanceListchange ::0::', competanceList);

  const handleCommentChange = (index, text) => {
    const updatedCommentaires = [...commentairesByCompetence];
    updatedCommentaires[index] = text;
    setCommentairesByCompetence(updatedCommentaires);
  };
 

  const renderContent = () => {
    return (
      <View style={{ marginTop: '2%' }}>
        {existingStepTitles.map((step, index) => (
          <View style={stylesForm.panelBlockQuiz} key={index}>
            <View style={stylesForm.panelQuiz}>
              <Text style={stylesForm.panelHeader}>{step.title}</Text>
              <TouchableOpacity onPress={() => togCommentImprove(index)}>
                <Icons
                  name={openCommentIndices.includes(index)
                    ? 'square-edit-outline'
                    : 'tooltip-text'
                  }
                  size={28}
                  color="#fff"
                  style={{ marginRight: '7' }}
                />
              </TouchableOpacity></View>
            <View style={stylesForm.panelBody}>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: 10 }}>
                {dataStatus.map(({ key, label }) => (
                  <View
                    key={key}
                    style={{
                      width: `${96 / numColumns}%`, // Répartition égale des colonnes
                      backgroundColor: key === 'acquis' ? "#d5f7d4" :
                        key === 'ameliorer' ? "#f7f2d4" :
                          key === 'nonAcquis' ? "#f3c0c0" :
                            key === 'nonEvalue' ? "#e3dfdf" : "#b9b9b9",
                      borderColor: key === 'acquis' ? "#245d03" :
                        key === 'ameliorer' ? "#d5b504" :
                          key === 'nonAcquis' ? "#c70707" :
                            key === 'nonEvalue' ? "#a1a0a0" : "#a1a0a0",
                      borderRadius: 10,
                      borderWidth: 2,
                      alignItems: 'center',
                      paddingVertical: 10,
                      paddingHorizontal: 5,
                      marginBottom: 5,
                      marginRight: index % numColumns === numColumns - 1 ? 0 : 2

                    }}
                  >
                    <RadioButton
                      label={label}
                      selected={selectedRadioValues[index] === key}
                      onPress={() => handleStatusChange(index, key)} // Appel de handleStatusChange lors du changement de statut
                    />
                  </View>
                ))}
              </View>


              {step.item.map((sousComp, i) => (
                <Checkbox
                  key={i}
                  label={sousComp}
                  selected={checkboxStatesByStep[index].includes(sousComp)}
                  onSelect={() => handleCheckboxSelect(index, sousComp)}
                  disabled={
                    !selectedRadioValues[index] ||
                    selectedRadioValues[index] === 'acquis' || selectedRadioValues[index] === 'nonAcquis' ||
                    selectedRadioValues[index] === 'nonEvalue'
                  }
                />
              ))}
            </View>
            <View style={{ margin: 5 }}>
              {openCommentIndices.includes(index) ? (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <TextInput
                    name="commentaireCompetance"
                    placeholder={t('writeComment')}
                    type="text"
                    multiline
                    style={{ width: '80%', backgroundColor: '#cce0ff', borderRadius: 5 }}
                    value={commentairesByCompetence[index]}
                onChangeText={(text) => handleCommentChange(index, text)}
                  />
                  <TouchableOpacity onPress={() => handleCommentImproveSend(index)}>
                    <Icons name="send" size={28} color="#1052b5" />
                  </TouchableOpacity>
                </View>
              ) : (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {commentairesByCompetence[index] ? ( // Check if commentairesByCompetence exists in state
                  <View style={styles.commentContainer}>
                    <View style={styles.commentBox}>
                      <Icons name="text-box" size={20} color="#c4dffb" style={styles.commentIcon} />
                      <Text style={styles.commentText}>{commentairesByCompetence[index]}</Text>
                    </View>
                  </View>
                ) : null}
</View>
              )}
            </View>
          </View>
        ))}
      </View>
    );
  };

  return (
    <View>
      <ScrollView>
        <View style={styles.container}>
          {renderContent()}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  header: {
    fontSize: 17,
    fontWeight: 'bold',
    marginBottom: 20,
  },

  commentContainer: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#f0f0f0',
    borderRadius: 8,
  },
  commentBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentIcon: {
    marginRight: 10,
  },
  commentText: {
    fontSize: 16,
    color: '#333',
  },
});

export default FormStepByStep;