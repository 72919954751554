import React,{useContext,useState} from 'react';
import {StyleSheet,Text,View,TouchableOpacity,Image} from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
 import Home from '../screens/HomeScreen';
import Ionicons from "react-native-vector-icons/Ionicons"
import Icons from 'react-native-vector-icons/MaterialCommunityIcons'
import { Colors } from '../theme/color';
import Icon from 'react-native-vector-icons/Ionicons';
import Profile from '../screens/Profil';



const Tab = createBottomTabNavigator();

export default function MyTabs() {

  return (
    <Tab.Navigator
    screenOptions={{
      // BottomTabBarHeight:30,
      tabBarStyle: { position: 'absolute',height:65,marginBottom:20,backgroundColor:Colors.secondary,marginHorizontal:20,borderRadius:40},
      tabBarLabelStyle: {
        fontSize: 15,
      },
      tabBarShowLabel:false,
    }}>
    
  

 <Tab.Screen name="Home" component={Home}
     options={{
       tabBarShowLabel:true,
      tabBarLabel: ({focused, color,}) => (
        <Text style={{color: focused ? Colors.primary : Colors.disable,}}>MyWishlist</Text>
      ),
      tabBarIcon:({focused,color}) => {
        return <View style={{backgroundColor:focused ? '#0065FF15' : Colors.secondary,height:44,width:44,borderRadius:25,alignItems:'center',justifyContent:'center'}}>
        <Image source={require('../../assets/image/s11.png')} resizeMode='stretch' style={{height:24,width:24,tintColor:focused ? '#003CBF' : '#415473'}}></Image>
        </View>
      },
      headerShown:false,
    }} />



 <Tab.Screen name="Profile" component={Profile}
    options={{
       tabBarShowLabel:true,
      tabBarLabel: ({focused, color,}) => (
        <Text style={{color: focused ? Colors.primary : Colors.disable,}}>Profile</Text>
      ),
      tabBarIcon:({focused,color}) => {
        return <View style={{backgroundColor:focused ? '#0065FF15' : Colors.secondary,height:44,width:44,borderRadius:25,alignItems:'center',justifyContent:'center'}}>
        <Image source={require('../../assets/image/d50.png')} resizeMode='stretch' style={{height:24,width:24,tintColor:focused ? '#003CBF' : '#415473'}}></Image>
        </View>
      },
      headerShown:false,
    }} /> 

  </Tab.Navigator>
  );
}


