import React from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import Background from './Background';
import Btn from './Btn';
import { darkGreen } from './Constants';
import i18n from '../locales/i18n';
import { useStorage } from "../contextApp";
import BottomBar from './composants/BottomBar';
const WelcomeScreen = (props) => {
  const { setItem } = useStorage();

  // Fonction pour changer la langue en utilisant i18n
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setItem('lang', lng);  
    console.log('langooo',lng);// Corrected from setItem('lang', lang)
  };

  return (
    <Background style={{ alignItems: 'center' }}>
      <View style={{ marginHorizontal: 40, maxWidth: '100%', alignItems: 'center', Background: 'white' }}>
        <Text style={{ color: 'white', fontSize: 23, marginBottom: 30, }}>Welcome to </Text>
        <Image source={require('./assets/logo-en.png')} style={{ height: 70, width: 180, marginLeft: 1, marginBottom: 40 }}></Image>

        <Text style={{ color: 'white', fontSize: 16, marginTop: 70, marginBottom: 4 }}>Select your Language</Text>
        <Text style={{ color: 'white', fontSize: 16, marginTop: 1, marginBottom: 20 }}>Sélectionner votre langue</Text>

        {/* Bouton pour choisir le français */}
        <Btn
          bgColor='white'
          textColor={darkGreen}
          btnLabel="French"
          Press={() => {
            changeLanguage('fr');
            props.navigation.navigate("Login", { language: 'fr' });
          }}
        />

        {/* Bouton pour choisir l'anglais */}
        <Btn
          bgColor='white'
          textColor={darkGreen}
          btnLabel="English"
          Press={() => {
            changeLanguage('en');
            props.navigation.navigate("Login", { language: 'en' });
          }}
        />
      </View>
      <View style={{ marginTop: 150 }}></View>

    </Background>
  );
};

const styles = StyleSheet.create({});

export default WelcomeScreen;
