import { StyleSheet, Dimensions } from "react-native";
import { Colors } from "./color";
import style from './style';
const width = Dimensions.get('screen').width
const height = Dimensions.get('screen').height


export default StyleSheet.create({
    dropdown: {
      width: '100%',
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: '#f4f8fd',
      borderRadius: 4,
      borderWidth: 1,
      borderColor: '#91c7ff',
      paddingLeft: 10,
      height: 50,
      shadowOpacity: 0.27,
      shadowRadius: 4.65,
      elevation: 6,
    },  
    alertContainer: {
      paddingVertical: 13,
      paddingHorizontal: 16,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: '#5f87c4',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 10
    },
    dropdownItem: {
      color: '#333',
      fontSize: 20,
      padding: 8,
    },
    container: {
      flex: 1,
      backgroundColor: Colors.secondary,
    },
    backgroundImage: {
      height: height / 5,
    },
    headerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 20,
      marginHorizontal: 20,
    },
    userImage: {
      height: 48,
      width: 48,
    },
    greetingContainer: {
      flex: 1,
      marginLeft: 10,
    },
    alertContainer: {
      paddingVertical: 13,
      paddingHorizontal: 16,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: '#2a77a8',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 10
    },
    alertText: {
      fontSize: 15,
      fontWeight: 'bold',
    },
    greetingText: {
      ...style.s17,
      color: Colors.secondary,
    },
    welcomeText: {
      ...style.r14,
      color: '#DADBDD',
    },
    searchIcon: {
      color: Colors.secondary,
    },
    menuIcon: {
      height: 30,
      width: 30,
      marginLeft: 15,
    },
    infoContainer: {
      position: 'absolute',
      padding: 6,
      top: 58,
      alignSelf: 'center',
      width: '100%',
      maxWidth: 400,
      zIndex:999
    },
    userInfoBox: {
      ...style.shadow,
      backgroundColor: Colors.secondary,
      borderRadius: 12,
      shadowColor: Colors.active,
    },
    userInfoRow: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
      marginHorizontal: 15,
      width: '100%',
    },
    userAvatar: {
      height: 48,
      width: 48,
    },
    userTextContainer: {
      flex: 1,
      marginLeft: 10,
    },
    userText: {
      ...style.s17,
      color: Colors.primary,
    },
    userTypeText: {
      ...style.r14,
      color: '#9EBAED',
    },
    mainContainer: {
      ...style.main,
    },
    mainContainerColumn: {
      flex: 1,
      backgroundColor: Colors.secondary,
    },
    appBar:
   {
      color: Colors.secondary,
      elevation: 0,
    },
    appTitle: {
      ...style.apptitle,
      color: '#2a5cab',
    },
    scrollContainer: {
      marginTop: 10,
      alignContent: "center",
    },
    selectListContainer: {
      alignItems: "center",
    },
    selectList: {
      width: "80%",
      marginTop: 10,
    },
    labelText: {
      color: Colors.active,
      fontSize: 18,
    },
    sendButtonContainer: {
      width: 500,
      marginTop: 30,
      marginBottom: 30,
      alignItems:"center"
    },
    sendButton: {
      ...style.btn,
      width:140,
      marginVertical: 20,
      marginTop: 80,
      marginBottom:60
    },
    sendButtonText: {
      ...style.btntxt,
    },
 
  });