import React, { useState, useEffect } from 'react';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LoginScreen from '../screens/LoginScreen';
import LoginLearner from '../screens/LoginLearner';
import StageSelectionScreen from '../screens/StageSelectionScreen';
import SyncDataScreen from '../screens/SyncDataScreen';
import WelcomeScreen from '../screens/WelcomeScreen';
import Splash from '../screens/Splash';
import Profile from '../screens/Profil';
import HomeScreen from '../screens/HomeScreen';
import MyTabs from './BottomNavigator';
import Course from '../screens/Course';
import RecodedData from '../screens/recordedData';
import MultiSteps from '../screens/MultiSteps';
import DataTransfere from '../screens/dataTransfere';
import { useStorage } from '../contextApp';
import Category from '../screens/Category';
import ConditionsMedicales from '../screens/conditionMedical';
import { useTranslation } from 'react-i18next';
import DetailDataTransfere from '../screens/detailDataTransfere';
import FileViewer from '../screens/ExternalLinkButton';
const Stack = createStackNavigator();

export default function StackNavigator() {
  const { t } = useTranslation();
  const [showSplashScreen, setshowSplashScreen] = useState(true);
  const { storageState } = useStorage();
  const navigation = useNavigation(); // Ajout de cette ligne

  useEffect(() => {
    setTimeout(() => {
      setshowSplashScreen(false);
    }, 1000);
  }, []);

  useEffect(() => {
    // Vérifie si storageState.formateurUser n'est pas vide et navigue vers sssss
    if (!showSplashScreen && storageState.formateurUser) {
      navigation.navigate('Home');
    }
  }, [showSplashScreen, storageState.formateurUser, navigation]);

  if (showSplashScreen) {
    return (
      <Stack.Navigator>
        <Stack.Screen name="Splash" component={Splash} options={{ headerShown: false }} />
      </Stack.Navigator>
    );
  }

  return (
    <Stack.Navigator>
    {storageState.formateurUser ? (
      <>
        <Stack.Screen name="Home" component={HomeScreen}  options={{ headerShown: false }} />
        <Stack.Screen name="MyTabs" component={MyTabs} options={{ title: "Tabs" }} />
        <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
        <Stack.Screen name="Practical Assessment" component={Course}  options={{ headerShown: false }}/>
        <Stack.Screen name="Module" component={MultiSteps} options={{ headerShown: false }} />
        <Stack.Screen name="SyncData" component={SyncDataScreen} options={{ title: "" }} />
        <Stack.Screen name="RecordedData" component={RecodedData} options={{ headerShown: false }}/>
        <Stack.Screen name="DataTransfere" component={DataTransfere} options={{ title:  t('menu') }} />
        <Stack.Screen name="DetailDataTransfere" component={DetailDataTransfere} options={{ title: t('retour') }} />
        <Stack.Screen name="Category" component={Category} options={{ headerShown: false }} />
        <Stack.Screen name="SecondLogin" component={LoginLearner} options={{ title:  t('menu') }} />
        <Stack.Screen name="Profil" component={Profile} options={{ headerShown: false }} />
        <Stack.Screen name="MedicalConditions" component={ConditionsMedicales} options={{ headerShown: false }} />
        <Stack.Screen name="PdfFile" component={FileViewer} options={{ headerShown: false }} />
        {/* Add other screens with their respective titles */}
      </>
    ) : (
      <>
          {/* <Stack.Screen name="PdfFile" component={FileViewer} options={{ headerShown: false }} /> */}
        <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
        <Stack.Screen name="DataTransfere" component={DataTransfere} options={{ title: t('menu')}} />
        <Stack.Screen name="DetailDataTransfere" component={DetailDataTransfere} options={{ title: t('retour')}} />
        <Stack.Screen name="SecondLogin" component={LoginLearner} options={{ title:  t('menu') }} />
        <Stack.Screen name="StageSelection" component={StageSelectionScreen} options={{ title: t('retour') }} />
        <Stack.Screen name="Profil" component={Profile} options={{ headerShown: false }} />
        {/* Add other screens with their respective titles */}
      </>
    )
    }
  </Stack.Navigator>
  );
}
