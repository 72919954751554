
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

const StorageContext = createContext();
const storageReducer = (state, action) => {
    switch (action.type) {
      case 'SET_ITEM':
        AsyncStorage.setItem(action.key, action.value);
        return { ...state, [action.key]: action.value };
      // Add other cases as needed
      case 'SET_CONNEXION':
        //console.log('start storge');
        const { username, password, formateurType,competances,dataExist,lastQuizs, formateurUser,apprenants,quizList,courName, lang, type, quizData,listeQuizData,filterQuizs,EPD3,completedCategory } = action.data;
        AsyncStorage.setItem('username', username);
        AsyncStorage.setItem('password', password);
        AsyncStorage.setItem('formateurType', formateurType);
        AsyncStorage.setItem('formateurUser', formateurUser);
        AsyncStorage.setItem('lang', lang);
        AsyncStorage.setItem('type', type);
        AsyncStorage.setItem('dataExist', dataExist);
        AsyncStorage.setItem('quizData', quizData);
        AsyncStorage.setItem('quizList', quizList);
        AsyncStorage.setItem('listquiz', listeQuizData);
        AsyncStorage.setItem('filterQuizs', filterQuizs);
        AsyncStorage.setItem('apprenants', apprenants);
        AsyncStorage.setItem('courName', courName);
        AsyncStorage.setItem('competances', competances);
        AsyncStorage.setItem('lastQuizs', lastQuizs);
        AsyncStorage.setItem('EPD3', EPD3);
        AsyncStorage.setItem('completedCategory', completedCategory);
        return {
          ...state,
          username,dataExist,
          password,
          formateurType,
          formateurUser,
          lang,
          type,
          quizData,lastQuizs,
          listquiz,filterQuizs,competances,
          apprenants,courName
        };
        case 'REMOVE_ITEM':
          AsyncStorage.removeItem(action.key);
          const { [action.key]: _, ...newState } = state; // Use object destructuring to remove the item from the state
          return newState;
      default:
        return state;
        //console.log('state',state);
      
    }
  };

export const StorageProvider = ({ children }) => {
  const [storageState, dispatch] = useReducer(storageReducer, {});

  useEffect(() => {
    // Load initial storage values here if needed
    const loadStorage = async () => {
      try {
        const keys = await AsyncStorage.getAllKeys();
        const values = await AsyncStorage.multiGet(keys);
        const storageData = keys.reduce((acc, key, index) => {
          acc[key] = values[index][1];
          return acc;
        }, {});
        dispatch({ type: 'LOAD_STORAGE', data: storageData });
      } catch (error) {
        console.error('Error loading storage:', error);
      }
    };

    loadStorage();
  }, []);

  const setItem = async (key, value) => {
    dispatch({ type: 'SET_ITEM', key, value });
  };
  const removeItem = async (key) => {
    dispatch({ type: 'REMOVE_ITEM', key });
  };

  const contextValue = {
    storageState,
    setItem,
    removeItem
    // Add other functions as needed
  };

  return (
    <StorageContext.Provider value={contextValue}>
      {children}
    </StorageContext.Provider>
  );
};

export const useStorage = () => {
  const context = useContext(StorageContext);
  if (!context) {
    throw new Error('useStorage must be used within a StorageProvider');
  }
  return context;
};
