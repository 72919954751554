import React, { useState, useEffect } from 'react';
import { View, Text, SafeAreaView, Dimensions, StyleSheet, ImageBackground, TouchableOpacity,
   Image, ScrollView, StatusBar, Picker, ActivityIndicator } from 'react-native';
import { AppBar, Button } from '@react-native-material/core';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../theme/color';
import style from '../theme/style';
import styles from '../theme/styles';
import {Config} from "../config";
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useNavigation } from '@react-navigation/native';
import { useStorage } from '../contextApp';
import { Avatar } from "react-native-paper";
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './composants/ChangeLang';
/**
 * Dimensions of the screen
 */
const height = Dimensions.get('screen').height;
const userApi = Config.userApi;
const passApi = Config.passApi;
const backUrl= Config.backUrl;
/**
 * HomeScreen component that displays user information and allows selecting quiz categories, courses, and quiz types.
 */
const HomeScreen = () => {
  const { t } = useTranslation();
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state
  // Context hooks
  const { storageState, setItem } = useStorage();
  const navigation = useNavigation();

  // User information
  const usernameEva = storageState.formateurUser;
  const type = storageState.formateurType;
  const userAp = storageState.username;
  const quizData = storageState.quizData;
  const lang = storageState.lang;
  const avatarLetters = usernameEva.slice(0, 2).toUpperCase();
  const avatarLettersUser = userAp.slice(0, 2).toUpperCase();
  // State for selected options
  const [selectedTypeCours, setSelectedTypeCours] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedPractical, setSelectedPractical] = useState('');
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);
  const [lastQuizzes, setLastQuizzes] = useState([]);
  // State for fetched courses and quizzes
  const [courses, setCourses] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const [alert, setAlert] = useState(null);
  // Options for quiz categories and practicals
  const quizCategories = [
    { value: 'Sterile', label:  t('Sterile' )},
    { value: 'Nonsterile', label: t('NonSterile') },
  ];
  const [praticals,setPraticals] = useState([]);

  // Fonction pour filtrer les cours par catégorie
  const filterCoursByCategorie = (coursArray, categorie) => {
    console.log("coursArray",coursArray);
    console.log("categorie",categorie);
    return coursArray.filter(cours => cours.categorieCours === categorie);
  };
   // Fonction pour filtrer les QUIZ par cours séléctionner
   const filterQUIZByCours = (coursArray, Coursselected) => {
    console.log("coursArray",coursArray);
    console.log("Coursselected",Coursselected);
    setItem("cidInscription",Coursselected)
    return coursArray.filter(cours => cours.cidInscription === Coursselected);
  };
  const handleLogoutFormateur = ()=>  {
    setAlert({ type: 'success', message: t('logoutSuccess') });

   var langue=lang
    localStorage.clear();
    setItem('language',langue)
    setItem('lang',langue)
    setTimeout(() => {
      navigation.navigate('Login');
      setAlert(null)

    }, 1200); 
   
  }


  const VerficationUsers = async (dataUser) => {
 
    console.log('startt::::0');
    const postData = {
      apprenant: userAp,
      formateur: usernameEva
    };

    // Construct the base URL
    let apiUrl = backUrl + `/app/deconnexion`;

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Specify content type as JSON
      },
      body: JSON.stringify(postData), // Convert postData to JSON string
    });

    const data = await response.json();
    if (response.ok) { 

    }
  }



  const handleLogoutLearner = ()=>  {
    setAlert({ type: 'success', message: t('logoutSuccess') });

   localStorage.removeItem('quizData');
   localStorage.removeItem('username');
   localStorage.removeItem('type');
  
    setTimeout(() => {
      navigation.navigate("Profil",{
        usernameEva: usernameEva,
        type: type,
        lang: lang,
      });
      setAlert(null)

    }, 1200); 
   
  }

  const getOptionsBasedOnCategory = () => {     
                                                                                                                                                                                          
    var options= [];
    setPraticals([])
   
    // Afficher seulement l'option 'Évaluation' si la catégorie est 'Nonsterile'
 
   // Afficher  les options selon le cours  si la catégorie est autre que 'Nonsterile'
      const cours = quizData.filter(cours => cours.cidInscription === selectedCourse)
     if(type==="formateur") {
      options.push({ value: 'Formation',label: 'PracticalTraining'  })
       
     }
     else 
      { 
       
          options.push({ value: 'Formation',label:'PracticalTraining' })
    
          options.push({ value: 'Évaluation',label:'PracticalAssessment'})
      
      }
      setPraticals(options)
   
    
  
  };
//console.log(quizData)
  /**
   * Effect hook to fetch courses when selectedTypeCours changes
   */
  useEffect(() => {
    if (selectedTypeCours === '') {
      setPraticals([])
      setCourses([])
      setSelectedCourse('');
      setSelectedPractical('')
    }
   else  if (selectedTypeCours === 'Sterile' || selectedTypeCours === 'Nonsterile') {
     // console.log('quizdata', quizData);
     // console.log('selectedTypeCours', selectedTypeCours);
      if (selectedTypeCours && quizData && quizData.length > 0) {
        const coursFiltres = selectedTypeCours
    ? filterCoursByCategorie(quizData, selectedTypeCours)
    : [];
        //console.log(coursFiltres);
        setCourses(coursFiltres);

      }
      getOptionsBasedOnCategory()
    }
    
  }, [selectedTypeCours]);
 //console.log(quizData)
  /**
   * Effect hook to update quiz when selectedCourse changes
   */
  useEffect(() => {
    if (selectedCourse  && quizData.length > 0) {
    //  console.log('selectedCourse',selectedCourse)
      const selectedQUIZData =   selectedCourse
      ? filterQUIZByCours(quizData, selectedCourse)
      : [];
   //   console.log('selectedQUIZData',selectedQUIZData[0])
      setQuiz(selectedQUIZData[0]?.listeQuiz || []);
      setItem('listquiz', selectedQUIZData[0]?.listeQuiz || []);
  
      // Filtrer les quizs en fonction de la sélection de quiz et du type pratique
      filterQuizzes(selectedQUIZData[0]?.listeQuiz || [], selectedPractical);
      getOptionsBasedOnCategory()
    }
 
  }, [selectedCourse, selectedPractical, quizData]);

  /**
   * Fetches courses based on selected quiz category and updates the courses state
   */


const filterQuizzes = async (selectedQuizzes, selectedPractical) => {
  console.log(selectedQuizzes)
  try {
    // Requête HTTP pour récupérer tous les quizs

    const response = await fetch(backUrl+'/app/quizs');
    const quizs = await response.json();

      var filteredQuizs = [];

        // Filtrer les quizs en fonction de la sélection de quiz et du type pratique
        
      quizs.forEach(element => {
      // console.log(selectedQuizzes.filter((el)=>el.identifiant === element.idModule ))
        var selectedId =selectedQuizzes.filter((el)=>el.identifiant === element.idModule )
        if(selectedId.length>0)
        { if((element.idModule ===selectedId[0].identifiant) &&  (selectedPractical === '' || element.typeName === selectedPractical) && (selectedPractical === '' || element.categoryName === selectedTypeCours))
          { 
            filteredQuizs.push({
              ...element,
              cidInscription:selectedCourse
            })
          }
        
        
        }
      });
      console.log(selectedPractical)
      selectedQuizzes.forEach((el)=>{
        if(el.identifiant==="FPCM" && selectedPractical==="Formation" ||el.identifiant==="EPCM" && selectedPractical==="Évaluation" ||el.identifiant==="NS0")
        { if(filteredQuizs.findIndex((el)=>el.idModule===el.identifiant)===-1)
        {
          filteredQuizs.push({idModule:el.identifiant,nomEn:"Medical conditions " + el.identifiant,nomFr:" Conditions médicales " + el.identifiant, typeName:selectedPractical})
        }
        
        }
      })
  
    // Mettre à jour l'état des quizs filtrés
    setFilteredQuizzes(filteredQuizs);
    setLastQuizzes(filteredQuizs);
    setItem('filterQuizs', filteredQuizs); setItem('lastQuizs', lastQuizzes);
    console.log('lastquiz*************',lastQuizzes);
    setItem('courName',  courses.find(course => course.cidInscription === selectedCourse)?.nomCours || '');
    setItem('category',selectedPractical)
   // console.log('filtrage des quizs :', filteredQuizs);
  } catch (error) {
    console.error('Erreur lors du filtrage des quizs :', error);
  }
};

let nomUsager = userAp ;
//let datatosync = storageState.apprenants;

useEffect(() => {
  const fetchData = async () => {
    try {
      // Construct the base URL
      let apiUrl = backUrl +`/app/syncron/last?nomUsager=${nomUsager}`;
       // apiUrl += `?nomUsager=${nomUsager}`;
      // Fetch data using the constructed URL
      const response = await fetch(apiUrl);
      const newData = await response.json();

  
      
      setItem('apprenants', newData);
      console.log('get sync ap', newData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();  // Call the fetchData function when the component mounts
}, [nomUsager]);
const dataSend = storageState.apprenants;
console.log('deja sync home ---------->', dataSend);
const goToCourse= ()=> {
  setLoading(true);
  console.log('here')
 
    if(selectedTypeCours === 'Sterile' )
    {
      if(selectedPractical!=''&& selectedCourse!='')
      {
        setTimeout(() => {
          setLoading(false); // Hide the loader after 5 seconds
          navigation.navigate('Practical Assessment',{
            datatosync: dataSend
          });
        }, 2000);
     
      }
      else if(selectedCourse=="") {
        setAlert({ type: 'error', message: t('selectCourseError') });
        return
      } else if(selectedPractical=="") {
        setAlert({ type: 'error', message: t('selectTypeError') });
  
        return
      } 
    } else  if(selectedTypeCours === 'Nonsterile' )
    {
      if(selectedCourse=="") {
        setAlert({ type: 'error', message: t('selectCourseError') });
  
        return
      } else {
        setTimeout(() => {
          setLoading(false); // Hide the loader after 5 seconds
          navigation.navigate('Practical Assessment',{
            datatosync: dataSend
          });
        }, 2000);
      
      }
    }
    else {
      setAlert({ type: 'error', message: t('selectCateogryError') });
    }
  
  
 
}

console.log(quiz)

  return (
    <SafeAreaView style={styles.container}>
    
         <ImageBackground
        source={require('../../assets/image/BG_0.png')}
        resizeMode="stretch"
        style={styles.backgroundImage}
      >
        <StatusBar backgroundColor="transparent" translucent={true} />
        <View style={styles.headerContainer}>
        <Avatar.Text  size={40} label={avatarLetters}
          style={{  backgroundColor: '#fff',  marginTop: 2,
            alignSelf: "center",  color:'#043ca5'  }}
        />
          <View style={styles.greetingContainer}>
            <Text style={styles.greetingText}>
            {t('hi')}, {usernameEva}
            </Text>
            <Text style={styles.welcomeText}>{t(type)}</Text>
          </View>
         {/* <ChangeLanguage/> */}
        <Icons name="power" style={{ fontSize: 34,marginLeft:10, color: "white"  }} onPress={handleLogoutFormateur} />
        </View>
      </ImageBackground>

      <View style={styles.infoContainer}>
        <View style={styles.userInfoBox}>
          <View style={styles.userInfoRow}>
          <Avatar.Text size={50} label={avatarLettersUser} style={{  backgroundColor: '#0c3989',  marginTop: 5, alignSelf: "center" }} />
            <View style={styles.userTextContainer}>
              <Text style={styles.userText}>{userAp}</Text>
              <Text style={styles.userTypeText}>{t('apprenantConnecte')}</Text>
            </View>
            <View> <Icons name="power-settings" style={{ fontSize: 47,marginRight:20, paddingRight:20,color: "#0c3989" }} onPress={handleLogoutLearner} /></View>
          </View>
        
        </View>
  
      </View>
      {alert && (
          <View style={[styles.alertContainer, { backgroundColor: alert.type === 'error' ? '#FFBABA' : '#D5E8D4' }]}>
            <Text style={[styles.alertText, { color: alert.type === 'error' ? '#D8000C' : '#4F8A10' }]}>
              {alert.message}
            </Text>
          </View>
        )}
    <StatusBar translucent={true} backgroundColor="transparent"  />
    
    <View style={styles.mainContainerColumn}>
    <ImageBackground
        source={require('../../assets/image/BG-L.png')} style={{ flex: 1, height: '100%',resizeMode: 'cover' }}
      >
    <AppBar  style={styles.appBar} color={Colors.secondary}   elevation={0}  title={t('quizFilter')}
          titleStyle={[styles.appTitle,{marginTop:40,marginBottom:20}]}   />
      <ScrollView showsVerticalScrollIndicator={false} style={styles.scrollContainer}>
        <View style={styles.selectListContainer}>
          <View style={styles.selectList}>
            <Text style={styles.labelText}>{t('quizChoose')}</Text>
            <Picker style={styles.dropdown}  selectedValue={selectedTypeCours}
              onValueChange={(itemValue, itemIndex) => {setSelectedTypeCours(itemValue),getOptionsBasedOnCategory()}}
            >
              <Picker.Item label={t('selectOption')} value="" style={styles.dropdownItem} />
              {quizCategories.map((item, index) => (
                <Picker.Item key={index}  label={item.label} value={item.value}  style={styles.dropdownItem}  />
              ))}
            </Picker>
          </View>
          {loadingCourses && <ActivityIndicator size="large" color={Colors.primary} />}
            {courses.length > 0 && (
              <View style={styles.selectList}>
                <Text style={styles.labelText}>{t('courChoose')}</Text>
              
                <Picker
                  style={styles.dropdown}
                  selectedValue={selectedCourse}
                  onValueChange={(itemValue, itemIndex) =>{ setSelectedCourse(itemValue),setSelectedPractical('')}}
                >
                    <Picker.Item label={t('selectOption')} value="" style={styles.dropdownItem} />
                  {courses.map((item, index) => (
                    <Picker.Item key={index}  label={item.nomCours}  value={item.cidInscription}  style={styles.dropdownItem}   />
                  ))}
                </Picker>
              </View>
            )}
             {selectedTypeCours && courses.length < 1 && (<Text style={styles.labelText}>{t('courmessage')}</Text>)}
             {/* {courses.length > 0 && praticals.length>0 &&  ( */}
             {courses.length > 0 && praticals.length>0 && selectedTypeCours !== "Nonsterile" &&   (
          
            <View style={styles.selectList}>
               <Text style={styles.labelText}>{t('typeChoose')}</Text>
                <Picker  style={styles.dropdown}  selectedValue={selectedPractical}
                  onValueChange={(itemValue, itemIndex) => setSelectedPractical(itemValue)} >
                  <Picker.Item label={t('selectOption')} value="" style={styles.dropdownItem} />
                  {praticals.map((item, index) => (
                    <Picker.Item  key={index}  label={t(item.label)}  value={item.value}   style={styles.dropdownItem}    />
                  ))}
                </Picker>
            </View>
          )}
       
       {courses.length > 0 &&(
        <View style={styles.sendButtonContainer}>
          {selectedTypeCours!=""? selectedTypeCours !="nonSterile" &&quiz.length > 0 ? (
             
       <TouchableOpacity
       onPress={() =>{ goToCourse(),setTimeout(() => {
        setAlert(null)
      
      }, 1200); }}
       style={[styles.sendButton, { backgroundColor: Colors.primary }]}>
          {loading ? ( // Show loader if loading is true
              <ActivityIndicator size="small" color="#fff" />
            ) : (
              <Text style={styles.sendButtonText}>{t('btnSend')}</Text>
            )}
     </TouchableOpacity>
          ):( 
            <TouchableOpacity
            onPress={() =>{ goToCourse(),setTimeout(() => {
             setAlert(null)
           
           }, 1200); }}
            style={[styles.sendButton, { backgroundColor: Colors.primary }]}>
                 {loading ? ( // Show loader if loading is true
              <ActivityIndicator size="small" color="#fff" />
            ) : (
              <Text style={styles.sendButtonText}>{t('btnSend')}</Text>
            )}
            {/* <Text style={styles.sendButtonText}>{t('btnSend')}</Text> */}
          </TouchableOpacity>
               ): null}
        </View>  )}
        </View>
      </ScrollView>
  
</ImageBackground>
<View style={{ position: 'absolute', bottom: 0,width:"100%",justifyContent:'center',  paddingHorizontal: 20,  }}>

  <View style={{ width: "100%" }}>

<TouchableOpacity onPress={()=>navigation.navigate('RecordedData')}
  style={{ backgroundColor:"#193d7e" ,flexDirection:'row',alignItems:'center',marginBottom:20, padding:12, borderRadius:5}}>
      <Text style={[style.m16,{color:"#fff",marginLeft:10,flex:1}]}>{t('back')}</Text>
      <Image source={require('../../assets/image/aaw.png')} resizeMode='stretch' style={{height:24,width:24}}></Image>
  </TouchableOpacity>
  </View>
  </View>

    </View>
  </SafeAreaView>
  );
};



export default HomeScreen;
