import React, { useState, useEffect } from 'react';
import {  SafeAreaView, ScrollView, View, Text,ImageBackground , StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import {TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { Colors } from '../theme/color';
import {Config} from "../config";
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import style from '../theme/style';
import stylesform from '../theme/styles';
import { useStorage } from '../contextApp';
import { useNavigation,useRoute } from "@react-navigation/native";
import { useTranslation } from 'react-i18next';
//import moment from 'moment-timezone';
import moment from 'moment';
const FirstRoute = () => {
  const backUrl = Config.backUrl;
  const route = useRoute();
  const { storageState, setItem } = useStorage();
  var exitingData = route.params.data!=""? route.params.data:storageState.apprenants;
  const [syncronData, setSyncronData] = useState([]);
  const filterQuizs = storageState.quizList;
  const lang = storageState.lang;
  const userAp = storageState.username;
  const navigation = useNavigation();
  const currentDateISO = moment().tz("America/Montreal").format();
  const [quizNames, setQuizNames] = useState([]);


  let nomUsager = userAp;
  const { t } = useTranslation();
  useEffect(() => {
 
     exitingData = route.params.data!=""? route.params.data:storageState.apprenants;
    console.log("exitingData;;;;;;;;;;;;;;;;;;;;;;;;;",exitingData)

  }, [route.params.data]);
  useEffect(() => {
  
    const ListeQuizData =
      exitingData &&
      exitingData.flatMap((item) =>
        item.listeQuiz.map((quiz) => ({
          id: quiz.id,
          cidInscription: quiz.cidInscription,
        }))
      );

    const newQuizNames = ListeQuizData.map((idModule) => {
      const apprenantQuiz = exitingData.find((item) =>
        item.listeQuiz.some(
          (quiz) =>
            quiz.id === idModule.id && quiz.cidInscription === idModule.cidInscription
        )
      );

      const quiz = filterQuizs.find((q) => q.idModule === idModule.id);
      const selectedQuiz = apprenantQuiz
        ? apprenantQuiz.listeQuiz.find(
            (q) => q.id === idModule.id && q.cidInscription === idModule.cidInscription
          )
        : null;
      return quiz && selectedQuiz
        ? {
            idModule: quiz.idModule,
            nomFr: quiz.nomFr,
            nomEn: quiz.nomEn,
            cour: selectedQuiz.cour,
            formateur: selectedQuiz.formateur,
            formateurType: selectedQuiz.formateurType,
            id: selectedQuiz.competences[0].id,
            evaluationSatisfaisante: selectedQuiz.evaluationSatisfaisante,
            nomUsager: apprenantQuiz.nomUsager,
            date: selectedQuiz.competences[0].dateSaisie,
            dateSyncron: null,
            cidInscription:idModule.cidInscription
          }
        : null;
    });

    setQuizNames(newQuizNames);
  }, [filterQuizs, exitingData,route.params.data]);
 
    
  console.log("quizNames;;;;;;;;;;;;;;;;;;;;;;;;;",quizNames)
  return (
    <View style={styles.panelBlock}>
      <View style={styles.panel}>
        <Text style={styles.panelHeader}>{t('dataPending')}</Text>
      </View>
      <View style={styles.panelBody}>
        {quizNames.map((quiz, index) => (
          <React.Fragment key={index}>
              
            <TouchableOpacity onPress={() => navigation.navigate('DetailDataTransfere', quiz)}>
              <Text style={[styles.panelText, { fontWeight: 600 }]}>
                <Icons name="checkbox-marked-circle-outline" size={33} style={{ color: '#013E7F', marginTop: 7 }} />{' '}
                {lang === 'fr' ? quiz.nomFr : quiz.nomEn} ({quiz.idModule})<Text style={{ fontWeight: 400 }}> {quiz.cour}</Text>
              </Text>
             
            </TouchableOpacity>
          
           
            <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View>
          </React.Fragment>
        ))}
        {/* Vérifier si des données médicales existent */}
        {exitingData.map((apprenant, index) => (
  apprenant.medicals && apprenant.medicals.length > 0 && (
    apprenant.medicals.map((medical, medIndex) => (
      <React.Fragment key={medIndex}>
        <TouchableOpacity onPress={() => navigation.navigate('DetailDataTransfere', {
          idModule: medical.id,
          nomFr: 'Conditions Médicales',
          nomEn: 'Medical Conditions',
          cour: medical.cour,
          formateur: medical.formateur,
          formateurType: medical.formateurType,
          id: medical.id,
          evaluationSatisfaisante: null,
          nomUsager: apprenant.nomUsager,
          date: medical.dateComplete ? medical.dateComplete : currentDateISO,
          dateSyncron: null,
        })}>
          <Text style={[styles.panelText, { fontWeight: 600 }]}>
            <Icons name="checkbox-marked-circle-outline" size={33} style={{ color: '#013E7F', marginTop: 7 }} />{' '}
            {lang === 'fr' ? 'Conditions Médicales' : 'Medical Conditions'} ({medical.id})<Text style={{ fontWeight: 400 }}> {medical.cour}</Text>
          </Text>
        </TouchableOpacity>
        <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View>
      </React.Fragment>
    ))
  )
))}
      </View>
    </View>
  );
};

const SecondRoute = () => {
  const backUrl =Config.backUrl;
  const { storageState , setItem} = useStorage();
  const [syncronData, setSyncronData] = useState([]);
  const lang = storageState.lang;
  const userAp = storageState.username;
  const navigation = useNavigation();
  const filterQuizs = storageState.quizList;
  const { t } = useTranslation();
  const [alert, setAlert] = useState(null);
  let nomUsager = userAp ;
  useEffect(() => {
    const fetchSyncData = async () => {
      try {
        let apiUrl;  // Declare apiUrl variable here

        // Construct the base URL
        if (nomUsager) {
          apiUrl = backUrl + `/app/syncron/lastweek?nomUsager=${nomUsager}`;
        } else {
          apiUrl = backUrl + '/app/syncron/lastweek';
        }

        const response = await fetch(apiUrl);
        const SyncData = await response.json();

        // Update the existingData state with the fetched data
        setSyncronData(SyncData);
        setItem('apprenantSync', SyncData);
        console.log('get sync appp', SyncData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSyncData();  // Call the fetchSyncData function when the component mounts
  }, [nomUsager]);
  const [quizSync, setQuizSync] = useState([]); 
  useEffect(() => {
    //console.log('is sync',syncronData);
    //console.log("filterQuizs   ",filterQuizs)
    const ListeQuizData = syncronData && syncronData.flatMap((item) =>
    item.listeQuiz.map((quiz) => ({
      id: quiz.id,
      cidInscription: quiz.cidInscription
    }))
  ) || [];
    const newQuizSync = ListeQuizData.map(idModule => {
      const apprenantQuiz = syncronData.find(item => item.listeQuiz.some(quiz => quiz.id === idModule.id && quiz.cidInscription === idModule.cidInscription));
    //  //console.log("apprenantQuizSync",apprenantQuiz)
      const quiz = filterQuizs.find(q => q.idModule === idModule.id);
     console.log(apprenantQuiz.dateSyncron)
     const selectedQuiz = apprenantQuiz
     ? apprenantQuiz.listeQuiz.find(
         (q) => q.id === idModule.id && q.cidInscription === idModule.cidInscription
       )
     : null;
   
   const formattedDate = selectedQuiz 
     ? moment(selectedQuiz.competences[0].dateSaisie).format('DD-MM-YY')
     : '';
 
   return quiz && selectedQuiz
     ? {
        idModule: quiz.idModule,
        nomFr: quiz.nomFr,
        nomEn: quiz.nomEn,
        cour:selectedQuiz.cour,
        formateur: selectedQuiz.formateur,
        formateurType: selectedQuiz.formateurType,
        id: selectedQuiz.competences[0].id,
        evaluationSatisfaisante: selectedQuiz.evaluationSatisfaisante,
        nomUsager: apprenantQuiz.nomUsager,
        date: selectedQuiz.competences[0].dateSaisie,
        dateSyncron:apprenantQuiz.dateSyncron ,
         cidInscription:idModule.cidInscription
      } : null;
    });

    setQuizSync(newQuizSync);
  }, [filterQuizs, syncronData]);
 //console.log("quizzzzzzzzzzzzzzzzzzzz deja sync",quizSync)
 //console.log("is sync medical", syncronData);
  const handleUpdateStatus = async () => {
    try {
      let apiUrl; // Declare apiUrl variable here
      // Construct the base URL
      apiUrl = backUrl + `/app/updateStatus`;
      const response = await fetch(apiUrl, {
        method: 'PUT',
      });
      if (!response.ok) {
        setAlert({ type: 'error', message: t('deleteerror') });
        throw new Error('Failed to update status'); // Throw an error if the response is not ok
      }
      // Handle successful update
      //console.log('Status updated successfully');
      setAlert({ type: 'success', message: t('deleteSuccess') });
      // Clear synchronization-related data
      setSyncronData([]);
      setItem('apprenantSync', '');
      setTimeout(() => {
        setAlert(null); // Clear the alert after 7 seconds
      }, 4000);
    } catch (error) {
      console.error('Error updating status:', error);
      // Provide user feedback about the error if needed
      // Show a toast message or update UI accordingly
    }
  };
  return (
    <View style={styles.panelBlock}>
    <View style={styles.panel}><Text style={styles.panelHeader}>{t('dataTransferred')}</Text></View>
    <View style={styles.panelBody}>
   {/* <Text style={styles.panelText}>NH - Exiting controlled work areas (FND8) </Text>
   <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View> */}
   <View style={styles.panelBody}>
        {quizSync.map((quiz, index) => (
          <React.Fragment key={index}>
           <TouchableOpacity onPress={() => navigation.navigate("DetailDataTransfere", quiz)}>
              <Text style={[styles.panelText,{fontWeight:600}]}><Icons name='checkbox-marked-circle' size={33} style={{color:'#013E7F',marginTop:7}}/> {lang === 'fr' ? quiz.nomFr : quiz.nomEn} ({quiz.idModule})<Text style={{fontWeight:400}}>   {quiz.cour}</Text> 
               {/* <Text style={{fontWeight:400 ,color:'grey' ,marginLeft:10}}><Icons name='account-circle' size={28} />{quiz.nomUsager}</Text> */}
               </Text>
            </TouchableOpacity>
            <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View>
          </React.Fragment>
        ))}
          {syncronData.map((apprenant, index) => (
         apprenant.medicals && apprenant.medicals.length > 0 && (
         apprenant.medicals.map((medical, medIndex) => (
      <React.Fragment key={medIndex}>
        <TouchableOpacity onPress={() => navigation.navigate('DetailDataTransfere', {
          idModule: medical.id,
          nomFr: 'Conditions Médicales',
          nomEn: 'Medical Conditions',
          cour: medical.cour,
          formateur: medical.formateur,
          formateurType: medical.formateurType,
          id: medical.id,
          evaluationSatisfaisante: null,
          nomUsager: apprenant.nomUsager,
          date: medical.dateComplete ? medical.dateComplete : currentDateISO,
          dateSyncron: apprenant.dateSyncron,
        })}>
          <Text style={[styles.panelText, { fontWeight: 600 }]}>
            <Icons name="checkbox-marked-circle" size={33} style={{ color: '#013E7F', marginTop: 7 }} />{' '}
            {lang === 'fr' ? 'Conditions Médicales' : 'Medical Conditions'} ({medical.id})<Text style={{ fontWeight: 400 }}> {medical.cour}</Text>
          </Text>
        </TouchableOpacity>
        <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View>
      </React.Fragment>
    ))
  )
))}
      </View>
      {alert && (
          <View style={[stylesform.alertContainer, { backgroundColor: alert.type === 'error' ? '#FFBABA' : '#D5E8D4' }]}>
            <Text style={[stylesform.alertText, { color: alert.type === 'error' ? '#D8000C' : '#4F8A10' }]}>
              {alert.message}
            </Text>
          </View>
        )}
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
    
      <TouchableOpacity 
        onPress={quizSync.length > 0 || syncronData.some(item => item.medicals && item.medicals.length > 0) ? handleUpdateStatus : () => {}} // Enable if there are quizSync items or medical records
        disabled={!(quizSync.length > 0 || syncronData.some(item => item.medicals && item.medicals.length > 0))} // Disable if both quizSync and medical records are empty
        style={[style.btn, { marginVertical: 20, backgroundColor: (quizSync.length > 0 || syncronData.some(item => item.medicals && item.medicals.length > 0)) ? '#eb1616' : '#f18686', width: 150 }]}>
        <Text style={[style.btntxt, { fontSize: 21 }]}>{t('deleteall')}</Text>
      </TouchableOpacity>
    </View>




    </View>
  </View>
  );
};

const renderTabBar = (props) => (
  <TabBar
    {...props}
    style={styles.tabBar}
    labelStyle={({ route, focused }) => (focused ? styles.activeTabLabel : styles.tabLabel)}
  />
);

const renderScene = SceneMap({
  first: FirstRoute,
  second: SecondRoute,
});

export default function DataTransfere({ navigation }) {
  const { t } = useTranslation();
  const { storageState } = useStorage();
  const exitingData = storageState.apprenants;

  const layout = useWindowDimensions();
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'first', title: t('dataToBeTransferred') },
    { key: 'second', title: t('dataAlreadyTransferred') },
  ]);

  return (
    <View style={styles.container}>
      
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
        renderTabBar={renderTabBar}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  panelBlock: {
    margin: '3%',
    backgroundColor: '#fff',
    marginTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  panel: {
    backgroundColor: '#013E7F',
    fontSize: 20,
    alignItems: 'center',
    textTransform: 'uppercase',
    borderRadius: 4,
    fontWeight: 'bold',
    marginBottom: 10,
    padding: 10,
    color: 'white',
  },
  panelBody: {
    padding: '2%',
  },
  panelText: {
    fontSize: 18,
    color: '#333',
    marginBottom: 15,
    borderBottomColor: '#000',
  },
  panelHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: '1%',
    color: 'white',
  },
  tabBar: {
    padding: '2%',
    backgroundColor: '#013E7F', // Change this to the desired background color
  },
  tabLabel: {
    color: 'white', // Change this to the desired text color
    fontSize: 18, // Change this to the desired font size
    fontWeight: 'bold',
  },
  activeTabLabel: {

    // Add any styles for the active tab label
  },
});
