import { StyleSheet, Dimensions } from "react-native";
import { Colors } from "./color";

const width = Dimensions.get('screen').width
const height = Dimensions.get('screen').height

export default StyleSheet.create({
    area: {
        flex: 1,
        backgroundColor: "#f9f9ff"
    },

    main: {
        flex: 1,
        paddingHorizontal: 20,
        backgroundColor: Colors.secondary,
  
    },
    title: {
        fontSize: 34,
        color: Colors.active,
        fontFamily: 'Gilroy-Bold'
    },
    apptitle: {
        fontSize: 24,
        color: Colors.active,
        fontFamily: 'Gilroy-Bold'
    },
    subtitle: {
        fontSize: 20,
        fontFamily: 'Gilroy-Bold',
        color: Colors.active,
    },
    r12: {
        fontSize: 12,
        fontFamily: 'Gilroy-Regular',
        color: Colors.active,
    },
    m12: {
        fontSize: 12,
        fontFamily: 'Gilroy-Medium',
        color: Colors.active,
    },
    b12: {
        fontSize: 12,
        fontFamily: 'Gilroy-Bold',
        color: Colors.active,
    },
    s12: {
        fontSize: 12,
        fontFamily: 'Gilroy-SemiBold',
        color: Colors.active,
    },
    r13: {
        fontSize: 13,
        fontFamily: 'Gilroy-Regular',
        color: Colors.active,
    },
    m13: {
        fontSize: 13,
        fontFamily: 'Gilroy-Medium',
        color: Colors.active,
    },
    b13: {
        fontSize: 13,
        fontFamily: 'Gilroy-Bold',
        color: Colors.active,
    },
    s13: {
        fontSize: 13,
        fontFamily: 'Gilroy-SemiBold',
        color: Colors.active,
    },
    r14: {
        fontSize: 14,
        fontFamily: 'Gilroy-Regular',
        color: Colors.active,
    },
    m14: {
        fontSize: 14,
        fontFamily: 'Gilroy-Medium',
        color: Colors.active,
    },
    b14: {
        fontSize: 14,
        fontFamily: 'Gilroy-Bold',
        color: Colors.active,
    },
    s14: {
        fontSize: 14,
        fontFamily: 'Gilroy-SemiBold',
        color: Colors.active,
    },
    r15: {
        fontSize: 15,
        fontFamily: 'Gilroy-Regular',
        color: Colors.active,
    },
    m15: {
        fontSize: 15,
        fontFamily: 'Gilroy-Medium',
        color: Colors.active,
    },
    b15: {
        fontSize: 15,
        fontFamily: 'Gilroy-Bold',
        color: Colors.active,
    },
    s15: {
        fontSize: 15,
        fontFamily: 'Gilroy-SemiBold',
        color: Colors.active,
    },
    r16: {
        fontSize: 16,
        fontFamily: 'Gilroy-Regular',
        color: Colors.active,
    },
    m16: {
        fontSize: 16,
        fontFamily: 'Gilroy-Medium',
        color: Colors.active,
    },
    b16: {
        fontSize: 16,
        fontFamily: 'Gilroy-Bold',
        color: Colors.active,
    },
    s16: {
        fontSize: 16,
        fontFamily: 'Gilroy-SemiBold',
        color: Colors.active,
    },
    r17: {
        fontSize: 17,
        fontFamily: 'Gilroy-Regular',
        color: Colors.active,
    },
    m17: {
        fontSize: 17,
        fontFamily: 'Gilroy-Medium',
        color: Colors.active,
    },
    b17: {
        fontSize: 17,
        fontFamily: 'Gilroy-Bold',
        color: Colors.active,
    },
    s17: {
        fontSize: 17,
        fontFamily: 'Gilroy-SemiBold',
        color: Colors.active,
    },
    r18: {
        fontSize: 18,
        fontFamily: 'Gilroy-Regular',
        color: Colors.active,
    },
    m18: {
        fontSize: 18,
        fontFamily: 'Gilroy-Medium',
        color: Colors.active,
    },
    b18: {
        fontSize: 18,
        fontFamily: 'Gilroy-Bold',
        color: Colors.active,
    },
    s18: {
        fontSize: 18,
        fontFamily: 'Gilroy-SemiBold',
        color: Colors.active,
    },


    btn: {
        backgroundColor: Colors.primary,
        alignItems: 'center',
        height: 54,
        borderRadius: 35,
        justifyContent: 'center'
    },
    btntxt: {
        fontSize: 17,
        color: Colors.secondary,
        fontFamily: 'Gilroy-SemiBold'
    },
    btn2: {
        backgroundColor: Colors.secondary,
        alignItems: 'center',
        height: 54,
        borderRadius: 10,
        justifyContent: 'center'
    },
    btntxt2: {
        fontSize: 17,
        color: Colors.primary,
        fontFamily: 'Gilroy-SemiBold'
    },
    btnoutline: {
        alignItems: 'center',
        height: 54,
        borderRadius: 10,
        justifyContent: 'center',
        borderColor:Colors.primary,
        borderWidth:1
    },
    txtinput: {
        paddingHorizontal: 0,
        height: 40,
        paddingBottom: 3,
        borderBottomWidth: 1,
        borderBottomColor: Colors.border
    },
    inputContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: 10,
        height: 40,
        borderBottomWidth: 1,
        borderBottomColor: Colors.border
    },

    indicator: {
        borderColor: '#BDBDBD',
        borderWidth: 1,
        padding: 4,
        borderRadius: 20,
        backgroundColor: '#BDBDBD',
        marginHorizontal: 5
    },


    shadow: {
        shadowColor: Colors.active,
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 0.2,
        shadowRadius: 8,
        elevation: 5,
        backgroundColor: Colors.bg,

    },

    divider: {
        height: 1,
        backgroundColor: Colors.border,
    },

    divider1: {
        height: 1.5,
        backgroundColor: Colors.border,
        marginTop: 20,
        marginBottom: 20,
        flex: 1
    },

    dividertxt: {
        color: Colors.disable,
        fontFamily: 'Gilroy-Regular'
    },

    verticaldivider: {
        height: '60%',
        width: 1,
    },

    modalcontainer: {
        flex: 1,
        borderRadius: 20,
        paddingHorizontal: 10,
        marginVertical: 140,
        paddingTop: 20,
        marginHorizontal: -10,
        alignSelf: 'center',
    },

    follow: {
        height: 38, flex:1, borderRadius: 10, backgroundColor: Colors.primary,justifyContent:'center',alignItems:'center'
    },
    following: {
        height: 38, flex:1, borderRadius: 10, backgroundColor: Colors.txt,justifyContent:'center',alignItems:'center'
    },
    categoryTextSelected: {
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 20,
        borderWidth: 2,
        backgroundColor: Colors.primary,
        borderColor: Colors.primary,
        color: Colors.secondary,
        fontFamily: 'Gilroy-Regular'
    },
    categoryText: {
        fontSize: 14,
        color: Colors.primary,
        borderWidth: 2,
        borderColor: Colors.primary,
        borderRadius: 20,
        paddingBottom: 5,
        paddingTop: 7,
        paddingHorizontal: 10,
        marginHorizontal: 5,
        fontFamily: 'Gilroy-Regular'
    },
    categorycontainer: {
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 30,
        justifyContent: 'space-between',
    },

}
);