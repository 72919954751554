import React, { useState, useEffect } from 'react';
import { Platform, View, Text, SafeAreaView, Dimensions, StyleSheet, ImageBackground, TouchableOpacity, Image, ScrollView, StatusBar, TouchableHighlight } from 'react-native';
import { Hoverable } from 'react-native-web-hover';
import { Colors } from '../theme/color';
import style from '../theme/style';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useStorage } from '../contextApp';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons'
import { green } from './Constants';
import { Config } from "../config";
import { useTranslation } from 'react-i18next';
import BottomBar from './composants/BottomBar';
import NetInfo from '@react-native-community/netinfo';
const width = Dimensions.get('screen').width;
const height = Dimensions.get('screen').height;

export default function Course() {
  const route = useRoute(); // Get the route object

  // Access the props passed to the screen
  const { datatosync } = route.params;
  const { storageState, setItem } = useStorage();
  const backUrl = Config.backUrl;
  const userAp = storageState.username;
  const cIdInscription = storageState.cidInscription;
  let nomUsager = userAp;
  //let datatosync = storageState.apprenants;

  const filterQuizs = storageState.filterQuizs;
  const lang = storageState.lang;
  const navigation = useNavigation();
  const [isHovered, setIsHovered] = useState(null);
  const [quizDone, setQSQuizDone] = useState([]);
  const [quizDoneRetake, setQSQuizDoneRetake] = useState([]);
  const [existingData, setExistingData] = useState([]);
  const category = storageState.category;
  const { t } = useTranslation();
  const [showBottomBar, setShowBottomBar] = useState(false);

  const typeFormation = category === "Formation" ? t('PracticalTraining') : t('PracticalAssessment')
  const [isConnected, setIsConnected] = useState(true);

useEffect(() => {
  const checkConnection = async () => {
    const initialConnectionInfo = await NetInfo.fetch();
    setIsConnected(initialConnectionInfo.isConnected);

    const unsubscribe = NetInfo.addEventListener(state => {
      setIsConnected(state.isConnected);
      setShowBottomBar(true);
      setTimeout(() => {
        setShowBottomBar(false);
      }, 3000);
    });

    return () => {
      unsubscribe();
    };
  };

  checkConnection();
}, []);


  let itemsArray = [];
  //console.log('propss  dataToSync course------------------>', datatosync);
  //console.log('Asyncapprenant::::::::::::::::::::', datatosync);
  //console.log(filterQuizs)
  let EPD3 = [];
  useEffect(() => {
    let idsListeQuiz = [];
    //console.log('data storge app :', datatosync);
    if (datatosync && Array.isArray(datatosync)) {
      //idsListeQuiz = datatosync.map((item) => item.listeQuiz.map((quiz) => quiz.id));
      idsListeQuiz = datatosync.flatMap((item) => item.listeQuiz.map((quiz) => quiz.id));
      //console.log('list done', idsListeQuiz);
      itemsArray = datatosync.filter(item => item.listeQuiz.filter((quiz) => quiz.id != 'EPD31') || item.listeQuiz.filter((quiz) => quiz.id != 'EPD30'));
      if (idsListeQuiz.includes('EPD30') || idsListeQuiz.includes('EPD31')) {
        idsListeQuiz = idsListeQuiz.filter(id => id !== 'EPD31' && id !== 'EPD30');
        idsListeQuiz.push('EPD3');
        datatosync = datatosync.filter(item => item.listeQuiz.filter((quiz) => quiz.id != 'EPD31') && item.listeQuiz.filter((quiz) => quiz.id != 'EPD30'));

        let EPD31 = itemsArray[0].listeQuiz[0].competences
        let data = itemsArray[0].listeQuiz[0];



        EPD31.forEach(element => {
          if (EPD3.findIndex(el => el.texte === element.texte) === -1) {
            EPD3.push(element);
          }
        });


        //let EPD3= EPD30.concat(EPD31)
        datatosync.push({
          listeQuiz: [{
            actions: data.actions,
            appreciation: data.appreciation,
            cidInscription: data.cidInscription,
            commentaire: data.commentaire,
            competences: EPD3,
            evaluationSatisfaisante: data.evaluationSatisfaisante,
            formateur: data.formateur,
            formateurType: data.formateurType,
            id: "EPD3",
            mainNb: data.mainNb,
            surfaceNb: data.surfaceNb,
            type: data.type,
          }], medicals: [], nomUsager: itemsArray[0].nomUsager
        })
        setItem('apprenants epd3', datatosync);
      }
      const satisfyingQuizIds = datatosync
        .flatMap((item) => item.listeQuiz)
        .filter((quiz) => quiz.evaluationSatisfaisante === true && quiz.cidInscription === cIdInscription)
        .map((quiz) => quiz.id);
      const existingUserIndex = datatosync.findIndex((user) => user.nomUsager === userAp);
      const unsatisfyingQuizIds = datatosync
        .flatMap((item) => item.listeQuiz)
        .filter((quiz) => quiz.evaluationSatisfaisante === false && quiz.cidInscription === cIdInscription)
        .map((quiz) => quiz.id);
      if (existingUserIndex != -1) {
        if (datatosync[existingUserIndex].medicals.length > 0) {
          // Filtrer les données médicales par cidInscription
          const userMedicals = datatosync[existingUserIndex].medicals.filter(el => el.cidInscription === cIdInscription);
        
          // Parcourir les données médicales filtrées
          userMedicals.forEach((el) => {
            console.log('el::::',el);
            if (!(el.presenteConditionMedicale && el.conditions.length > 0) ) {
              satisfyingQuizIds.push(el.id);
            } else {
              unsatisfyingQuizIds.push(el.id);
            }
          });
        }
      }
      setQSQuizDone(satisfyingQuizIds);
      setQSQuizDoneRetake(unsatisfyingQuizIds);
      setExistingData(datatosync)
      //console.log('quiz Effectuer', idsListeQuiz);
      //console.log('satesfying', satisfyingQuizIds);
    }
  }, [datatosync, route.params]);

  //console.log('exist----------->', existingData)

  useEffect(() => {
    //console.log('filter list', filterQuizs);
  }, [filterQuizs, quizDone, quizDoneRetake, datatosync.listeQuiz]);


  console.log('quizDone:::::::::::::::', filterQuizs)
  return (
    <>
      <SafeAreaView style={[style.area, { marg: -20 }]}>
        <>

          <View style={{ flexDirection: 'row', position: 'sticky', top: 0, backgroundColor: '#013E7F', zIndex: 2 }}>
            <View style={{ width: '90%', alignItems: 'left' }}>

              <TouchableOpacity onPress={ () => isConnected?navigation.navigate('Home'):navigation.navigate('RecordedData') }
                style={{ color: 'white', padding: 8, flexDirection: 'row', fontSize: 18, marginTop: 10, height: 60 }}>
                <Icons name="arrow-left-thin" size={28} color="#fff" style={{ marginRight: '7', marginTop: -2 }} />
                <Text style={{ color: 'white', fontSize: 18 }}>{typeFormation}</Text>
              </TouchableOpacity>

            </View>

          </View>
        </>
        <View style={[style.main, { marginTop: -10 }]}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {/* <View style={[{ marginVertical: 15 }]}></View> */}
            <View style={[{ marginVertical: 15 }]}>
            {filterQuizs && filterQuizs.length > 0 ? (
              filterQuizs.sort((a, b) => {
                // Mettre les quiz liés aux conditions médicales en premier
                if (a.idModule === "EPCM" || a.idModule === "FPCM" || a.idModule === "NS0") return -1;
                if (b.idModule === "EPCM" || b.idModule === "FPCM" || b.idModule === "NS0") return 1;
                // Sinon, garder l'ordre actuel
                return 0;
              }).map((quiz, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      if (quiz.idModule == "EPD3") {
                        navigation.navigate('Category', { QuizItem: quiz });
                        setItem('EPD3', quiz)
                      }
                      else if (quiz.idModule === "EPCM" || quiz.idModule === "FPCM" || quiz.idModule === "NS0") {
                        navigation.navigate('MedicalConditions', { QuizItem: quiz });
                      } else {
                        navigation.navigate('Module', { QuizItem: quiz });
                      }
                    }}
                    activeOpacity={1}
                    underlayColor="transparent"
                    style={[
                      styles.quizItem,
                      quizDone && quizDone.includes(quiz.idModule) && { opacity: 0.8 },
                    ]}
                  >
                    <React.Fragment>
                      <Text
                        style={[
                          style.s18,
                          {
                            flex: 1,
                            marginRight: 10,
                            color: Colors.active,
                          },
                        ]}
                      >
                        {lang === 'fr' ? quiz.nomFr : quiz.nomEn} {quiz.idModule != "FPCM" && quiz.idModule != "EPCM" && quiz.idModule != "NS0" ? (quiz.idModule) : ""}
                      </Text>
                      <Icons name={quizDone && quizDone.includes(quiz.idModule)
                        ? 'check-bold' : quizDoneRetake && quizDoneRetake.includes(quiz.idModule)
                          ? 'window-close' : 'chevron-right'} style={
                            quizDone && quizDone.includes(quiz.idModule)
                              ? {
                                fontSize: 31,
                                color: 'green'}
                              : quizDoneRetake && quizDoneRetake.includes(quiz.idModule)
                                ?{
                                  fontSize: 25,
                                  color: 'red',
                                }: {
                                  fontSize: 25,
                                  color: '#193d7e',
                                }} />
                    </React.Fragment>
                  </TouchableOpacity>
                )
              })
            ) : (
              <Text style={{ textAlign: 'center', fontSize: 18, marginTop:20 }}>{t('NullQuiz')}</Text>
            )}
          </View>

          </ScrollView>
        </View>
        {/* <BottomBar /> */}
      </SafeAreaView>
    {  showBottomBar && 
    (
    <View style={[styles.bottomBar, { backgroundColor: isConnected ? '#f9f9ff' : '#e14040' }]}> 
    <Text style={styles.bottomBarText}> {isConnected ? null: t('nonConnecte')} </Text> 
    <Text style={styles.statusText}> {isConnected ? null : t('horsligne')} </Text> 
    </View>)}
    </>
  );
}

const styles = StyleSheet.create({
  quizItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 18,
    backgroundColor: '#fff',
    borderRadius: 4,
    elevation: 3,
    shadowColor: '#2d64c7e0',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    marginBottom: 12,
  },

  quizItemHover: {
    backgroundColor: '#0158af',
    transform: [{ scale: 1.00 }],
    shadowColor: '#fff',
  },
  bottomBar: { 
    height: 50,
     justifyContent: 'center',
      alignItems: 'center',
     },
 bottomBarText: { 
  color: 'white',
   fontSize: 15, 
   fontWeight: 'bold', 
  }, statusText: { color: 'white',
   fontSize: 12, }, 
});
