import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json'

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
    },
    lng: 'en',  // Default language
    fallbackLng: 'en',  // Fallback language if a translation is missing
    interpolation: {
      escapeValue: false,  // React already escapes values
    },
  });

export default i18n;
