import React, { useState, useEffect } from 'react';
import { View, Text, Button, StyleSheet, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from '@react-native-community/netinfo';

const SyncDataScreen = ({ navigation, route }) => {
  const [offlineData, setOfflineData] = useState([]);
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const fetchDataLocally = async () => {
      try {
        const savedDataString = await AsyncStorage.getItem('yourDataKey');

        if (savedDataString) {
          const savedData = JSON.parse(savedDataString);
          setOfflineData(savedData);
        }
      } catch (error) {
        console.error('Error retrieving local data: ', error);
      }
    };

    fetchDataLocally();

    const unsubscribe = NetInfo.addEventListener((state) => {
      setIsOnline(state.isConnected);

      // Check if the connection is restored
      if (state.isConnected) {
        Alert.alert(
          'Connection Restored',
          'Vous êtes maintenant en ligne. Appuyez sur OK pour synchroniser vos données.',
          [
            {
              text: 'OK',
              onPress: () => {
                // Trigger the synchronization logic here
                handleSync();
              },
            },
          ]
        );
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleSync = async () => {
    try {
      if (isOnline) {
        // Send the offlineData to the server for synchronization
        // Replace the following line with your actual server synchronization logic
        console.log('Sending data to server:', offlineData);

        // Clear local data after successful sync
        await AsyncStorage.removeItem('yourDataKey');

        navigation.goBack();
      } else {
        console.warn('Device is offline. Cannot synchronize data.');
      }
    } catch (error) {
      console.error('Synchronization error: ', error);
    }
  };

  return (
    <View>
      <Text>Data Saved in Local</Text>
      {offlineData.map((data, index) => (
        <Text key={index}>{JSON.stringify(data)}</Text>
      ))}
      <Button
        title="Sync"
        onPress={handleSync}
        disabled={!isOnline} // Désactive le bouton si l'appareil est hors ligne
        style={isOnline ? styles.syncButton : styles.disabledSyncButton}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  syncButton: {
    backgroundColor: 'blue',
  },
  disabledSyncButton: {
    backgroundColor: 'gray',
  },
});

export default SyncDataScreen;
