import React ,{useState , useEffect} from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

const RadioButton = ({ label, selectedRadioValue, onSelect,disabled ,color}) => {
  const [isSelected, setIsSelected] = useState(selectedRadioValue === label);

  useEffect(() => {
    setIsSelected(selectedRadioValue === label);
  }, [selectedRadioValue, label]);

  const handlePress = () => {
    setIsSelected(!isSelected);
    onSelect(isSelected ? null : label);
  };

  return (
    <TouchableOpacity
      style={{ flexDirection: 'row', alignItems: 'flex-start', marginVertical: 8 }}
      onPress={handlePress}
      disabled={disabled}
    >
      {/* RadioButton design */}
      <View
        style={{
          height: 24,
          width: 24,
          borderRadius: 12,
          borderWidth: 2,
          borderColor: color,
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 5,
          marginLeft: 15,
        }}
      >
        {isSelected && (
          <View
            style={{
              height: 15,
              width: 15,
              borderRadius: 11,
              backgroundColor: '#3b86e9',
            }}
          />
        )}
      </View>
      <Text style={{ fontSize: 16, fontWeight: 'bold', color:color}}>{label}</Text>
    </TouchableOpacity>
  );
};

export default RadioButton;
