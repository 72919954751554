import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

const RadioButtonLight = ({ label, selected, onSelect, disabled }) => {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 20 }}>
      <TouchableOpacity onPress={onSelect}  disabled={disabled}
        style={{
          width: 22,
          height: 22,
          borderRadius: 11,
          borderWidth: 2,
          borderColor: selected ? '#fff' : '#fff',
          backgroundColor: selected ? '#97c9f3' : '#fff',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 5,
        }}
      >
        {selected && <View style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: '#1052b5' }} />}
      </TouchableOpacity>
      <Text style={{ color: '#fff', fontSize: 16 }}>{label}</Text>
    </View>
  );
};

export default RadioButtonLight;
