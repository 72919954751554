import React, { useState, useContext,useEffect } from "react";
import { View, Text, SafeAreaView, Dimensions, ImageBackground, TouchableOpacity, TextInput, Image,
   ScrollView, StatusBar, } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import styles from '../theme/styles';
import { AppBar } from "@react-native-material/core";
import { Colors } from "../theme/color";
import { Avatar } from "react-native-paper";
import style from "../theme/style";
import Icons from "react-native-vector-icons/MaterialCommunityIcons";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useTranslation } from 'react-i18next';
import BottomBar from "./composants/BottomBar";
import ChangeLanguage from "./composants/ChangeLang";
const width = Dimensions.get("screen").width;
const height = Dimensions.get("screen").height;
import { Config } from "../config";
import { useStorage } from "../contextApp";
import ExternalLinkButton from "./ExternalLinkButton";
import NetInfo from '@react-native-community/netinfo';
export default function Profile() {
  const apiUrl = Config.apiUrl + "/" + "resto/bce/quiz/sync";
  const userApi = Config.userApi;
  const passApi = Config.passApi;
  const backUrl =Config.backUrl;
  const { storageState,setItem } = useStorage();
  const { t } = useTranslation();
// Déterminez le nombre de colonnes en fonction de la largeur de l'écran 
const screenWidth = Dimensions.get('window').width;
const valueWidth = screenWidth > 600 ? 60 : 90;
  const route = useRoute();
  const lang= storageState.lang
  const usernameEva= storageState.formateurUser?storageState.formateurUser:route.params.usernameEva

  const type= storageState.formateurType?storageState.formateurType:route.params.type

  const navigation = useNavigation();
  const  avatarLetters =usernameEva.slice(0, 2).toUpperCase();
const quizData =storageState.quizData;
  const [alert, setAlert] = useState(null);
  const [quizs, setQuizs] = useState([]);
  const userAp = storageState.username;
  let nomUsager = userAp ? userAp : "";
  const [dataSend,setDataSend] = useState([]);

  const pdfSource =lang === 'fr' ? 'Guide_Utilisateur.pdf' : 'User_Manual.pdf';
  const PdfFileLink = Config.backUrl + '/pdf/' + pdfSource;
  const [isConnected, setIsConnected] = useState(true);

useEffect(() => {
  const checkConnection = async () => {
    const initialConnectionInfo = await NetInfo.fetch();
    setIsConnected(initialConnectionInfo.isConnected);

    const unsubscribe = NetInfo.addEventListener(state => {
      setIsConnected(state.isConnected);
      setShowBottomBar(true);
      setTimeout(() => {
        setShowBottomBar(false);
      }, 3000);
    });

    return () => {
      unsubscribe();
    };
  };

  checkConnection();
}, []);
const [showBottomBar, setShowBottomBar] = useState(false);
const handleOfflineClick = () => {
  setShowBottomBar(true);
  setAlert({ type: 'error', message: t('errorConnexion') });
  setTimeout(() => {
   
    setShowBottomBar(false);
    setAlert(null);
  }, 3000);

};
useEffect(() => {
  setItem('quizList', quizs);
  fetchData();
// //console.log('quisList',quizs);
}, [quizs]); 
  useFocusEffect(
    React.useCallback(() => {

        fetchData();
      
      
    }, [])
  );
  useEffect(() => {
   
    console.log('datasend:', dataSend);
  }, [dataSend]);
  useEffect(() => {
    //console.log('type',storageState);
  }, [type]);
  const [existingData, setExistingData] = useState([]);

  const fetchData = async () => {
    try {
      // Construct the base URL

      let apiUrl = backUrl +'/app/syncron/lastbyevaluator';
      // If nomUsager is not null or empty, append it as a query parameter
      if (usernameEva) {
        apiUrl += `?usernameEva=${usernameEva}`;
      }

      // Fetch data using the constructed URL
      const response = await fetch(apiUrl);
      const newData = await response.json();

      // Update the existingData state with the fetched data
      setExistingData(newData);
      
      setItem('apprenants', newData);
      setDataSend(newData);
      //console.log('get sync ap', newData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchQuizs = async () => {
    const response = await fetch(backUrl +'/app/quizs');
  const data = await response.json();
  setQuizs(data);
  };

  useEffect(() => {
    fetchQuizs();
  // //console.log('quisList',quizs);
  }, []); 

  useEffect(() => {
    //console.log('lang:', storageState);

  }, [usernameEva]);
  const handleUpdateStatus = async (nomUsager) => {
    try {
      const apiUrl = backUrl +`/app/update-status/${nomUsager}`;
  
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        setItem('apprenants', []);
        setItem('competances', []);
        setDataSend([]);
        //console.log(`Update status for ${nomUsager} successful`);
      } else {
        console.error(`Error updating status for ${nomUsager}:`, response.statusText);
        setAlert({ type: 'error', message: t('errorSync') });
      }
    } catch (error) {
      console.error(`Error updating status for ${nomUsager}:`, error);
      setAlert({ type: 'error', message: t('errorSync') });
    }
  };




    
  const handleSyncronization = async () => {
    try {
      if (dataSend.length > 0 && dataSend[0].hasOwnProperty("listeQuiz")) {
        dataSend.forEach(entry => {
          if (entry.hasOwnProperty("listeQuiz")) {
            entry.listeQuiz.forEach(quiz => {
              if (quiz.hasOwnProperty("cour")) {
                delete quiz.cour;
              }
            });
          }
        });
      } ;
      
      if (dataSend.length > 0 && dataSend[0].hasOwnProperty("medicals")) {
        dataSend.forEach(entry => {
          if (entry.hasOwnProperty("medicals")) {
            entry.medicals.forEach(medical => {
              if (medical.hasOwnProperty("cour")) {
                delete medical.cour;
              }
              if (medical.hasOwnProperty("dateComplete")) {
                delete medical.dateComplete;
              }
            });
          }
        });
      }
      //console.log('dataSend après suppression de cour dans toutes les entrées de listeQuiz et medicale:', dataSend);
      
      //console.log('data to send:', dataSend);
  
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(userApi + ":" + passApi),
          "BCE-Quess-Auth": localStorage.getItem("jwt")
        },
        body: JSON.stringify({ apprenants: dataSend }),
      };
  
      // Vérification si le nom d'utilisateur et le mot de passe sont fournis
      if (!dataSend) {
        //console.log('error data send');
        // Handle the case where dataSend is empty
        return;
      }
  
      // Appel de l'API pour la connexion de l'apprenant
      const responseSyncron = await fetch(apiUrl, requestOptions);
  
      // Vérification de la réponse de l'API
      if (responseSyncron.ok) {
        const data = await responseSyncron.json();
        //console.log('Success send apprenant', data);
       
        setAlert({ type: 'success',message: t('successSync') });
        
        dataSend.forEach((apprenant) => {
          handleUpdateStatus(apprenant.nomUsager);
        });
        // Handle success response
      } else {
        //console.log('Error sending data');
        setAlert({ type: 'error', message: t('errorSync')  });
        // Handle error response
        throw new Error("Network response was not ok");
      }
      setTimeout(() => {
        setAlert(null); // Clear the alert after 7 seconds
      }, 3000);
    } catch (error) {
      setAlert({ type: 'error', message: t('errorSync')  });
      console.error("There was a problem with the synchronization:", error);
      // Handle other errors
    }
  };
  const handleLogoutFormateur = ()=>  {
    setAlert({ type: 'success', message: t('logoutSuccess') });
   var langue=lang;
    localStorage.clear();
    setItem('language',langue)
    setItem('lang',langue)
    setTimeout(() => {
      navigation.navigate('Login');
      setAlert(null)

    }, 1200); 
  
  }
  return (
    <SafeAreaView style={[style.area, {}]}>
      <StatusBar backgroundColor="transparent" translucent={true} />
   
      <ImageBackground
        source={require('../../assets/image/BG_0.png')}
        resizeMode="stretch"
        style={styles.backgroundImage}
      >
        <StatusBar backgroundColor="transparent" translucent={true} />
        <View style={styles.headerContainer}>
        <Avatar.Text
          size={40} label={avatarLetters}
          style={{  backgroundColor: '#c8dcff',  marginTop: 2,
            alignSelf: "center",  color:'#043ca5'  }}
        />
          <View style={styles.greetingContainer}>
            <Text style={styles.greetingText}>
            {t('hi')}, {usernameEva}
            </Text>
            <Text style={styles.welcomeText}>{t(type)}</Text>
          </View>
        <ChangeLanguage />
        <Icons name="power" style={{ fontSize: 34,marginLeft:10, color: "white" }} onPress={handleLogoutFormateur} />
        </View>
      </ImageBackground>
   
      <View style={[style.main, { marginTop: -50 }]}>
      <ImageBackground
        source={require('../../assets/image/BG-L.png')} style={{ flex: 1, height: '100%' }}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ marginTop: 140 }}
        >
          <View
            style={{
              flexDirection: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
               {alert && (
          <View style={[styles.alertContainer, { backgroundColor: alert.type === 'error' ? '#FFBABA' : '#D5E8D4' }]}>
            <Text style={[styles.alertText, { color: alert.type === 'error' ? '#D8000C' : '#4F8A10' }]}>
              {alert.message}
            </Text>
          </View>
        )}
            <Text style={[style.s17, { color: Colors.active }]}></Text>
            <TouchableOpacity
              onPress={() => navigation.navigate("Specialist")}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <Text style={[style.s13, { color: Colors.lable }]}></Text>
            </TouchableOpacity>
          </View>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
                width:`${valueWidth}%`,
              }}
            >
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("SecondLogin", {
                    usernameEva: usernameEva,
                    type: type,
                    lang: lang,
                  })
                }
                style={{
                  flex: 1,
                  backgroundColor: "#013E7F",
                  borderRadius: 35,
                  paddingVertical: 12,
                   paddingHorizontal: 28,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <Icons
                    name="account-switch"
                    style={{ fontSize: 35, color: "white" }}
                  />
                  <Text style={[style.s18, { color: Colors.secondary }]}>
                    {t('loginLearner')}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
               width:`${valueWidth}%`,
              }}
            >
              <TouchableOpacity
                onPress={() => navigation.navigate("DataTransfere" ,{data:""})}
                style={{
                  flex: 1,
                  backgroundColor:  '#013E7F' ,
                  borderRadius: 35,
                  paddingVertical: 12,
                    paddingHorizontal: 28,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <Icons
                    name="checkbox-marked-circle-plus-outline"
                    style={{ fontSize: 35, color: "white" }}
                  />
                  <Text style={[style.s18, { color: Colors.secondary }]}>
                    {t('dataTransfere')}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
               width:`${valueWidth}%`,
              }}
            >
              <TouchableOpacity
                 onPress={dataSend && dataSend.length > 0   ? handleSyncronization : handleOfflineClick}

                style={{
                  flex: 1,
                  // Assuming you're using this in a style object
                  backgroundColor:dataSend&& dataSend.length > 0  ? '#013E7F' : '#466badcf',

                  borderRadius: 35,
                  paddingVertical: 12,
                    paddingHorizontal: 28,
                }}
                disabled={dataSend.length === 0}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <Icons
                    name="cached"
                    style={{ fontSize: 35, color: "white" }}
                  />
                  <Text style={[style.s18, { color: Colors.secondary }]}>
                    {t('SynchronizeData')}
                  </Text>
                </View>
              </TouchableOpacity>
              
            </View>
            <View style={{ flexDirection: "row",alignItems: "center",marginTop: 15, width:`${valueWidth}%` }} >
            <ExternalLinkButton url={PdfFileLink} title={t('tuto')} />
            </View>
          </View>
   
        </ScrollView>
        </ImageBackground>
      </View>
      {/* <BottomBar /> */}
    </SafeAreaView>
  );
}
