import React from 'react';
import { View, ImageBackground } from 'react-native';

const BackgroundScreen = ({ children }) => {
  return (
    <ImageBackground source={require("./assets/bg-home.jpg")} style={{ flex: 1, height: '100%' }}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        {children}
      </View>
    </ImageBackground>
  );
}

export default BackgroundScreen;
