import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/locales/i18n';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import StackNavigator from './src/navigator/StackNavigator';
import { StorageProvider } from './src/contextApp';
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native'; // Import React Native modal components
import { useTranslation } from 'react-i18next';

const Stack = createStackNavigator();

function App() {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const beforeUnloadListener = (event) => {
      const message = t('refreshMsg');
      event.returnValue = message;
      handleModalOpen();
      return message;
    };

    const popstateListener = () => {
      handleModalOpen();
      history.pushState(null, null, window.location.pathname);
    };

    window.addEventListener('beforeunload', beforeUnloadListener);
    window.addEventListener('popstate', popstateListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
      window.removeEventListener('popstate', popstateListener);
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <NavigationContainer>
        <StorageProvider>
          <StackNavigator />
          {/* Modal pour la confirmation */}
          <Modal
            animationType="slide"
            transparent={true}
            visible={showModal}
            onRequestClose={handleModalClose}
          >
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 26, 73, 0.79)' }}> {/* Blue transparent background */}
              <View style={{ backgroundColor: 'white', padding: 30, borderRadius: 8, width: 350 }}>
                <Text style={{ marginBottom: 20,fontWeight:"700" ,color:"#01689C" }}>{t('refreshMsg')}</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <TouchableOpacity onPress={handleModalClose} style={{ padding: 10, borderRadius: 5, marginRight: 10, backgroundColor: 'red' }}>
                    <Text style={{color:'white'}}>{t('non')}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={handleRefresh} style={{ padding: 10, borderRadius: 5, backgroundColor: 'green', color: 'white' }}>
                    <Text style={{color:'white'}}>{t('oui')}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </StorageProvider>
      </NavigationContainer>
    </I18nextProvider>
  );
}

export default App;
