import React, { useState, useEffect } from 'react';
import { Platform,View,Text,SafeAreaView,Dimensions,StyleSheet,ImageBackground,TouchableOpacity,Image,ScrollView,StatusBar,TouchableHighlight } from 'react-native';
import { Hoverable } from 'react-native-web-hover';
import { Colors } from '../theme/color';
import style from '../theme/style';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useStorage } from '../contextApp';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons'
import { green } from './Constants';
const width = Dimensions.get('screen').width;
const height = Dimensions.get('screen').height;
import { useTranslation } from 'react-i18next';

const Category = ({ route }) => {

    const { t } = useTranslation();
    const { storageState, setItem  } = useStorage();
    const { QuizItem } = route.params;
    const exitingData = storageState.apprenants;
    const navigation = useNavigation();
    const lang = storageState.lang;
    const [categoryDone, setQScategoryDone] = useState([]);
    const [categories,setCategories] = useState([]);
    let idsListeQuiz = [];
    ////console.log("competences",exitingData)
    const completedCategory= storageState.completedCategory;
    useEffect(() => {
      ////console.log('data storge app :', exitingData);
      if (exitingData && Array.isArray(exitingData)) {
        idsListeQuiz = exitingData.flatMap((item) => item.listeQuiz.map((quiz) => quiz.id));
        if(QuizItem.idModule==="EPD3") 
        {
          
         
          //console.log("completedCategory",completedCategory)
          if(completedCategory) 
          {
            
             idsListeQuiz.push(completedCategory.id)
            

          } 
         
        }
        setQScategoryDone(idsListeQuiz);
        //console.log('category Effectuer', idsListeQuiz);
      }
    }, [exitingData]);
    useEffect(() => {
        var  categoriesList= []
       //console.log(QuizItem)
       var idCat = 0;
       var idMod=""
       QuizItem.competences.forEach(element => {
        var categorie= lang==='fr'?element.categoryFr:element.categoryEn
        if(categorie.includes(";")) {
            var categs = categorie.split(";")
            if ( categs.length>1) {
                categs.forEach((el)=> {
                    if(categoriesList.findIndex((category)=> category.name == el)==-1) 
                    {
                        categoriesList.push({name:el,
                            id:QuizItem.id,
                            categoryName:QuizItem.categoryName,
                            idModule:QuizItem.idModule+idCat,
                            main:QuizItem.main,
                            mainSurface:QuizItem.mainSurface,
                            nomFr:QuizItem.nomFr,
                            nomEn:QuizItem.nomEn,
                            actions:QuizItem.actions,
                            points:QuizItem.points,
                            surface:QuizItem.surface,
                            typeName:QuizItem.typeName,
                            competences:[element]})
                            idCat+=1
                    }
                    else {
                        var cat = categoriesList.find((category)=> category.name == el)
                        cat.competences.push(element)
                    }
                })
                
            }
        } else {
            if(categoriesList.findIndex((category)=> category.name == categorie)==-1) 
            {
                categoriesList.push( {name:categorie,
                    id:QuizItem.id,
                    categoryName:QuizItem.categoryName,
                    idModule:QuizItem.idModule+idCat,
                    main:QuizItem.main,
                    mainSurface:QuizItem.mainSurface,
                    nomFr:QuizItem.nomFr,
                    nomEn:QuizItem.nomEn,
                    actions:QuizItem.actions,
                    points:QuizItem.points,
                    surface:QuizItem.surface,
                    typeName:QuizItem.typeName,
                    competences:[element]})
                    idCat+=1
            }
            else {
                var cat = categoriesList.find((category)=> category.name == categorie)
                cat.competences.push(element)
            }
        }
     categoriesList.map((categorie)=> {
      if(QuizItem.idModule==="EPD3") 
      {
        
        const apprenantQuiz = exitingData.find(item => item.listeQuiz.some(quiz => quiz.id === "EPD3"));
        const EPD3 =apprenantQuiz && apprenantQuiz.listeQuiz.find(q => q.id ===  "EPD3");

       
        if(EPD3)
        {
          if(EPD3.competences.length===categorie.competences.length) {
          categorie.competences=EPD3.competences;
           idMod=categorie.idModule
        }
      }
       
       
      }
     })
     ////console.log('quizDoneeeeeeee',idsListeQuiz)
    
     setCategories(categoriesList)
       });
       setQScategoryDone(prevState => [...prevState, idMod]);
      // //console.log("eeeeeeeeeeeeeeeeeeeeeee",idMod)
      }, []);

      const dataSend = storageState.apprenants;
      console.log('deja sync home ---------->', dataSend);
          const goToCourse= ()=> {
            navigation.navigate('Practical Assessment',{
              datatosync: dataSend
            });
          }
      //console.log(categories)
      return (
        <>
          <SafeAreaView style={[style.area, { marg: -20 }]}>
          <>
            <View style={{ flexDirection: 'row', position: 'sticky', top: 0, backgroundColor: '#013E7F', zIndex: 2 }}>
                <View style={{width:'90%',alignItems:'left' }}>
               
                  <TouchableOpacity onPress={goToCourse}
                  style={{color:'white',padding:8 ,flexDirection:'row',fontSize:18,marginTop:10,height:60}}>
                  <Icons  name="arrow-left-thin"  size={28} color="#fff" style={{ marginRight: '7', marginTop:-2 }} /> 
                  <Text style={{color:'white',fontSize:18}}>{t('backList')}</Text>  
                  </TouchableOpacity>
                 
                 </View>
                 
                <View style={{width:'10%',alignItems:'right'}}> 
              
                 
                </View>
            </View> 
            </>
            <View style={[style.main, { marginTop: -10 }]}>
              <ScrollView showsVerticalScrollIndicator={false}>
                <View style={[{ marginVertical: 15 }]}></View>
                {categories.map((category, index) => {
                  
                  //console.log(categoryDone.indexOf(category.idModule))
                  return(
     
          <TouchableOpacity
            onPress={() => {navigation.navigate('Module', { QuizItem: category });}}
            activeOpacity={1}
            underlayColor="transparent"
            style={[
              styles.quizItem,    categoryDone && categoryDone.includes(category.idModule) && { opacity: 1 },
             
              // You can add additional styles based on conditions here
          
            ]}
           
          >
            <React.Fragment>
              <Text
                style={[
                  style.s18,
                  {
                    flex: 1,
                    marginRight: 10,
                    color: Colors.active,
                  },
                ]}
              >
                {lang === 'fr' ? category.name : category.name} 
              </Text>
             <Icons name=   {'chevron-right'}  style={{ fontSize:25,color: '#9bbef3'} }/>
             
            </React.Fragment>
          </TouchableOpacity>
     
    )})}
    
              </ScrollView>
            </View>
          </SafeAreaView>
        </>
      );
};
const styles = StyleSheet.create({
    quizItem: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 15,
      paddingVertical: 18,
      backgroundColor: '#fff',
      borderRadius: 4,
      elevation: 3,
      shadowColor: '#0158af',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 2,
      marginBottom: 8,
    },
    
    quizItemHover: {
      backgroundColor: '#0158af',
      transform: [{ scale: 1.00 }],
      shadowColor: '#fff',
    },
  });
export default Category;