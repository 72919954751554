import React from 'react';
import { View, Text, SafeAreaView, Image, Dimensions, StatusBar } from 'react-native';
import style from '../theme/style';
import { useNavigation } from '@react-navigation/native';
import { Colors } from '../theme/color';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import LinearGradient from 'react-native-web-linear-gradient';

const width = Dimensions.get('screen').width;
const height = Dimensions.get('screen').height;

export default function Splash() {
  const navigation = useNavigation();

  return (
    <LinearGradient colors={['#1e2b96', '#237bb9', '#77b3f1']} style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      {/* Your existing content */}
      <SafeAreaView style={{ flex: 1 }}>
        {/* <StatusBar translucent={true} backgroundColor='transparent' /> */}
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 100 }}>
          <Image source={require('./assets/loading-load.gif')} style={{ width: 110, height: 110, marginBottom: 180 }} />
          <Image source={require('./assets/logo-en.png')} style={{ height: 88, width: 220 }} />
        </View>
        <View style={{ justifyContent: 'flex-end', alignItems: 'center', marginBottom: 20 }}>
          <Text style={{ color: 'white', fontSize: 16 }}>Version 1.0.0</Text>
        </View>
      </SafeAreaView>
    </LinearGradient>
  );
}
