import React from 'react';
import { View, Text, Button } from 'react-native';

const StageSelectionScreen = ({ navigation }) => {
  const handleStageSelection = (competenceData) => {
    navigation.navigate('OfflineForm', { competence: competenceData });
  };

  return (
    <View>
      <Text>Sélection du type de stage</Text>
      <Button title="Sterile" onPress={() => handleStageSelection()} />
 
      <Button title="Non Sterile" onPress={handleStageSelection} />
    </View>
  );
};


export default StageSelectionScreen;
