import {View, Text, TouchableOpacity} from 'react-native';
import React from 'react';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';

export default function Btn({bgColor, btnLabel, textColor, Press,icon}) {
  return (
    <TouchableOpacity
    onPress={Press}
      style={{
        backgroundColor: bgColor,
        borderRadius: 30,
        alignItems: 'center',
        width: 200,
        paddingVertical: 15,
        marginVertical: 10,flexDirection:'row', justifyContent:'center'
      }}>
      <Text style={{color: textColor, fontSize: 18, fontWeight: '500'}}>
        {btnLabel}
      </Text><Icons name={icon} size={20}  style={{ marginHorizontal: 10,color:'white' }} />
    </TouchableOpacity>
  );
}