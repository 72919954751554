import React, { useState, useEffect } from 'react';
import { View, Text, Switch,Dimensions, TouchableOpacity, TextInput, Modal, Button } from 'react-native';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Colors } from '../../theme/color';
import style from '../../theme/style';
import stylesForm from '../../theme/step';
import Checkbox from './Checkbox';
import RadioButton from './RadioButton';
import RadioButtonLight from './RadioButtonLight';
import { useStorage } from '../../contextApp';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
const { height: screenHeight, width: screenWidth } = Dimensions.get('window');
const ConditionsMedicales = ({ medicals,setMedicals, quizItem,selectedEtat, setSelectedEtat, selectedRadio, setSelectedRadio,presenteConditionMedicale,setPresenteConditionMedicale, maintientActivite, setMaintientActivite, selectedConditions, setSelectedConditions }) => {
  const { storageState, setItem } = useStorage()

  const usernameEva = storageState.formateurUser;
  const typeFormateur = storageState.formateurType;
  const { t } = useTranslation();
  const userAp = storageState.username;
  const lang = storageState.lang
  const cidInscription = storageState.cidInscription;
  const type = quizItem.typeName === 'Évaluation' ? 'evaluation' : 'formation'
  const idMod = quizItem.idModule
  const courName = storageState.courName;
  const [commentaire, setCommentaire] = useState('');
  const [commentStates, setCommentStates] = useState(false);
  const [editMode, setEditMode] = useState(false); // Nouvel état pour le mode édition

  const [openDialog, setOpenDialog] = useState(false);
  const radioetat = [
    { label: t('oui'), value: true },
    { label: t('non'), value: false }
  ];
  const radiocondition = [
    { label: t('maintientMsg'), value: true },
    { label: t('reportMessage'), value: false }
  ];
  const conditionsData = [
    { id: 1, fr: "Infection respiratoire active", en: "Active respiratory infection" },
    { id: 2, fr: "Conjonctivite", en: "Conjunctivitis" },
    { id: 3, fr: "Feu sauvage", en: "Cold sore" },
    { id: 4, fr: "Plaie fraîche (incluant nouveau tatouage)", en: "Fresh wound (including new tattoo)" },
    { id: 5, fr: "Lésions cutanées importantes", en: "Serious skin lesions" },
    { id: 6, fr: "Autre condition ou infection virale (s.v.p. précisez dans la boîte commentaire)", en: "Other condition or viral infection (please specify in the comments box)" }
  ];
  
  const currentDateISO = moment().tz("America/Montreal").format();
  
  //console.log("apprenant :: ",storageState.apprenants);

  
  //if medicals exist avec mem cidInscreption la formulaire new medical sera remplir avec le storageState.apprenants.medicals

  const newMedicalsData = {
    id: idMod,
    cidInscription: cidInscription,
    presenteConditionMedicale: presenteConditionMedicale,
    maintientActivite: maintientActivite,
    type: type,
    formateur: usernameEva,
    formateurType: typeFormateur,
    commentaire: commentaire,
    conditions: selectedConditions,
    cour: courName,
    dateComplete: currentDateISO
  };
  useEffect(() => {
    if (storageState.apprenants && storageState.apprenants.length > 0) {
      // Accédez aux données médicales pour le premier utilisateur (dans cet exemple)
      const medicalsForUseEffect = storageState.apprenants[0].medicals;
      // Vérifiez si des données médicales existent pour cet utilisateur avec le même cidInscription et idMod
      const existingMedicals = medicalsForUseEffect.find(medical => medical.cidInscription === cidInscription && medical.id === idMod );
      // Si des données médicales existent, remplissez le formulaire avec ces données
      if (existingMedicals) {
        // Mettre à jour les états locaux avec les données médicales existantes
        setPresenteConditionMedicale(existingMedicals.presenteConditionMedicale);
        setMaintientActivite(existingMedicals.maintientActivite);
        setSelectedConditions(existingMedicals.conditions);
        setCommentaire(existingMedicals.commentaire);
        setSelectedEtat(existingMedicals.presenteConditionMedicale ? t('oui') : t('non'));
        if (existingMedicals.presenteConditionMedicale === true) {
          setSelectedRadio(existingMedicals.maintientActivite ? t('maintientMsg') : t('reportMessage'));
        }
      } else {
        // Si aucune donnée médicale n'existe pas, initialisez les états locaux avec des valeurs par défaut
        setPresenteConditionMedicale(false);
        setMaintientActivite(false);
        setSelectedConditions([]);
        setCommentaire('');
        setSelectedEtat(null);
        setSelectedRadio(null);
      }
    }
  }, [storageState.apprenants, cidInscription, idMod]);
 
  if(medicals.length>0)
    {
     
      var medicalsInfos = medicals;
    } 
    else {
      const existingUserIndex = storageState.apprenants.findIndex((user) => user.nomUsager === userAp);

      if (existingUserIndex !== -1) {

      
        var medicalsInfos = storageState.apprenants[existingUserIndex].medicals
       
      }
      else {
        
        var medicalsInfos = [];
      }
    }

  const indexConditionsMedicales = medicalsInfos.findIndex((el) => el.id === idMod && el.cidInscription === cidInscription)
  if (indexConditionsMedicales !== -1) {
    var medicalsData = [...medicalsInfos];
  }
  else {

    var medicalsData = [...medicalsInfos, newMedicalsData];
  }
  useEffect(() => {
    if (medicalsData.length > 0) {
        setMedicals(medicalsData)
      }
    
  }, []);

  const togCommentStatus = () => {
    // Activer ou désactiver le mode édition
    setEditMode(!editMode);

    // Réinitialiser le commentaire en mode édition
    if (!editMode) {
      setCommentaire(commentaire);
    }
  };
  //fuction radioLightChange
  const handleRadioLightChange = (selectedLabel) => {
    const selectedOption = radioetat.find(option => option.label === selectedLabel);
    if (selectedOption) {
      setSelectedEtat(selectedLabel); // Mettre à jour l'état sélectionné
      setPresenteConditionMedicale(selectedOption.value); // Mettre à jour presenteConditionMedicale en fonction de l'option sélectionnée

      if (selectedOption.value) {
        // Si "oui" est sélectionné, ouvrir la boîte de dialogue
        setOpenDialog(true);
      } else {
        // Si "non" est sélectionné, définir maintientActivite et conditions à leur état initial
        setSelectedRadio(null);
        setSelectedConditions([]);
      }

      // Mettre à jour medicalsData
      const indexConditionsMedicales = medicalsData.findIndex((el) => el.id === idMod && el.cidInscription === cidInscription);
      if (indexConditionsMedicales !== -1) {
        const updatedMedicalsData = medicalsData.map((item, index) => {
          if (index === indexConditionsMedicales) {
            return {
              ...item,
              presenteConditionMedicale: selectedOption.value,
              maintientActivite: false, // Réinitialiser maintientActivite
              conditions: [], // Réinitialiser les conditions
            };
          } else {
            return item;
          }
        });
        //console.log(updatedMedicalsData)
        setMedicals(updatedMedicalsData);
       
      }
    }
  };
  
 // console.log("medicalsDataaaaaa;;;;",medicalsData)

  const toggleCheck = (id, texte) => {
    setSelectedConditions((prevConditions) => {
      const isSelected = prevConditions ? prevConditions.some((condition) => condition.id === id) : false;
      let updatedConditions;

      if (isSelected) {
        // Si la condition est déjà sélectionnée, la supprimer
        updatedConditions = prevConditions.filter((condition) => condition.id !== id);
      } else {
        // Sinon, l'ajouter
        updatedConditions = [...(prevConditions || []), { id, texte }];
      }

      // Mettre à jour medicalsData
      const indexConditionsMedicales = medicalsData.findIndex((el) => el.id === idMod && el.cidInscription === cidInscription)
      if (indexConditionsMedicales !== -1) {

        const updatedMedicalsData = medicalsData.map((item, index) => {
          if (index === indexConditionsMedicales) {
            // If it's the index you want to update, spread the existing object properties and update the conditions and presenteConditionMedicale properties
            return {
              ...item,
              conditions: updatedConditions,
            };
          } else {
            // If it's not the index you want to update, return the original item unchanged
            return item;
          }
        });

        // Définir medicals avec les données mises à jour
        setMedicals(updatedMedicalsData);
      }

      return updatedConditions;
    });
  };
  const handleRadioChange = (selectedLabel) => {
    const selectedOption = radiocondition.find(option => option.label === selectedLabel);
    if (selectedRadio === selectedLabel) {
      var newValue = null
      setSelectedConditions([])
    }
    else {
      var newValue = selectedLabel;
    }
    if (selectedOption) {
      setMaintientActivite(selectedOption.value);
      setSelectedRadio(newValue); // Sélectionner le choix radio
      // Mettre à jour medicalsData
      const indexConditionsMedicales = medicalsData.findIndex((el) => el.id === idMod && el.cidInscription === cidInscription)
      if (indexConditionsMedicales !== -1) {
        // Mettre à jour medicalsData


        const updatedMedicalsData = medicalsData.map((item, index) => {
          if (index === indexConditionsMedicales) {
            // If it's the index you want to update, spread the existing object properties and update the conditions and presenteConditionMedicale properties
            return {
              ...item,
              presenteConditionMedicale : true,
              maintientActivite: selectedOption.value
            };
          } else {
            // If it's not the index you want to update, return the original item unchanged
            return item;
          }
        });

        // Définir medicals avec les données mises à jour
        setMedicals(updatedMedicalsData);
      }
    }
  };

  // useEffect(() => {

  //   if (presenteConditionMedicale === false) {
  //     // Si presenteConditionMedicale est défini sur false, décocher le maintien d'activité et vider les conditions sélectionnées
  //     setSelectedRadio(null); // Décocher le maintien d'activité
  //     setSelectedConditions([]); // Vider les conditions sélectionnées
  //   }
  // }, [presenteConditionMedicale, selectedRadio, selectedConditions]);

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleCommentSend = () => {
    // Mettre à jour medicalsData avec le commentaire
    const indexConditionsMedicales = medicalsData.findIndex((el) => el.id === idMod && el.cidInscription === cidInscription)
    if (indexConditionsMedicales !== -1) {
      // Mettre à jour medicalsData

      const updatedMedicalsData = medicalsData.map((item, index) => {
        if (index === indexConditionsMedicales) {
          // If it's the index you want to update, spread the existing object properties and update the conditions and presenteConditionMedicale properties
          return {
            ...item,
            commentaire: commentaire
          };
        } else {
          // If it's not the index you want to update, return the original item unchanged
          return item;
        }
      });

      // Définir medicals avec les données mises à jour
      setMedicals(updatedMedicalsData);
    }
    // Désactiver le mode édition
    setEditMode(false);

    // Définir medicals avec les données mises à jour

  };

  useEffect(() => {
    // Cet effet sera exécuté chaque fois que selectedConditions ou maintientActivite changera
    // Vous pouvez ajouter une logique supplémentaire ou des appels API ici si nécessaire

  }, [selectedConditions, maintientActivite]);

  return (
    <View style={stylesForm.panelBlock}>
      <View style={stylesForm.panel}>
        <Text style={[stylesForm.panelHeader, {  }]}>{t('presenteConditionsMedicales')}</Text>
        <View style={{  flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', marginRight: '1%' }}>
          {radioetat.map((option) => (
            <RadioButtonLight
              key={option.label}
              label={option.label}
              selected={selectedEtat === option.label} // Vérifiez si l'option est sélectionnée en fonction de selectedRadio
              onSelect={() => handleRadioLightChange(option.label)}
              disabled={false} // Ajoutez votre logique de désactivation ici si nécessaire
            />
          ))}
        
          </View>
          <TouchableOpacity onPress={() => togCommentStatus()}>
            <Icons
              name={commentaire ? 'square-edit-outline' : 'tooltip-text'}
              size={28}
              color="#fff"
              style={{ marginRight: '4' }}
            />
          </TouchableOpacity>
        </View>
      </View>

      <View style={stylesForm.panelBody}>
        <View>
          {radiocondition.map((label) => (
            <RadioButton
              key={label.label}
              label={label.label}

              selectedRadioValue={selectedRadio} // Utiliser l'état local pour gérer la sélection
              onSelect={() => handleRadioChange(label.label)}
              disabled={!presenteConditionMedicale}
              color={!presenteConditionMedicale ? '#acb3cdd9':'#2f47a4' }
            />
          ))}
        </View>

        {!maintientActivite && (
          <View>
            {/* En supposant que conditionsData est un tableau que vous souhaitez mapper */}
            {conditionsData.map((item, index) => (
              <Checkbox
                key={item.id}
                label={lang === "fr" ? item.fr : item.en}
                selected={selectedConditions ? selectedConditions.some((condition) => condition.id === item.id) : false}
                onSelect={() => toggleCheck(item.id, lang === "fr" ? item.fr : item.en)}
                disabled={!maintientActivite && selectedRadio == null}
              />
            ))}
          </View>
        )}

        <View>
          {editMode ? (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TextInput
                name="commentaire"
                placeholder={t('writeComment')}
                type="text"
                multiline
                style={{ width: '80%', backgroundColor: '#cce0ff', borderRadius: 5 }}
                value={commentaire}
                onChangeText={(comment) => setCommentaire(comment)}
              />
              <TouchableOpacity onPress={() => handleCommentSend()}>
                <Icons name="send" size={28} color="#1052b5" />
              </TouchableOpacity>
            </View>
          ) : (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {commentaire ? (
                <View style={styles.commentContainer}>
                  <View style={styles.commentBox}>
                    <Icons name='text-box' size={20} color="#c4dffb" style={styles.commentIcon} />
                    <Text style={styles.commentText}>{commentaire}</Text>
                  </View>
                </View>
              ) : null}
            </View>
          )}
        </View>
      </View>
      {/* Modal de chat */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={openDialog}
        onRequestClose={handleCloseDialog}
      >
        <View style={stylesForm.modalContainer}>
        <View style={[stylesForm.modalContent, { width: screenWidth * 0.8 }]}>
            <View style={stylesForm.modalHeader}>

              <TouchableOpacity onPress={handleCloseDialog}>
                <Icons name="close" size={28} color="#eb8181" />
              </TouchableOpacity>
            </View>
            <View>
              <View style={{marginBottom:15}}>
              <Text style={stylesForm.modalHeaderText}>{t('medicalCondsMessage')}</Text>
              </View>
              <Button title={t('confirm')} onPress={handleCloseDialog} />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = {
  commentContainer: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#f0f0f0',
    borderRadius: 8,
  },
  commentBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentIcon: {
    marginRight: 10,
  },
  commentText: {
    fontSize: 16,
    color: '#333',
  },
};

export default ConditionsMedicales;