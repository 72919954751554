import React, { useState, useEffect } from "react";
import { TouchableHighlight,View, Text, Image,Dimensions, TouchableOpacity, StyleSheet } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Background from "./BackgroundScreen";
import Btn from "./Btn";
import Field from "./Field";
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useRoute } from "@react-navigation/native";
import { useTranslation } from 'react-i18next';
import { Config } from "../config";
import logoFr from "./assets/logo-fr-dark.png";
import logoEn from "./assets/logo-en-dark.png";
const apiUrl = Config.apiUrl + '/' + 'resto/bce/quiz/loginFormateur';
import { useStorage } from "../contextApp";
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
const userApi = Config.userApi;
const passApi = Config.passApi;
import i18n from '../locales/i18n';
import ExternalLinkButton from "./ExternalLinkButton";
const RadioButton = ({ label, selected, onSelect }) => (
  <TouchableOpacity
    style={{
      flexDirection: "row",
      alignItems: "flex-start",
      marginVertical: 8,
    }}
    onPress={onSelect}
  >
    <View
      style={{
        height: 24,
        width: 24,
        borderRadius: 12,
        borderWidth: 2,
        borderColor: "#2a77a8",
        alignItems: "center", // Align center for circle
        justifyContent: "center",
        marginRight: 4, marginLeft: 14, alignContent: "center",
      }}
    >
      {selected ? (
        <View
          style={{
            height: 15,
            width: 15,
            borderRadius: 11,
            backgroundColor: "#1ac71a",
          }}
        />
      ) : null}
    </View>
    <Text style={{ fontSize: 16, fontWeight: "bold", color: "#2a77a8" }}>
      {label}
    </Text>
  </TouchableOpacity>
);
const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const widthColumn = screenWidth > 1060 ? "88%" :screenWidth > 760 ? "87%" : "88%";
const widthMax = screenWidth > 1060 ? 540 :screenWidth > 760 ? 580: 430;
const heightColumn = screenWidth > 1060 ? "78%" :screenWidth > 760 ? "90%" : "78%";
const Login = (props) => {
  const { t } = useTranslation();
  const { storageState, setItem } = useStorage();
  const language = storageState.lang ? storageState.lang : "en";
  const route = useRoute();
  const [selectedOption, setSelectedOption] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState(null);
  const logoSource = language === 'fr' ? logoFr : logoEn;
  const pdfSource =language === 'fr' ? 'TutoFr.pdf' : 'TutoEn.pdf';
  const PdfFileLink = Config.backUrl + '/pdf/' + pdfSource;
  useEffect(() => {
  }, [language]);
  //console.log(selectedOption)
  const handleLogin = async () => {
    try {
      var Type = "";
      if (selectedOption === "Trainer") {
        Type = "formateur";
      } else {
        Type = "evaluateur";
      }
      //console.log("type   ",Type)
      const postData = {
        nomUsager: username,
        motPasse: password,
        typeFormateur: Type,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(userApi + ":" + passApi),
          //mode: 'no-cors'
        },
        Credential: 'include',
        body: JSON.stringify(postData),
      };

      if (!username || !password) {
        setAlert({ type: 'error', message: t('alertUserAndPassword') });
        return;
      } else if (!selectedOption) {
        setAlert({ type: 'error', message: t('alertRequired') });
        return;
      }

      const response = await fetch(apiUrl, requestOptions);

      if (response.ok) {
        const data = await response.json()
        //console.log(data)
        if (data.erreurs.length > 0) {
          if (data.erreurs[0] === "usagerInexistant") {
            setAlert({ type: 'error', message: t('usagerInexistant') });
          }
          else {
            setAlert({ type: 'error', message: t('alertType') });
          }

          return;
        }
        else {
          setAlert({ type: 'success', message: t('alertSuccess') });

          // Store language value in AsyncStorage
          await AsyncStorage.setItem('language', language);
          // const data = await response.json()
          //document.cookie = "BCE-Quess-Auth="+ data.jwt+ "; expires=session; path=/";
          await AsyncStorage.setItem('jwt', data.jwt);
          await AsyncStorage.setItem("formateurUser", username)
          await AsyncStorage.setItem("formateurType", Type)
          // Wait for the success alert to be displayed, then navigate
          setTimeout(() => {
            props.navigation.navigate("Profil", {
              usernameEva: username,
              type: Type,
              lang: language,
            });


            setUsername("")
            setPassword("")
            setSelectedOption("")
            setAlert(null)

          }, 1200); // Adjust the timeout duration as needed
        }

      } else {
        setAlert({ type: 'error', message: t('alertError') });
        throw new Error("Network response was not ok");
      }


    } catch (error) {
      setAlert({ type: 'error', message: t('alertError') });
      console.error("There was a problem with the login:", error);
    }
  };

  const handlePress = () => {
    // Chemin vers le fichier PDF local
    const filePdf = require("../locales/tutopdf.pdf");
    console.log('pdf', filePdf);
    // Ouvrir le fichier PDF avec Linking
    Linking.openURL(filePdf);
  };
  const changeLanguage = (langue) => {
    if (langue == "fr") {
      setItem('lang', 'fr')
      i18n.changeLanguage('fr');
    }
    else {
      setItem('lang', 'en')
      i18n.changeLanguage('en');
    }
  }

  return (
    <Background>
      <View style={styles.container}>
        {/* Start Notice  Alert */}
        <View style={styles.noticeContainer}>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
  <Icons name="alert-octagon" style={{ fontSize: screenWidth > 1060 ? 55 :screenWidth > 760 ? 55: 38, marginRight: 2, color: "#c54646", alignSelf: 'center' }} />
</View>

  <View style={{ alignItems: 'center' ,width: widthColumn}} >
    <Text style={{ color: '#c31f1f', textAlign: 'left', flexWrap: 'wrap' ,fontSize: screenWidth > 1060 ? 14 :screenWidth > 760 ? 17: 12}}>{t('noticeAlert')}</Text>
  </View>
</View>

        {/* End Notice  Alert */}
        <View style={styles.formContainer}>
          <Image source={logoSource}
            // source={require("./assets/logo-Light.png")}
            style={styles.logo}
          />
          {/* <Text style={styles.headerText}>
          {t('loginHeader')}
        </Text> */}

          {alert && (
            <View style={[styles.alertContainer, { backgroundColor: alert.type === 'error' ? '#FFBABA' : '#D5E8D4' }]}>
              <Text style={[styles.alertText, { color: alert.type === 'error' ? '#D8000C' : '#4F8A10' }]}>
                {alert.message}
              </Text>
            </View>
          )}

          <Text style={styles.labelText}>
            {t('idInputLabel')}
          </Text>
          <Field
            placeholder={t('username')}
            value={username}
            onChangeText={(text) => setUsername(text)}
            keyboardType={"email-address"}
            icon="account-outline"
            required={true}
          />

          <Field
            placeholder={t('password')}
            secureTextEntry={true}
            value={password}
            onChangeText={(text) => setPassword(text)}
            icon="lock-outline"
            required={true}
          />

          <View style={styles.radioButtonGroup}>
            <RadioButton
              label={t('trainer')}
              selected={selectedOption === "Trainer"}
              onSelect={() => setSelectedOption("Trainer")}
            />
            <RadioButton
              label={t('evaluator')}
              selected={selectedOption === "Evaluator"}
              onSelect={() => setSelectedOption("Evaluator")}
            />
          </View>
          <View style={{ marginBottom:screenWidth > 1060 ? 12 :screenWidth > 760 ? 18 : 8 }}>
            <Btn
              textColor="white"
              bgColor="#013E7F"
              btnLabel={t('loginButton')}
              style={styles.loginButton}
              Press={handleLogin}
              icon="arrow-right"
            /></View>
          <View style={{ flexDirection: "row", paddingBottom: 10, marginBottom: 10 }}>
            <TouchableOpacity onPress={() => changeLanguage("fr")}>
              <Text style={language === "fr" ? styles.selectedLanguageText : styles.LanguageText}> {t('french')}</Text>
            </TouchableOpacity>
            <Text style={styles.LanguageText}> /</Text>
            <TouchableOpacity onPress={() => changeLanguage("en")}>
              <Text style={language === "en" ? styles.selectedLanguageText : styles.LanguageText}> {t('english')}</Text>
            </TouchableOpacity>
            <MaterialIcons name="language" style={{ fontSize: 24, marginLeft: 10, marginTop: -2, color: "#6997d3" }} />
          </View>

        </View>
     <View>
    
     </View>
      </View>
    </Background>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    width: widthMax,
    maxWidth:screenWidth,
    justifyContent:"center",
    maxHeight:screenHeight
  },
  logo: {
    height: screenWidth > 1060 ? 55 : screenWidth > 760 ? 70 : 55,
    width: screenWidth > 1060 ? 130 : screenWidth > 760 ? 180 : 130,
    marginLeft: 1,
    marginBottom: screenWidth > 1060 ? 20: screenWidth > 760 ? 30 : 20,
  },
  noticeContainer: {
    backgroundColor: '#FFF',
     padding: 7, 
     marginBottom: 20,
      borderColor: "#f1a0a0",
       borderLeftWidth: 5,
        borderRadius: 5, 
        width:widthColumn,
         flexDirection: 'row'
  },
  formContainer: {
    backgroundColor: "#fff",
    height:heightColumn,
    width:widthColumn,
    borderRadius: 7,
    paddingTop: screenWidth > 1060 ? 30:screenWidth > 760 ? 40 : 20,
    alignItems: "center",
    paddingBottom:screenWidth > 1060 ? 20:screenWidth > 760 ? 20 : 10,
  },
  headerText: {
    color: "#2a77a8",
    fontSize: screenWidth > 1060 ? 20 :screenWidth > 760 ? 40 : 20,
    fontWeight: "bold",
    marginVertical: 5,
  },
  LanguageText: {
    color: "#2a77a8",
    fontSize: 17,
    fontWeight: "500",
    marginBottom: screenWidth > 1060 ? 10 :screenWidth > 760 ? 20 : 10,

  },
  selectedLanguageText: {
    color: "#2a77a8",
    fontSize: 17,
    fontWeight: "bold",
    textDecorationLine: 'underline',
    textDecorationThickness: 3, // Adjust the thickness of the underline
  },
  alertContainer: {
    paddingVertical: 13,
    paddingHorizontal: 16,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#2a77a8',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10

  },
  alertText: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  labelText: {
    color: "#01689cbd",
    fontSize: 16,
    fontWeight: "400",
    marginBottom: 20,
  },
  radioButtonGroup: {
    alignItems: "left",
    width: "100%",
    //paddingLeft: "15%",
    marginTop:screenWidth > 1060 ? 10 : screenWidth > 760 ? 15 : 9,
    marginBottom: screenWidth > 1060 ? 10 :screenWidth > 760 ? 17 : 9,
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: 'center'
  },
  radioButton: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginVertical: 8,
  },
  radioButtonCircle: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
  },
  radioButtonSelectedCircle: {
    height: 15,
    width: 15,
    borderRadius: 11,
    backgroundColor: "#8ad58f",
  },
  radioButtonLabel: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#2a77a8",
    marginRight: 8
  },
  loginButton: {
    width: 180,
    paddingTop: 7,
    marginBottom: screenWidth > 1060 ? 8 :screenWidth > 760 ? 13 : 8
  },
});

export default Login;
