import React from 'react';
import { TouchableOpacity, View, Text, Linking } from 'react-native';
import Icons from "react-native-vector-icons/MaterialCommunityIcons";
import { Colors } from "../theme/color";
import style from "../theme/style";
const ExternalLinkButton = ({ url, title }) => {
  const handlePress = () => {
    Linking.openURL(url);
  };

  return (
    <TouchableOpacity onPress={handlePress}
      style={{ flex: 1, backgroundColor: '#013E7F', borderRadius: 35, paddingVertical: 12, paddingHorizontal: 28 }} >
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 10 }}  >
        <Icons name="file-download-outline" style={{ fontSize: 35, color: "white" }} />
        <Text style={[style.s18, { color: Colors.secondary }]}>
          {title}
        </Text>
      </View>
    </TouchableOpacity>

  );
};

export default ExternalLinkButton;
