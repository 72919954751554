import React, { useEffect, useState } from 'react'; import { View, Text, StyleSheet } from 'react-native'; import NetInfo from '@react-native-community/netinfo';
import { useStorage } from "../../contextApp";
import { Config } from '../../config';
import { useTranslation } from 'react-i18next';
const BottomBar = () => {
  const { storageState } = useStorage();
  const [isConnected, setIsConnected] = useState(false);
   const [showBottomBar, setShowBottomBar] = useState(false);
   const backUrl =Config.backUrl;
  const apiUrl = backUrl +'/app/syncron';
  const dataToSend = storageState.apprenants;
  const { t } = useTranslation();
  const lang = storageState.lang;
  const handleDataToSend = async () => {
    try {
      if (!dataToSend) {
        //console.log('Error: No data to send');
        return;
      }
      //console.log('Data to send:', dataToSend);
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          apprenants: dataToSend,
        }),
      };

      const responseSyncron = await fetch(apiUrl, requestOptions);

      if (responseSyncron.ok) {
        const data = await responseSyncron.json();
        //console.log('Success: Apprenant data sent', data);
        // Handle success response if needed
      } else {
        //console.log('Error: Failed to send data');
        // Handle error response
        throw new Error(`Failed to synchronize data. Status: ${responseSyncron.status}`);
      }
    } catch (error) {
      console.error('Error during synchronization:', error.message);
      // Handle other errors
    }
  };
  useEffect(() => {
    const checkConnection = async () => {
      const initialConnectionInfo = await NetInfo.fetch(); //console.log("Connexion initiale: ", initialConnectionInfo.isConnected);


      setIsConnected(initialConnectionInfo.isConnected);

      const unsubscribe = NetInfo.addEventListener(state => {
        //console.log("Connexion en cours: ", state.isConnected);

        setIsConnected(state.isConnected);
        setShowBottomBar(true);
        //console.log('apprenat datatosend',dataToSend);
        handleDataToSend();
        // Réinitialiser showBottomBar après 3 secondes
        setTimeout(() => {
          setShowBottomBar(false);
        }, 3000);
      });

      return () => {
        unsubscribe();
      };
    };

    checkConnection();
  }, []);
  return (
    showBottomBar && 
    (
    <View style={[styles.bottomBar, { backgroundColor: isConnected ? '#4a8f4a' : '#e14040' }]}> 
    <Text style={styles.bottomBarText}> {isConnected ? t('connecte') : t('nonConnecte')} </Text> 
    <Text style={styles.statusText}> {isConnected ? t('enligne') : t('horsligne')} </Text> 
    </View>));
};

const styles = StyleSheet.create({ 
  bottomBar: { 
    height: 50,
     justifyContent: 'center',
      alignItems: 'center',
     },
 bottomBarText: { 
  color: 'white',
   fontSize: 15, 
   fontWeight: 'bold', 
  }, statusText: { color: 'white',
   fontSize: 12, }, });

export default BottomBar;