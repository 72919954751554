import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity,Dimensions, StyleSheet } from 'react-native';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTranslation } from 'react-i18next';
import { useStorage } from '../contextApp';
const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const widthColumn = screenWidth > 600 ? "87%" : "88%";
const Counter = ({name,setMains,setSurfaces,nbr,cnt}) => {
  const { storageState  } = useStorage();
 const [count, setCount] = useState(cnt);
  const lang = storageState.lang;
  const { t } = useTranslation();
useEffect(()=>{
  console.log('count ::::::::',count);
},[count]);
  const increment = () => {
    setCount(count + 1);
    if(name=="surface") 
    {
      setSurfaces(count +1)
    }
    else {
      setMains(count +1)
    }
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
      if(name=="surface") 
      {
        setSurfaces(count -1)
      }
      else {
        setMains(count -1)
      }
    }
  };
  return (
    <View style={styles.container}>
      <View style={styles.counterContainer}> 
      <View style={styles.counnterView}>
      <Text style={styles.count}> {name=="surface"?t('nettoyageSurface'):t('nettoyageMain')} {t(name)}</Text>
      <Text style={count>nbr || count ==nbr ?styles.countTrue:styles.countFalse}>{count}</Text>
      </View>
      <View style={styles.buttonContainer}>
      <TouchableOpacity style={styles.button} onPress={increment}>
          <Text style={styles.buttonText}>    <Icons name="plus-circle" size={screenWidth > 600 ? 28 :25} color="#1052b5" /></Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={decrement}>
          <Text style={styles.buttonText}>    <Icons name="minus-circle" size={screenWidth > 600 ? 28 :25} color="#1052b5" /></Text>
        </TouchableOpacity>
      </View>
      
     
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  counterContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal:  screenWidth > 600 ? 10 : 7,
  },
  button: {
    padding:screenWidth > 600 ? 7 : 3,
    marginLeft:screenWidth > 600 ? 5 : 2,
  },
  buttonText: {
   fontFamily:'Varela Round',
    fontSize:screenWidth > 600 ? 35 : 20,
    fontWeight:'bold',
    textAlign:'center'
  },
  countTrue: {
    fontSize:screenWidth > 600 ? 35 : 20,
    marginHorizontal:screenWidth > 600 ? 10 : 7,
    color:"green"
  },
  count: {
    fontWeight:'500',
    color:'#193d7e',
    width: '90%',
    fontSize:screenWidth > 600 ? 20: 14,
    marginHorizontal:screenWidth > 600 ? 10 : 7,
   
  },
  countFalse: {
    fontSize: screenWidth > 600 ? 35 : 20,
    marginHorizontal:screenWidth > 600 ? 10 : 7,
    color:"red"
  },
  counnterView: {
    width: screenWidth > 600 ? '45%': '60%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    width: screenWidth > 600 ? '45%': '30%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

export default Counter;