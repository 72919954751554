export const Colors={
    primary:'#013E7F',
    active:'#01689C',
     btn:'#FE970F',
    intxt:'#9CA4AB',
    secondary:'#f9f9ff',
    txt:'#F2F6FE',
    lable:'#B2BAC6',
    disable:'#8E9AAB',
    disable1:'#5E6F88',
    border:'#E5E5E5',
    icon:'#8894A7',
    bord:'#F2F4F5',
    r:'#f42717',
    g:'#0CE07A',
    bl:'#4594f1',
    white:'#fff'
}