import React, { useState, useContext } from "react";
import { View, Text, SafeAreaView, Dimensions, ImageBackground,
   TouchableOpacity, Image, ScrollView, StatusBar, Alert, StyleSheet, } from "react-native";
import { useTranslation } from 'react-i18next';
import { Colors } from "../theme/color";
import { Avatar } from "react-native-paper";
import style from "../theme/style";
import styles from '../theme/styles';
import Btn from "./Btn";
import Field from "./Field";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useStorage } from "../contextApp";
import { Config } from "../config";
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import ChangeLanguage from "./composants/ChangeLang";
import CircularLoader from "./composants/Loader";

// Configuration de l'API
const apiUrl = Config.apiUrl + "/" + "resto/bce/quiz/loginApprenant";
const apiUrlInscription = Config.apiUrl + "/" + "resto/bce/quiz/inscriptions";
//const apiUrl = Config.apiUrl + "/" + "resto/cnx/bce/check/loginQuiz";
const userApi = Config.userApi;
const passApi = Config.passApi;

// Dimensions de l'écran
const width = Dimensions.get("screen").width;
const height = Dimensions.get("screen").height;

// Composant principal pour la connexion en tant qu'apprenant
const LoginLearner = (props) => {
  // Utilisation du contexte pour la gestion du stockage
  const { storageState,setItem } = useStorage();

  // Récupération des paramètres de navigation
  const route = useRoute();
  const { t } = useTranslation();
  

 const lang = storageState.lang; 
 const usernameEva= storageState.formateurUser?storageState.formateurUser:route.params.usernameEva

 const type = storageState.formateurType?storageState.formateurType:route.params.type
  // Initialisation de lettre avatar
  const avatarLetters = usernameEva.slice(0, 2).toUpperCase();
  // États pour la visibilité du mot de passe, le focus et les données du quiz
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
//console.log(type)
  // États pour le nom d'utilisateur, le mot de passe et les données du quiz
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [dataQuiz, setDataQuiz] = useState(null);
  const[isLoading,setIsLoading] = useState(false)
//console.log(localStorage.getItem("jwt"))
  // État pour la gestion des alertes
  const [alert, setAlert] = useState(null);

  // Navigation
  const navigation = useNavigation();
  const handleLogoutFormateur = ()=>  {
    setAlert({ type: 'success', message: t('logoutSuccess') });

   var langue=lang
    localStorage.clear();
    setItem('language',langue)
    setItem('lang',langue)
    setTimeout(() => {
      navigation.navigate('Login');
      setAlert(null)

    }, 1200); 
   
  }
  // Fonction de gestion de la connexion de l'apprenant
  const handleLoginLearner = async () => {
    try {
      const postData = {
        motPasse: password,
        nomUsager: username,
      };
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(userApi + ":" + passApi),
          "BCE-Quess-Auth": localStorage.getItem("jwt")
        },
        body: JSON.stringify(postData), 
        credentials: 'include',
      };
  
      setIsLoading(true);
  
      if (!username || !password) {
        setAlert({ type: "error", message: t("alertUserAndPassword") });
        setTimeout(() => { setAlert(null); }, 4000);
        return;
      }
  
      const responseLoginLearner = await fetch(apiUrl, requestOptions);
      const dataUser = await responseLoginLearner.json();

      if (dataUser.ok) {
        setAlert({ type: 'success', message: t('alertSuccess') });
  
        const requestOptions2 = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa(userApi + ":" + passApi),
            "BCE-Quess-Auth": dataUser.jwt
          },
          credentials: 'include',
        };
  
        const responseInscription = await fetch(apiUrlInscription, requestOptions2);
        const data = await responseInscription.json();
  
        if (!data.ok) {
          throw new Error("Network response was not ok");
        }
  
        setIsLoading(false);
  
        setItem('username', username);
        setItem('password', password);
        setItem('formateurType', type === "formateur" ? "formateur" : "evaluateur");
        setItem('formateurUser', usernameEva);
        setItem('lang', lang);
        setItem('type', 'apprenant');
        setItem('quizData', data.inscriptions);
  
        setTimeout(() => {
          setUsername("");
          setPassword("");
          setAlert(null);
          navigation.navigate('Home');
        }, 1200); 
      } else {
        setIsLoading(false);
        if (dataUser.erreurs && dataUser.erreurs.includes("formateurLienInexistant")) {
          setAlert({ type: "error", message: t("alertErrorLaison") });
          setTimeout(() => { setAlert(null); }, 4000);
        } else {
          setAlert({ type: "error", message: t("alertError") });
          setTimeout(() => { setAlert(null); }, 4000);
          throw new Error("Network response was not ok");
        }
        return;
      }
    } catch (error) {
      setIsLoading(false);
      setAlert({ type: "error", message: t("alertError") });
      setTimeout(() => { setAlert(null); }, 4000);
      console.error("There was a problem with the login:", error);
      Alert.alert("Login Failed", "Invalid username or password");
    }
  };
  

  // Rendu du composant
  return (
    <SafeAreaView style={[style.area, {}]}>
      <StatusBar backgroundColor="transparent" translucent={true} />
      <ImageBackground
        source={require('../../assets/image/BG_0.png')}
        resizeMode="stretch"
        style={styles.backgroundImage}
      >
        <StatusBar backgroundColor="transparent" translucent={true} />
        <View style={styles.headerContainer}>
        <Avatar.Text
          size={40} label={avatarLetters}
          style={{  backgroundColor: '#c8dcff',  marginTop: 2,
            alignSelf: "center",  color:'#043ca5'  }}
        />
          <View style={styles.greetingContainer}>
            <Text style={styles.greetingText}>
            {t('hi')}, {usernameEva}
            </Text>
            <Text style={styles.welcomeText}>{t(type)}</Text>
          </View>
        <ChangeLanguage/>
  
        <Icons name="power" style={{ fontSize: 34,marginLeft:10, color: "white" }} onPress={handleLogoutFormateur} />
        </View>
      </ImageBackground>
      <View style={[style.main, { marginTop: -50, paddingTop: 50 }]}>
      <ImageBackground
        source={require('../../assets/image/BG-L.png')} style={{ flex: 1, height: '100%',resizeMode: 'cover' }}
      >
        <ScrollView showsVerticalScrollIndicator={false} style={{ marginTop: 20 }}>
          <View
            style={{
              flexDirection: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >  <View>
          {isLoading ? (
            <CircularLoader />
          ) : null}
        </View>
            <Text style={[style.s17, { color: Colors.active }]}></Text>
            <TouchableOpacity
              onPress={() => navigation.navigate("Specialist")}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <Text style={[style.s13, { color: Colors.lable }]}></Text>
            </TouchableOpacity>
          </View>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <Text
              style={[
                style.r15,
                { fontSize: 25, color: Colors.primary, marginBottom: 20 },
              ]}
            >
              {t("logHeader")}
            </Text>
           
        {alert && (
          <View style={[styles.alertContainer, { backgroundColor: alert.type === 'error' ? '#FFBABA' : '#D5E8D4' }]}>
            <Text style={[styles.alertText, { color: alert.type === 'error' ? '#D8000C' : '#4F8A10' }]}>
              {alert.message}
            </Text>
          </View>
        )}

            <View style={{ paddingTop: 20 }}></View>
            <Field
              placeholder={t("username")}
              value={username}
              onChangeText={(text) => setUsername(text)}
              keyboardType={"email-address"}
              icon="account-outline"
            />

            <Field
              placeholder={t("password")}
              secureTextEntry={true}
              value={password}
              onChangeText={(text) => setPassword(text)}
              icon="lock-outline"
            />
            <Btn
              textColor="white"
              bgColor={Colors.primary}
              btnLabel={t("loginButton")}
              Press={handleLoginLearner}
            />
          </View>
       
        </ScrollView>
        </ImageBackground>
      </View>
    
    </SafeAreaView>
    
  );
};

// Styles pour les alertes
const styles1 = StyleSheet.create({
  alertContainer: {
    paddingVertical: 13,
    paddingHorizontal: 16,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#f9f9ff",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  alertText: {
    fontSize: 15,
    fontWeight: "bold",
  },
});

// Export du composant
export default LoginLearner;