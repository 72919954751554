import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';

const CircularLoader = () => {
  return (
  
      <ActivityIndicator size="large" color="#3d77e1" />
  
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CircularLoader;