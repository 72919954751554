// StrongPoints.js
import React ,{useEffect, useState} from 'react';
import {  View, Text ,TouchableOpacity,TextInput } from 'react-native';
import Checkbox from './Checkbox';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Colors } from '../../theme/color';
import style from '../../theme/style';
import stylesForm from '../../theme/step';
import { useTranslation } from 'react-i18next';

const StrongPoints = ({ strongModalVisible,commentaireStrong, setCommentaireStrong, toggleStrongModal, message, setMessage, handleStrongPointSend, strongPoints, selectedStrongPoints, toggleCheckbox }) => {
  const [editModeStrong, setEditModeStrong] = useState(false);
  //commentaire not Strong
  const togCommentStrong = () => {
    setEditModeStrong(!editModeStrong);
  };
  const { t } = useTranslation();

  const handleCommentStrongSend = () => {
    setEditModeStrong(false);
  };
  useEffect(() => {
    //console.log('Strong Points Updated **:', strongPoints);
  }, [strongPoints]);
  return (
    <View style={stylesForm.panelBlock}>
    <View style={stylesForm.panel}>
    <Text style={[stylesForm.panelHeader, ]}>{t('pointsForts')}</Text>
    <View style={{ flexDirection: 'row' }}>
   
      <TouchableOpacity onPress={toggleStrongModal}>
        <Icons name="plus-box" size={28} color="#fff" />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => togCommentStrong()}  style={{ marginLeft: '5' }}>
          <Icons
            name={commentaireStrong ? 'square-edit-outline' : 'tooltip-text'}
            size={28}
            color="#fff"
           
          />
        </TouchableOpacity>
      </View>
    </View>
    <View style={stylesForm.panelBody}>
      {strongPoints.map((point, index) => (
        <Checkbox
          key={index}
          label={point.texte}
          selected={selectedStrongPoints.includes(point.texte)}
          onSelect={() => toggleCheckbox(point, 'strong')}
          
        />
      ))}
       <View>
                  {editModeStrong ? (
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <TextInput
                        name="commentaireStrong"
                        placeholder={t('writeComment')}
                        type="text"
                        multiline
                        style={{ width: '80%', backgroundColor: '#cce0ff', borderRadius: 5 }}
                        value={commentaireStrong}
                        onChangeText={(comment) => setCommentaireStrong(comment)}
                      />
                      <TouchableOpacity onPress={() => handleCommentStrongSend()}>
                        <Icons name="send" size={28} color="#1052b5" />
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      {commentaireStrong ? (
                        <View style={styles.commentContainer}>
                          <View style={styles.commentBox}>
                            <Icons name='text-box' size={20} color="#c4dffb" style={styles.commentIcon} />
                            <Text style={styles.commentText}>{commentaireStrong}</Text>
                          </View>
                        </View>
                      ) : null}
                    </View>
                  )}
                </View>
    </View>
  </View>
  );
};
const styles = {
  commentContainer: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#f0f0f0',
    borderRadius: 8,
  },
  commentBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentIcon: {
    marginRight: 10,
  },
  commentText: {
    fontSize: 16,
    color: '#333',
  },
};
export default StrongPoints;
