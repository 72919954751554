import React from 'react';
import { View, Text, Switch, TouchableHighlight, TextInput, TouchableOpacity } from 'react-native';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Colors } from '../../theme/color';
import style from '../../theme/style';
import stylesForm from '../../theme/step';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'react-native-paper';
const ActionItem = ({
  actions,
  toggleSwitch,
  toggleComment,
  commentStates,
  handleCommentSend,
  editedComment,
  setEditedComment,
}) => {
  const { t } = useTranslation();
  return (
    <View style={stylesForm.panelBlock}>
      <View style={stylesForm.panel}>
        <Text style={stylesForm.panelHeader}>Actions</Text>
      </View>
      <View style={stylesForm.panelBody}>
        {actions.map((act, index) => (
          <TouchableHighlight
            key={index}
            activeOpacity={1}
            underlayColor="transparent"
            style={stylesForm.boxShad}
          >
            <View>
            <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                  <View style={{ flex: 1 }}>
                    <Text style={[stylesForm.panelText, { fontSize: 16, fontWeight: 'bold', color: act.coche ? '#2f47a4' : '#848bc1' }]}>
                      {act.texte || ''}
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <View style={{ alignItems: 'center', marginRight: 8 }}>
                        <Text style={[stylesForm.panelText, { fontSize: 13, fontWeight: 'bold', color: '#2f47a4'  }]}>{t('done')}</Text>
                        <RadioButton
                          value="done"
                          status={act.coche ? 'checked' : 'unchecked'}
                          onPress={() => toggleSwitch(index, true)}
                          color="green" // color when selected
                        />
                      </View>
                      <View style={{ alignItems: 'center' }}>
                        <Text style={[stylesForm.panelText, { fontSize: 13, fontWeight: 'bold', color: '#2f47a4'  }]}>{t('notDone')}</Text>
                        <RadioButton
                          value="notDone"
                          status={!act.coche ? 'checked' : 'unchecked'}
                          onPress={() => toggleSwitch(index, false)}
                          color="red" // color when selected
                        />
                      </View>
                    </View>
                    <TouchableOpacity onPress={() => toggleComment(index)}>
                      <Icons
                        name={commentStates[index] ? 'square-edit-outline' : 'tooltip-text'}
                        size={28}
                        color="#0c57cb"
                        style={{ marginLeft: 5 }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              <View>
                {commentStates[index] ? (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TextInput
                      name="commentaire"
                      placeholder={t('writeComment')}
                      type="text"
                      multiline
                      style={{ width: '80%', backgroundColor: '#cce0ff', borderRadius: 5 }}
                      value={editedComment}
                      onChangeText={(commentaire) => setEditedComment(commentaire)}
                    />
                    <TouchableOpacity onPress={() => handleCommentSend(index)}>
                      <Icons name="send" size={28} color="#1052b5" />
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {act.commentaire ? (
                        <View style={styles.commentContainer}>
                        <View style={styles.commentBox}>
                          <Icons name='text-box' size={20} color="#c4dffb" style={styles.commentIcon} />
                          <Text style={styles.commentText}>{act.commentaire}</Text>
                        </View>
                      </View>
                    ) : null}
                  </View>
                )}
              </View>
            </View>
          </TouchableHighlight>
        ))}
      </View>
    </View>
  );
};
const styles = {
  commentContainer: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#f0f0f0',
    borderRadius: 8,
  },
  commentBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentIcon: {
    marginRight: 10,
  },
  commentText: {
    fontSize: 16,
    color: '#333',
  },
};
export default ActionItem;
