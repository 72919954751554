import React, { useState,useEffect } from 'react';
import { View, Text, StyleSheet,Dimensions,TouchableOpacity,Modal,Button } from 'react-native';
import style from '../theme/style';
import { Colors } from '../theme/color';
import { useStorage } from '../contextApp';
import { useTranslation } from 'react-i18next';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import stylesForm from '../theme/step';
import { useNavigation } from '@react-navigation/native';
import { Config } from '../config';
import NetInfo from '@react-native-community/netinfo';

//import moment from 'moment';
const DetailDataTransfere = ({ route }) => {
  const { t } = useTranslation();
  const { storageState, setItem } = useStorage();
  const existingData = storageState.apprenants;
  const lang = storageState.lang;
  const [openModal, setOpenModal] = useState(false);
  const navigation = useNavigation();
  const { formateur, formateurType, id, idModule, evaluationSatisfaisante, nomUsager, date, nomEn, nomFr, dateSyncron,cidInscription } = route.params;
//console.log('evaluationSatisfaisante:',date);
const screenWidth = Dimensions.get('window').width;
// Déterminez le nombre de colonnes en fonction de la largeur de l'écran
const valueWidth = screenWidth > 600 ? 60 : 90;
const dateString = new Date(date);
const formatDate = (dateTimeString) => {
  const dateString = new Date(dateTimeString);
  const day = dateString.getDate() < 10 ? '0' + dateString.getDate() : dateString.getDate();
  const month = (dateString.getMonth() + 1) < 10 ? '0' + (dateString.getMonth() + 1) : dateString.getMonth() + 1;
  const year = dateString.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};
const initialConnectionInfo =  NetInfo.fetch();
const backUrl =Config.backUrl;
const apiUrl = backUrl +'/app/syncron';
const formattedDateTime = formatDate(date);
const [isConnected, setIsConnected] = useState(true);

let formattedDateTimeSyncron;

if (dateSyncron !== null) {
  console.log('h00:', dateSyncron);
  formattedDateTimeSyncron = dateSyncron;
  console.log('h1:', formattedDateTimeSyncron);
} else {
  formattedDateTimeSyncron = t('enAttente');
}
const [showBottomBar, setShowBottomBar] = useState(false);

useEffect(() => {
  const checkConnection = async () => {
 
    setIsConnected(initialConnectionInfo.isConnected);

    const unsubscribe = NetInfo.addEventListener(state => {
      setIsConnected(state.isConnected);
      setShowBottomBar(true);
      setTimeout(() => {
        setShowBottomBar(false);
      }, 3000);
    });

    return () => {
      unsubscribe();
    };
  };

  checkConnection();
}, [initialConnectionInfo]);
const userExists= existingData.findIndex((el)=>el.nomUsager=== nomUsager)

if(userExists!=-1)
  {
    const userData= existingData[userExists]
    const userCompletedQuizs= userData.listeQuiz
   var quizExists= userCompletedQuizs.findIndex((el)=>el.cidInscription===cidInscription && el.id===idModule)
   
  }
  const deleteQuiz = async () =>{
    if(idModule==="FPCM" ||idModule==="EPCM") 
      {
        var medicalExists= existingData[userExists].medicals.findIndex((el)=>el.cidInscription===cidInscription && el.id===idModule)
        existingData[userExists].medicals.splice( medicalExists,1);
      }
      else {
        existingData[userExists].listeQuiz.splice( quizExists,1);
      }
   
    console.log('existingData:',existingData);
   await setItem('apprenants', existingData);
   try {
    if (!existingData) {
      //console.log('Error: No data to send');
      return;
    }
    //console.log('Data to send:', dataToSend);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        apprenants: existingData,
      }),
    };

    const responseSyncron = await fetch(apiUrl, requestOptions);

    if (responseSyncron.ok) {
      const data = await responseSyncron.json();
      setTimeout(() => {
        navigation.navigate("DataTransfere" ,{data:existingData})
    
      }, 700); 
    } else {
      //console.log('Error: Failed to send data');
      // Handle error response
      throw new Error(`Failed to synchronize data. Status: ${responseSyncron.status}`);
    }
  } catch (error) {
    console.error('Error during synchronization:', error.message);
    // Handle other errors
  }
  

  }
   // Fonction pour fermer le modal
    const handleCloseModal = () => {
      setOpenModal(false);
    };
  
     // Fonction pour ouvrir le modal
     const handleOpenModal = () => {
      setOpenModal(true);
    };
  
  return (

  
      <View style={styles.panelBlock}>
        <View style={styles.panel}><Text style={styles.panelHeader}>{lang === 'fr' ? nomFr : nomEn} ({idModule})</Text></View>
        <View style={styles.panelBody}>
          <React.Fragment >

            <Text style={styles.panelText}>{t('quizTitle')} : <Text style={styles.textValue}>{lang === 'fr' ? nomFr : nomEn} ({idModule})</Text> </Text>
            <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View>
            {evaluationSatisfaisante != null ?<>
            <Text style={styles.panelText}> {t('evalSatisf')} : {evaluationSatisfaisante ? <Text style={styles.textValueYes}>{t('satisfaisante')}</Text> : <Text style={styles.textValueNo}>{t('nonSatisfaisante')}</Text>} </Text>
            <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View></>:<></>}
            <Text style={styles.panelText}> {t('dateComplete')} :  <Text style={styles.textValue}>{formattedDateTime}</Text> </Text>
            <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View>
            <Text style={styles.panelText}> {t('dateTransfert')} :  <Text style={styles.textValue}>{formattedDateTimeSyncron }</Text> </Text>
            <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View>
            <Text style={styles.panelText}> {t(formateurType)} :  <Text style={styles.textValue}>{formateur} {' '}<Text style={{fontSize:14}}></Text> </Text> </Text>
            <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View>
            <Text style={styles.panelText}> {t('apprenant')} :  <Text style={styles.textValue}>{nomUsager}</Text> </Text>
            <View style={[style.divider, { backgroundColor: Colors.bord, marginVertical: 20 }]}></View>
          { isConnected && (<View style={styles.sendButtonContainer}>
           
            <TouchableOpacity  style={[styles.sendButton, { backgroundColor: Colors.r }]} onPress={handleOpenModal}>
            <Text style={styles.sendButtonText}>{t('supprimer')}</Text>
             

              </TouchableOpacity>
        
              </View>)}
  
          </React.Fragment>

        </View>
  
        {/* verfication Modal */}
        <Modal
          animationType="slide"
          transparent={true}
          visible={openModal}
          onRequestClose={handleCloseModal}
         
        >
         <View style={[stylesForm.modalContainer, { justifyContent: 'center', alignItems: 'center' }]}>
        <View style={[stylesForm.modalContent, { width: screenWidth * 0.8 }]}>
              <View style={stylesForm.modalHeader}>
                <Text style={stylesForm.modalHeaderText}>{t('Information')}</Text>
                <TouchableOpacity onPress={handleCloseModal}>
                  <Icons name="close" size={28} color="#eb8181" />
                </TouchableOpacity>
              </View>
              <View>
              <View style={{margin:10}}>
              <Text style={stylesForm.modalHeaderText }>{t('messageSuppression')}</Text>
              </View>
              <View style={{  display:"flex",flexDirection: 'row', flexWrap: 'wrap', alignItems:'center', justifyContent:"center", marginBottom: 10 }}>
              <TouchableOpacity  style={[styles.sendButton, { backgroundColor: Colors.r }]} onPress={deleteQuiz}>
            <Text style={styles.sendButtonText}>{t('supprimer')}</Text>
             

              </TouchableOpacity>
              <TouchableOpacity  style={[styles.sendButton, { backgroundColor: Colors.bl }]} onPress={handleCloseModal}>
            <Text style={styles.sendButtonText}>{t('cancel')}</Text>
             

              </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </Modal>
        {  showBottomBar && 
    (
    <View style={[styles.bottomBar, { backgroundColor: isConnected ? '#f9f9ff' : '#e14040' }]}> 
    <Text style={styles.bottomBarText}> {isConnected ? null: t('nonConnecte')} </Text> 
    <Text style={styles.statusText}> {isConnected ? null : t('horsligne')} </Text> 
    </View>)}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  panelBlock: {
    margin: '1%',
    backgroundColor: '#fff',
 
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  panel: {
    backgroundColor: '#013E7F',
    fontSize: 20,
    alignItems: 'center',
    textTransform: 'uppercase',
    borderRadius: 4,
    fontWeight: 'bold',
    marginBottom: 10,
    padding: 10,
    color: 'white',
  },
  panelBody: {
    padding: '2%',
  },
  panelText: {
    fontSize: 18,
    fontWeight:'bold',
    color: '#01689C',
    marginBottom: 15,
    borderBottomColor: '#000',
  },
  textValue:{
    marginLeft:10,
    color:'#01689C',
    fontWeight: 400
  },
  textValueYes:{
   minWidth:100,
    alignItems:'center',
    paddingTop:7,
   paddingRight: 7,
   paddingBottom: 7,
    paddingLeft: 7,
    borderRadius:7,
    backgroundColor:'green',
    marginLeft:10,
    color:'#fff',
    fontWeight: 400
  },
  textValueNo:{
    width:100,alignItems:'center',
    paddingTop:8,
   paddingRight: 7,
   paddingBottom: 8,
 paddingLeft: 7,
    borderRadius:7,
    backgroundColor:'red',
    marginLeft:10,
    color:'#fff',
    fontWeight: 400
  },
  panelHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: '1%',
    color: 'white',
  },
  tabBar: {
    padding: '2%',
    backgroundColor: '#013E7F', // Change this to the desired background color
  },
  tabLabel: {
    color: 'white', // Change this to the desired text color
    fontSize: 18, // Change this to the desired font size
    fontWeight: 'bold',
  },
  activeTabLabel: {
    // Add any styles for the active tab label
  }, 
  sendButtonContainer: {
   
    marginTop: 10,
    marginBottom: 10,
    alignItems:"center"
  },
  sendButton: {
    ...style.btn,
    width:140,
    marginVertical: 20,
    marginTop: 10,
    marginRight:3,
    marginBottom:10
  },
  sendButtonText: {
    ...style.btntxt,
  },
  bottomBar: { 
    height: 50,
     justifyContent: 'center',
      alignItems: 'center',
     },
 bottomBarText: { 
  color: 'white',
   fontSize: 15, 
   fontWeight: 'bold', 
  }, statusText: { color: 'white',
   fontSize: 12, }, 
});

export default DetailDataTransfere;
