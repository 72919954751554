import React, { useState, useEffect } from 'react';
import { SafeAreaView, Dimensions,ScrollView, View, Text, Switch, TouchableOpacity, Modal, Button, TextInput, } from 'react-native';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import style from '../theme/style';
import stylesform from '../theme/styles';
import { useNavigation } from '@react-navigation/native';
import stylesForm from '../theme/step';
import { useStorage } from '../contextApp';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ConditionsMedicales from './composants/ConditionsMedicales';
import { useTranslation } from 'react-i18next';



const MultiSteps = ({ route }) => {
  const { height: screenHeight, width: screenWidth } = Dimensions.get('window');
    // Utilisation du hook useStorage pour récupérer et mettre à jour les données de stockage
    const { storageState, setItem } = useStorage();
  
    // Récupération des paramètres de la route
    const { QuizItem } = route.params;
    
    // Utilisation du hook useNavigation pour accéder à la navigation
    const navigation = useNavigation();
    
    // Récupération de la langue actuelle depuis le stockage
    const lang = storageState.lang;
    
    // Récupération du nom de l'utilisateur formateur depuis le stockage
    const usernameEva = storageState.formateurUser;
    
    // Récupération du type de formateur depuis le stockage
    const type = storageState.formateurType;
    
    // Récupération du nom de l'utilisateur depuis le stockage
    const userAp = storageState.username;
    
    // Récupération de l'identifiant de l'inscription depuis le stockage
    const cidInscription = storageState.cidInscription;
    
    // État local pour indiquer si des conditions médicales sont présentées
    const [presenteConditionMedicale, setPresenteConditionMedicale] = useState(null);
    
    // État local pour indiquer si le modal est ouvert
    const [openModal, setOpenModal] = useState(false);
    
    // Utilisation du hook useTranslation pour la traduction
    const { t } = useTranslation();
    
    // Détermination du nom à afficher en fonction de la langue
    const name = lang === 'fr' ? QuizItem.nomFr : QuizItem.nomEn;
    
    // État local pour les données médicales
    const [medicals, setMedicals] = useState([]);
    
    // État local pour indiquer si l'activité est maintenue
    const [maintientActivite, setMaintientActivite] = useState(false);
    
    // État local pour les conditions sélectionnées
    const [selectedConditions, setSelectedConditions] = useState([]);
    
    // État local pour la radio sélectionnée
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [selectedEtat, setSelectedEtat] = useState(null);
    
    // État local pour le message d'erreur
    const [messageError, setMessageError] = useState('');
    
    // Données de l'apprenant
    const apprenantsData = {
      "nomUsager": userAp,
      "medicals": medicals,
    };
  
    // Fonction pour fermer le modal
    const handleCloseModal = () => {
      setOpenModal(false);
    };
  
    // Si les données des apprenants n'existent pas encore dans le stockage, les ajouter
    if (!storageState.apprenants) {
      setItem("apprenants", apprenantsData);
    }
 
    // Fonction pour gérer la soumission du formulaire
    const handleSatisfactoryPress = async () => {
      try {
        if (!selectedEtat ) {
          setOpenModal(true);
          setMessageError(t('errormedical1'));
          return; 
        }
        if (presenteConditionMedicale && !maintientActivite && !(selectedRadio === t('reportMessage'))) {
          setOpenModal(true);
          setMessageError(t('errormedical1'));
          return; 
        } else if (!maintientActivite && selectedRadio === t('reportMessage')) {
          if (selectedConditions.length === 0) {
            setOpenModal(true);
            setMessageError(t('errormedical2'));
            return;
          }
        }
  
        const asyncDataApprenant = storageState.apprenants || [];
  
        if (!Array.isArray(asyncDataApprenant)) {
          throw new Error('Les données apprenants ne sont pas sous forme de tableau.');
        }
  
        const existingUserIndex = asyncDataApprenant.findIndex((user) => user.nomUsager === userAp);
  
        if (existingUserIndex !== -1) {
          asyncDataApprenant[existingUserIndex].medicals = medicals;
        } else {
          asyncDataApprenant.push({
            "nomUsager": userAp,
            "medicals": medicals,
            "listeQuiz": []
          });
        }
  
        await setItem('apprenants', asyncDataApprenant);
  
        await new Promise(resolve => setTimeout(resolve, 100));
  
        console.log('Données de l\'apprenant enregistrées avec succès :', asyncDataApprenant);
  
        navigation.navigate('RecordedData');
      } catch (error) {
        console.error('Erreur lors de l\'enregistrement des données de l\'apprenant :', error);
      }
    };
    const dataSend = storageState.apprenants;
console.log('medicals  ---------->', medicals);
    const goToCourse= ()=> {
      navigation.navigate('Practical Assessment',{
        datatosync: dataSend
      });
    }
    return (
      <SafeAreaView style={style.area}>
            <>
            <View style={{ flexDirection: 'row', position: 'sticky', top: 0, backgroundColor: '#013E7F', zIndex: 2 }}>
                <View style={{width:'90%',alignItems:'left' }}>
               
                  <TouchableOpacity onPress={goToCourse}
                  style={{color:'white',padding:8 ,flexDirection:'row',fontSize:18,marginTop:10,height:60}}>
                  <Icons  name="arrow-left-thin"  size={28} color="#fff" style={{ marginRight: '7', marginTop:-2 }} /> 
                  <Text style={{color:'white',fontSize:18}}>{t('backList')}</Text>  
                  </TouchableOpacity>
                 
                 </View>
                 
                <View style={{width:'10%',alignItems:'right'}}> 
              
                 
                </View>
            </View> 
            </>
        <ScrollView>
          <View style={{ marginTop: -7 }}>
            <View style={style.main}>
              <Text style={{ textAlign: 'center', fontSize: 27, fontWeight: 'bold', color: '#193d7e', marginTop: '4%', marginBottom: 20 }}>
               {name}
              </Text>
  
              {/* Medical Conditions Section */}
              <ConditionsMedicales medicals={medicals} presenteConditionMedicale={presenteConditionMedicale} selectedEtat={selectedEtat} 
              setSelectedEtat={setSelectedEtat} setPresenteConditionMedicale={setPresenteConditionMedicale} setMedicals={setMedicals} quizItem={QuizItem} selectedRadio={selectedRadio} setSelectedRadio={setSelectedRadio}  setSelectedConditions={setSelectedConditions} selectedConditions={selectedConditions} maintientActivite={maintientActivite} setMaintientActivite={setMaintientActivite}/>
            
              {/* Buttons for navigation */}
              <View style={stylesForm.buttonContainer}>
               
                <TouchableOpacity
                   onPress={handleSatisfactoryPress}
                  style={stylesForm.buttonSuccess}
                >
                  <Text style={stylesForm.buttonText}>{t('submit')}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {/* verfication Modal */}
          <Modal
          animationType="slide"
          transparent={true}
          visible={openModal}
          onRequestClose={handleCloseModal}
         
        >
         <View style={[stylesForm.modalContainer, { justifyContent: 'center', alignItems: 'center' }]}>
        <View style={[stylesForm.modalContent, { width: screenWidth * 0.8 }]}>
              <View style={stylesForm.modalHeader}>
                <Text style={stylesForm.modalHeaderText}>{t('error')}</Text>
                <TouchableOpacity onPress={handleCloseModal}>
                  <Icons name="close" size={28} color="#eb8181" />
                </TouchableOpacity>
              </View>
              <View>
              <View style={{margin:10}}>
              <Text style={stylesForm.modalHeaderText }>{messageError}</Text>
              </View>
                <Button title="OK" onPress={handleCloseModal} />
              </View>
            </View>
          </View>
        </Modal>
        {/* The keyboard will show up this screen */}
        </ScrollView>
  
  
      </SafeAreaView>
    );
  };
  const styles = {
    commentContainer: {
      marginTop: 10,
      padding: 10,
      backgroundColor: '#f0f0f0',
      borderRadius: 8,
    },
    commentBox: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    commentIcon: {
      marginRight: 10,
    },
    commentText: {
      fontSize: 16,
      color: '#333',
    },
  };
  export default MultiSteps;
  
  