import { StyleSheet, Dimensions } from "react-native";
import { Colors } from "./color";
import style from './style';
import zIndex from "@material-ui/core/styles/zIndex";
const screenWidth = Dimensions.get('screen').width
const height = Dimensions.get('screen').height


export default StyleSheet.create({
  boxShad: {
  
    paddingHorizontal: 10,
    paddingVertical: 15,
   backgroundColor:'#fff',
    borderRadius: 8,
    shadowColor: '#033f87', 
    shadowOpacity: 0.2,
    shadowRadius: 4,
    marginBottom: 10,
  },
  formSurface: {
    paddingTop:screenWidth > 600 ? 14: 12,
    paddingBottom:screenWidth > 600 ? 14: 12,
    position: 'sticky',
     top: 50,
    backgroundColor: 'white',
    borderRadius: 8,
    marginBottom: 20,
    zIndex:2
  },
    formContainer: {
      backgroundColor: 'white',
      borderRadius: 8,
      marginBottom: 20,
    },
    checkboxContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 8,
    },
    checkbox: {
      height: 24,
      width: 24,
      borderRadius: 5,
      borderWidth: 2,
      borderColor: '#8c9cc3',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 5,
      marginLeft: 15,
    },
    checkboxSelected: {
      height: 22,
      width: 22,
      backgroundColor: '#378000',
      borderColor: '#a0a59e',
    },
    checkboxLabel: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#8c9cc3',
    },
    panelBlockQuiz: {
      backgroundColor: '#fff',
      borderRadius: 5,
      borderWidth: 1,
      marginBottom:12,
      borderColor: '#a5c6e7',
    },
    panelQuiz: {
      backgroundColor: '#013E7F',
      fontSize: 18,
      borderRadius: 2,
      fontWeight: 'bold',
      marginBottom: 10,
      padding: 4,
      color: 'white',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    panel: {
      backgroundColor: '#013E7F',
      fontSize: 18,
      borderRadius: 4,
      fontWeight: 'bold',
      marginBottom: 10,
      padding: 6,
      color: 'white',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    panelBlock: {
      backgroundColor: '#fff',
      marginTop: 20,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: '#ddd',
    },
    panelBody: {
      padding: '2%',
    },
    panelText: {
      fontSize: 16,
      color: '#333',
      marginBottom: 10,
    },
    panelHeader: {
      fontSize: 18,
      fontWeight: 'bold',
      padding: '1%',
      color: '#fff',
    },
    buttonContainer: {
      marginTop: 30,
      marginBottom: 30,
      alignItems: 'center',
    },
    button: {
      ...style.btn,
      marginVertical: 20,
      backgroundColor: '#c90000',
      width: 240,
    },
    buttonSuccess: {
      ...style.btn,
      marginVertical: 20,
      backgroundColor: '#12812e',
      width: 240,
    },
    buttonText: {
      ...style.btntxt,
    },
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(191, 220, 243 , 0.9)',
    },
    modalContent: {
      backgroundColor: "#fff",
      padding: 20,
      borderRadius: 10,
      width: 500,
    },
    modalHeader: {
      color: '#4f78e1',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    modalHeaderText: {
      fontSize: 18,
      color: '#3689eb',
      fontWeight: 'bold',
      marginTop:2,
      marginBottom: 10,
      textAlign:"center"
    },
    input: {
      height: 100,
      borderColor: '#4f78e1',
      borderWidth: 1,
      marginBottom: 10,
      padding:8,
      color: '#4f78e1'
    },
    
  });