import React, { useState, useContext, useEffect } from "react";

import MaterialIcons from'react-native-vector-icons/MaterialIcons'
import { useStorage } from "../../contextApp";
import i18n from '../../locales/i18n';
export default function ChangeLanguage(props) {

    
    const { storageState, setItem } = useStorage();
    const lang= storageState.lang
   
    const changeLanguage=()=> {
        if(lang=="en") {
          setItem('lang','fr')
          i18n.changeLanguage('fr');
        }
        else {
          setItem('lang','en')
          i18n.changeLanguage('en');
        }
    }
    useEffect(() => {
      //console.log('lang:', lang);
    }, [lang]);

    return ( 

        <MaterialIcons   name="language" style={{ fontSize: 34,marginLeft:10, color: "white" }} onPress={changeLanguage}/>
    )
}